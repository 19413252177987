export function validateEmail(email: string): boolean {
	const parts = email.split('@')
	return parts.length === 2 && parts[0].length > 0 && parts[1].length > 0
}

const emailSplitPattern = /[ ,;\t\n]+/

// eslint-disable-next-line no-useless-escape
const onPasteEmailSplitPattern = /[ ,;\"\<\>\t\n]+/

export const parseEmailsString = (emailsString: string): string[] =>
	emailsString.split(emailSplitPattern).filter(email => email.trim() != '')

export const parsePastedEmailsString = (emailsString: string): string[] =>
	emailsString.split(onPasteEmailSplitPattern).filter(email => email.trim() != '')

/**
 * Returns the array of parts of the string pasted from clipboard;
 * If there is at least one valid email in parsed parts it returns only valid emails;
 * If there are no valid emails it returns the array of parts of regularly parsed string;
 * @param pastedString
 */
export const handlePastedString = (pastedString: string): string[] => {
	const parsedParts = parsePastedEmailsString(pastedString)
	const hasAtLeastOneValidEmail = parsedParts.some(validateEmail)

	if (hasAtLeastOneValidEmail) {
		return parsedParts.filter(validateEmail)
	}

	return parseEmailsString(pastedString)
}
