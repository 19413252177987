import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'

//only for picker
export const SUPPORTED_FILE_TYPES: string[] = Object.keys(GoogleMimeTypes).map(key => GoogleMimeTypes[key])

export function isImageFile(mimeType: string): boolean {
	return mimeType.startsWith('image/')
}

export function isPreviewFile(mimeType: string): boolean {
	return (
		mimeType === GoogleMimeTypes.TXT ||
		mimeType === GoogleMimeTypes.PDF ||
		mimeType === GoogleMimeTypes.WORD_DOC ||
		mimeType === GoogleMimeTypes.WORD_DOCX ||
		mimeType === GoogleMimeTypes.POWERPOINT ||
		mimeType === GoogleMimeTypes.POWERPOINT_1
	)
}

export function isEditableByEditorFile(mimeType: string): boolean {
	return (
		mimeType === GoogleMimeTypes.DOCS ||
		mimeType === GoogleMimeTypes.SHEETS ||
		mimeType === GoogleMimeTypes.SLIDES ||
		mimeType === GoogleMimeTypes.WORD_DOCX ||
		mimeType === GoogleMimeTypes.POWERPOINT ||
		mimeType === GoogleMimeTypes.POWERPOINT_1
	)
}
