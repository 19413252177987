<template>
	<transition name="fade" @enter="onReady">
		<div class="modal-overlay" v-if="opened" @click="close">
			<div class="modal-container">
				<div class="modal" v-if="opened" @mousedown.stop @click.stop>
					<div class="modal-content-container">
						<h3 class="share-header">Visibility</h3>
						<ShareGlobalPermission :permissions="permissions" :file-id="fileId" @update-file="getPermissions"/>
					</div>
				</div>

				<div class="modal" v-if="opened" @mousedown.stop @click.stop>
					<div class="modal-content-container">
						<h3 class="share-header">Add people</h3>
						<ShareAddPeople :permissions="permissions" :file-id="fileId" ref="addPeople" @update-file="getPermissions"/>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import PageSelector from 'components/common/PageSelector.vue'
	import {State} from 'vuex-class'
	import ShareGlobalPermission from 'components/share/ShareGlobalPermission.vue'
	import ShareAddPeople from 'components/share/ShareAddPeople.vue'
	import SpaceService from 'modules/space/SpaceService'
	import NotificationService from 'modules/notifications/NotificationService'
	import {SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
	import DriveAPI from 'api/DriveAPI'

	@Component({
		components: {
			ShareAddPeople,
			ShareGlobalPermission,
			PageSelector,
			BaseModal,
		}
	})

	export default class ShareModal extends Vue {
		@State
		currentPageId: string

		private fileId: string
		private permissions: gapi.client.drive.Permission[] = []
		private opened: boolean = false

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_SHARE, this.open)
			EventBus.$on(CustomEventNames.ESCAPE_PRESSED, this.close)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_SHARE, this.open)
			EventBus.$off(CustomEventNames.ESCAPE_PRESSED, this.close)
		}

		open(fileId: string, permissions: gapi.client.drive.Permission[]) {
			if (!fileId || !permissions) {
				return
			}
			this.fileId = fileId
			this.permissions = permissions
			this.opened = true
		}

		close() {
			this.opened = false
			this.fileId = undefined
			this.permissions = undefined
		}

		onReady() {
			const pageSelector = this.$refs.addPeople as any
			pageSelector.focusInput()
		}

		private getPermissions(fileId: string) {
			DriveAPI.getPermissionsForFile(fileId).then((permissions: any) => {
				this.permissions = permissions
			}, () => {
				NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
			})
		}
	}
</script>

<style scoped>
	.modal-overlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: var(--dialog-modal-z-index);
		display: flex;
		flex-direction: column;
		position: fixed;
		align-items: center;
		transition: all 0.2s;
		background-color: rgba(33, 38, 42, 0.92);
		color: rgba(25, 27, 49, 0.98);
		overflow-y: auto;
	}

	.modal-container {
		display: flex;
		flex-direction: column;
		padding: 36px 0;
		margin: auto;
	}

	.modal {
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		background-color: var(--white);
		box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.2);
		box-sizing: border-box;
		position: relative;
		margin-bottom: 16px;
	}

	.share-header {
		margin-bottom: 16px;
	}
</style>
