<template>
	<div class="page-selector">
		<div class="input-container" :class="{'line-results-container': lineSearch && resultsVisible}">
			<IconSearch class="icon-search"/>
			<input
					type="text"
					spellcheck="false"
					ref="inputfield"
					v-model="searchText"
					:placeholder="placeholder"
					@focus="onFocus"
					@blur="onBlur"
					@keydown="onKeyDown">
			<PulseLoader :size="'6px'" :color="'var(--g-50)'" :loading="searchInProgress" class="loader"/>
		</div>

		<div class="results" v-show="resultsVisible" :class="{'absolute-results': lineSearch}">
			<div
					class="search-results-container"
					ref="container">
				<FileRow
						@click="selectItem"
						@mousedown.native.stop
						@mousedown.native.prevent
						v-for="(page, index) in searchResults"
						:file="page"
						:class="{ 'active-item': selectedItemIndex !== -1 && index === selectedItemIndex }"
						:ref="'search-item-' + index"
						:key="page.id"/>

				<div class="no-results" v-if="searchText && !searchResults.length">
					<div class="no-results-title" v-if="!searchInProgress">No results found</div>
					<div class="no-results-title-desc" v-if="!searchInProgress">Try different search terms</div>
				</div>
			</div>

			<div class="page-selector-footer">
				<div class="footer-navigate-item">
					<div class="footer-navigate-item-icon">↑↓</div>
					<div class="footer-navigate-item-title">Navigate</div>
				</div>

				<div class="footer-navigate-item">
					<div class="footer-navigate-item-icon">⏎</div>
					<div class="footer-navigate-item-title">Select</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import Keyboard from 'enums/Keyboard'
	import IconSearch from 'components/icons/icons/IconSearch.vue'
	import {State} from 'vuex-class'
	import FileRow from 'components/common/elements/FileRow.vue'
	import Utils from 'utils/Utils'
	import debounce from 'lodash/debounce'
	import DriveAPI from 'api/DriveAPI'
	import PulseLoader from 'components/common/loaders/PulseLoader.vue'
	import {ISpaceFiles} from 'modules/space/SpaceService'
	import FileUtils from 'utils/FileUtils'

	@Component({
		components: {
			PulseLoader,
			FileRow,
			IconSearch
		}
	})

	export default class PageSelector extends Vue {
		@Prop({default: 'Jump to...'})
		placeholder: string

		@Prop({default: false})
		lineSearch: boolean

		@State
		spaceFiles: ISpaceFiles

		@State
		googleUser: gapi.auth2.GoogleUser

		searchText: string = ''
		selectedItemIndex: number = -1

		searchResults: gapi.client.drive.File[] = []
		searchInProgress: boolean = false

		inputFocused: boolean = false

		get resultsVisible(): boolean {
			return this.lineSearch ? !!this.searchText && this.inputFocused : true
		}

		private onFocus() {
			this.inputFocused = true
		}

		private onBlur() {
			this.inputFocused = false
		}

		mounted() {
			this.searchResults = this.searchLocally()
		}

		focusInput() {
			const input = this.$refs.inputfield as any
			input.focus()
		}

		@Watch('spaceFiles')
		onSpaceFilesChange(newVal) {
			if (!this.searchInProgress && !this.searchText) {
				this.searchResults = this.searchLocally()
			}
		}

		@Watch('searchText')
		onSearchTextChange(newVal) {
			if (this.selectedItemIndex !== -1) {
				this.selectedItemIndex = -1
			}
			if (!newVal) {
				this.searchResults = this.searchLocally()
				this.searchInProgress = false
				return
			}

			if (this.googleUser) {
				this.searchInProgress = true
				this.onTrySearch()
			}
			this.searchResults = this.searchLocally()
		}

		private onTrySearch = debounce(() => {
			this.searchExternal()
		}, 300)

		private searchExternal() {
			const folders = FileUtils.getAllFoldersInSpace()
			DriveAPI.search(this.searchText.trim().toLowerCase(), folders).then((res: gapi.client.drive.File[]) => {
				res.forEach(f => {
					if (!this.searchResults.some(ef => f.id == ef.id)) {
						this.searchResults.push(f)
					}
				})
				this.searchInProgress = false
			}, error => {
				this.searchInProgress = false
			})
		}

		private searchLocally() {
			return Object.keys(this.spaceFiles).filter(key => this.spaceFiles[key].name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1).map(key => this.spaceFiles[key]).slice(0, 100)
		}

		private onKeyDown(e) {
			switch (e.keyCode) {
				case Keyboard.DOWN_ARROW:
					this.highlightItem('down')
					e.preventDefault()
					break
				case Keyboard.UP_ARROW:
					this.highlightItem('up')
					e.preventDefault()
					break
				case Keyboard.ENTER:
					this.onEnter()
					e.preventDefault()
					break
				default:
					return true
			}
		}

		private highlightItem(direction) {
			const results = this.searchResults

			if (results.length === 0) {
				return
			}

			let selectedItemIndex = this.selectedItemIndex

			if (selectedItemIndex === -1) {
				if (direction === 'down') {
					selectedItemIndex = 0
				} else {
					selectedItemIndex = results.length - 1
				}
			} else {
				if (direction === 'down') {
					selectedItemIndex++
					if (selectedItemIndex === results.length) {
						selectedItemIndex = 0
					}
				} else {
					selectedItemIndex--
					if (selectedItemIndex < 0) {
						selectedItemIndex = results.length - 1
					}
				}
			}

			this.selectedItemIndex = selectedItemIndex

			let elements = this.$refs['search-item-' + selectedItemIndex]
			const container = this.$refs.container as HTMLElement

			if (elements && elements[0]) {
				Utils.scrollIntoView(elements[0].$el, container)
			}
		}

		private onEnter() {
			if (this.selectedItemIndex === -1) {
				return
			}
			const item = this.searchResults[this.selectedItemIndex]

			if (!item) {
				return
			}
			this.selectItem(item)
		}

		private selectItem(item: gapi.client.drive.File) {
			this.$emit('item-selected', item)
			this.searchText = ''
		}
	}
</script>

<style scoped>
	.page-selector {
		max-width: 100%;
		box-sizing: border-box;
		position: relative;
	}

	.input-container {
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		padding: 12px 16px;
		box-sizing: border-box;
		background-color: var(--g-20);
		border-radius: 8px;
	}

	.line-results-container {
		border-radius: 8px 8px 0 0;
	}

	.results {
		background-color: var(--white);
		width: 100%;
		position: relative;
		box-sizing: border-box;
	}

	.icon-search {
		width: 20px;
		height: 20px;
		color: var(--primary-color);
		margin-right: 8px;
		margin-top: 2px;
	}

	input {
		font-family: 'Jost', Helvetica, Arial, sans-serif;
		height: 100%;
		width: 100%;
		border: none;
		color: var(--primary-color);
		font-size: 14px;
		outline: none;
		box-sizing: border-box;
		background-color: transparent;
	}

	input::placeholder {
		color: var(--g-60);
	}

	.search-results-container {
		width: 100%;
		max-height: 300px;
		overflow-y: auto;
		box-sizing: border-box;
		padding: 12px 0;
		background-color: var(--white);
		scrollbar-width: none;
	}

	.search-results-container::-webkit-scrollbar {
		width: 0;
	}

	.active-item {
		background-color: var(--db-5);
	}

	.page-selector-footer {
		border-top: 1px solid var(--g-20);
		display: flex;
		align-items: center;
		padding: 12px 12px 0 12px;
	}

	.footer-navigate-item {
		display: flex;
		margin-right: 16px;
		align-items: center;
		font-size: 12px;
	}

	.footer-navigate-item-icon {
		margin-right: 6px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
	}

	.footer-navigate-item-icon svg {
		width: 16px;
		height: 16px;
	}

	.footer-navigate-item-title {

	}

	.no-results {
		height: 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.no-results-title {
		font-weight: 600;
		margin-bottom: 4px;
	}

	.no-results-title-desc {
		color: var(--g-60);
	}

	.loader {
		position: absolute;
		right: 20px;
		top: 14px;
	}

	.absolute-results {
		position: absolute;
		top: 46px;
		left: 0;
		z-index: 1;
		padding-bottom: 12px;
		border-radius: 0 0 16px 16px;
		border: 1px solid var(--g-20);
		border-top: none;
	}

	.absolute-results .active-item {
		border-radius: 0;
	}
</style>
