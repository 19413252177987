<template>
	<BaseModal ref="modal" :width="404" @closed="onClosed">
		<div class="modal-content-container">
			<h2 class="modal-header">What is the topic?</h2>

			<div class="modal-paragraph margin-b-16">
				Topics allow you to organize your knowledge at a high level. You can use topics to separate spaces by department, function, or team. For example Engineering, HR, Sales, etc. You can nest topics infinitely to put everything in its place.
			</div>

			<div class="modal-paragraph" style="font-weight: 600">
				Topics are visible to all your domain users.
			</div>

			<div class="modal-buttons-container">
				<div class="rounded-button-medium db-50-button button-margin-4" @click="close">Got it</div>
			</div>
		</div>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'

	@Component({
		components: {BaseModal}
	})

	export default class TopicHintModal extends Vue {
		mounted() {
			EventBus.$on(CustomEventNames.OPEN_TOPIC_HINT, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_TOPIC_HINT, this.open)
		}

		open() {
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		close() {
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		onClosed() {
			//
		}
	}
</script>

<style scoped>
	.modal-content-container {
		width: 404px;
	}
</style>
