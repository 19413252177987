<template>
	<div class="email-badge" :class="{'not-valid': !invitee.isValid, 'editing': isEdit}">
		<div
				class="invitee-text"
				:contenteditable="isEdit"
				@click="onBadgeClick"
				@keydown="onKeyDown"
				ref="badgeEditor"
				@blur="onBlur"
		>{{text}}</div>
		<IconCross class="icon-close" @click.native="deleteEmail" :class="{'hidden': isEdit}"/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {IEmailsEditorInviteeInfo} from 'components/common/form/emaileditor/IEmailsEditorInviteeInfo'
	import IconCross from 'components/icons/icons/IconCross.vue'
	import Keyboard from 'enums/Keyboard'

	@Component({
		components: {
			IconCross,
		}
	})

	export default class EmailBadge extends Vue {
		@Prop()
		invitee: IEmailsEditorInviteeInfo

		private isEdit: boolean = false

		get text(): string {
			return this.invitee.name ? this.invitee.name.trim() : this.invitee.email.trim()
		}

		getBadgeContent(): string {
			const input = this.$refs.badgeEditor as any
			return input.innerText
		}

		private deleteEmail() {
			this.$emit('delete', this.invitee.email)
		}

		private onBadgeClick(e: MouseEvent) {
			e.stopPropagation()
			this.isEdit = true
			setTimeout(() => {
				this.focusInput()
			})
		}

		private onKeyDown(e: KeyboardEvent) {
			e.stopPropagation()
			if (e.keyCode == Keyboard.ENTER || e.keyCode == Keyboard.SPACE) {
				e.preventDefault()
				this.saveEmail()
			}
		}

		private saveEmail() {
			this.isEdit = false
			if (this.invitee.email !== this.getBadgeContent()) {
				this.$emit('change', this.invitee.email, this.getBadgeContent())
			}
		}

		private onBlur() {
			if (this.isEdit) {
				this.saveEmail()
			}
		}

		focusInput() {
			const input = this.$refs.badgeEditor as any
			input.focus()
			let range = document.createRange()
			let selection = window.getSelection()

			range.selectNodeContents(input)
			range.collapse(false)

			if (selection) {
				selection.removeAllRanges()
				selection.addRange(range)
			}
		}
	}
</script>

<style scoped>
	.email-badge {
		line-height: 24px;
		padding-left: 8px;
		background-color: var(--db-40);
		color: var(--white);
		box-sizing: border-box;
		border-radius: 20px;
		margin: 0 6px 6px 0;
		display: flex;
		align-items: center;
		outline: none;
	}

	.invitee-text {
		outline: none;
	}

	.not-valid {
		background-color: var(--red);
	}

	.icon-close {
		margin-left: 4px;
		width: 12px;
		height: 12px;
		padding-right: 6px;
		cursor: pointer;
	}

	.editing {
		background-color: transparent !important;
		color: var(--primary-color);
	}

	.hidden {
		opacity: 0;
		pointer-events: none;
	}
</style>
