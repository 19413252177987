<template>
	<BaseModal ref="modal" @closed="onClosed">
		<div class="modal-content-container">
			<h2 class="modal-header">Remove</h2>
			<div class="modal-paragraph">You are about to remove <span style="font-weight: 600;">{{ fileName }}</span>. It will stay in Google Drive.</div>
			<div class="modal-buttons-container">
				<div class="rounded-button-medium db-50-button button-margin-4" @click="deleteFile">Remove</div>
				<div class="rounded-button-medium db-50-text-button cancel-button" @click="close">Cancel</div>
			</div>
		</div>

		<Loading :is-loading="isLoading"/>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import DriveAPI from 'api/DriveAPI'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import Loading from 'components/common/Loading.vue'
	import NotificationService from 'modules/notifications/NotificationService'
	import {DELETE_NOT_PERMITTED, SOMETHING_WENT_WRONG, SUCCESS_DELETE_MESSAGE} from 'modules/notifications/NotificationMessages'
	import cloneDeep from 'lodash/cloneDeep'
	import {State} from 'vuex-class'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import {SpaceliProperties} from 'enums/FileProps'
	import Stat from 'modules/stat/Stat'
	import FileUtils from 'utils/FileUtils'

	@Component({
		components: {
			Loading,
			BaseModal,
		}
	})

	export default class DeleteModal extends Vue {
		file: gapi.client.drive.File = {}
		isLoading: boolean = false

		get fileName() {
			return this.file && this.file.name ? this.file.name : ''
		}

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_DELETE_FILE, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_DELETE_FILE, this.open)
		}

		open(file: gapi.client.drive.File) {
			this.isLoading = false
			this.file = file
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		close() {
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		onClosed() {
			this.file = undefined
		}

		deleteFile() {
			this.isLoading = true

			DriveAPI.hideFile(this.file).then((result: any) => {
				if (result.error) {
					NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG, true)
					this.close()
					return
				}

				if (this.file.properties && this.file.properties[SpaceliProperties.SPACELI]) {
					Stat.removeSpace()
				} else {
					Stat.removeFile()
				}

				EventBus.$emit(CustomEventNames.FILE_DELETED, cloneDeep(this.file))
				this.close()
				NotificationService.getInstance().showNotification(SUCCESS_DELETE_MESSAGE)
			})
		}
	}
</script>

<style scoped>

</style>
