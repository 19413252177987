<template>
	<div class="file-view">
		<div class="file-content-view base-file-view-padding">
			<div class="file-header-container">
				<h1 class="file-header">Activity feed</h1>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import {ISpacePage} from 'components/space/pages/ISpacePage'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'

	@Component({
		components: {
			//
		}
	})

	export default class ActivityPage extends Vue implements ISpacePage {
		@Prop()
		file: gapi.client.drive.File

		@State(state => state.spaceLoadedPartially)
		spaceLoadedPartially: boolean

		@State(state => state.spaceLoadedFully)
		spaceLoadedFully: boolean

		update(): void {
			//
		}

		loaded(): void {
			if (this.spaceLoadedPartially || this.spaceLoadedFully) {
				EventBus.$emit(CustomEventNames.PAGE_LOADED)
			}
		}

		mounted() {
			this.loaded()
		}
	}
</script>

<style scoped>

</style>
