<template>
	<div class="toc-view" v-show="hasContent">
		<div class="toc-content-title">
			<IconContents class="toc-content-title-icon"/>
			<div class="toc-content-title-text">Contents</div>
		</div>
		<div class="toc-content" ref="tocContent"></div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconContents from 'components/icons/icons/IconContents.vue'
  import * as tocbot from 'tocbot'

	@Component({
		components: {
			IconContents,
		}
	})

	export default class TableOfContents extends Vue {
		@Prop({default: ''})
		contentSelector: string

		@Prop({default: ''})
		scrollContainer: string

		private hasContent: boolean = false

		mounted() {
			this.createToc()
			this.calcHasContent()
		}

		refresh() {
			tocbot.refresh()
			this.calcHasContent()
		}

		private createToc() {
			tocbot.init({
				tocSelector: '.toc-content',
				contentSelector: this.contentSelector,
				scrollContainer: this.scrollContainer,
				collapseDepth: 6,
        scrollSmooth: false,
			})
		}

		private calcHasContent() {
			const toc = this.$refs.tocContent as HTMLDivElement
			toc.childElementCount ? this.hasContent = true : this.hasContent = false
		}
	}
</script>

<style>
	.toc-view {
		flex: none;
		width: 224px;
		display: flex;
		flex-direction: column;
		user-select: none;
	}

	.toc-content {
		position: relative;
		width: 100%;
	}

	.toc-content-title {
		display: flex;
		align-items: center;
		padding: 0 20px 20px 0;
		cursor: default;
	}

	.toc-content-title-icon {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}

	.toc-content-title-text {
		font-weight: 600;
	}

	.toc-content > ol {
		padding-left: 20px;
	}
</style>
