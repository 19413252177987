<template>
	<div class="app-main">
		<transition :name="routingAnimation">
			<router-view/>
		</transition>

		<transition name="fade">
			<LoadingScreen class="loading-app" v-if="isLoading"/>
		</transition>

		<div class="popover-backdrop" v-close-popover id="popoverBackdrop"></div>

		<FeedbackModal/>

		<Spaceli2Modal/>

		<ShareModal/>

		<NeedFeedbackModal class="survey-modal"/>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import AppService from 'modules/app/AppService'
	import LoadingScreen from 'components/common/LoadingScreen.vue'
	import FeedbackModal from 'components/common/modals/FeedbackModal.vue'
	import RoutingUtils from 'utils/RoutingUtils'
	import ShareModal from 'components/common/modals/ShareModal.vue'
	import NeedFeedbackModal from 'components/common/modals/NeedFeedbackModal.vue'
	import Spaceli2Modal from "./components/common/modals/Spaceli2Modal.vue";
	import AuthService from "./modules/app/AuthService";
	import {EventBus} from "./EventBus";
	import {CustomEventNames} from "./enums/CustomEventNames";

	@Component({
		components: {
			Spaceli2Modal,
			NeedFeedbackModal,
			ShareModal,
			FeedbackModal,
			LoadingScreen
		}
	})
	export default class App extends Vue {
		appService: AppService
		isLoading: boolean = true

		created() {
			this.appService = AppService.createAppProcessor(this)
			this.appService.appInitialized.add(this.onAppInited, this)
		}

		private onAppInited() {
			this.isLoading = false

			setTimeout(() => {
				const item = localStorage.getItem('spaceli_2_message')
				if (AuthService.getInstance().googleUser && !item) {
					EventBus.$emit(CustomEventNames.OPEN_MEET_SPACELI_2)
				}
			}, 2000)
		}

		get routingAnimation() {
			return RoutingUtils.getAnimationNameForRoute(this.$route.name)
		}
	}
</script>

<style>
	#app {
		width: 100%;
		height: 100%;
		position: relative;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: var(--primary-color);
		user-select: none;
		-webkit-tap-highlight-color: transparent;
	}

	.app-main {
		width: 100%;
		height: 100%;
		position: relative;
		background-color: var(--white);
		user-select: none;
	}

	.loading-app {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.survey-modal {
		position: absolute;
		left: 24px;
		bottom: 24px;
	}
</style>
