export const SUCCESS_DELETE_MESSAGE = 'File has been removed. It will stay in Google Drive.' //It will be permanently deleted in 30 days.
export const SOMETHING_WENT_WRONG = 'Something went wrong, please try again later.'
export const SAVED = 'Saved'

export const LINK_COPIED = 'Link copied! Share it with your team!'

export const FAILED_LOAD_GAPI = 'Failed to load Google API, please try again later.'

export const INVALID_IFRAME_CODE = 'The iFrame code or link you are trying to submit is invalid'

export const DELETE_NOT_PERMITTED = 'Sorry, you don\'t have permission to delete this page'

export const CANT_CREATE_SPACE = 'Sorry, you can\'t create space in this folder, because you don\'t have permission to edit this folder.'
export const CANT_CREATE_SPACE_SHORTCUT = 'Sorry, you can\'t create space with the shortcut. Please select an original folder.'
export const CANT_MOVE_FILE = 'Sorry, you can\'t move this file, because you don\'t have permissions. Try to copy this file.'
export const CANT_COPY_FILE = 'Sorry, you can\'t copy this file.'
//'Sorry, you can\'t create space in shared folder. Please select your own folder'


/////// Sharing messages ////////
export const SPACE_PUBLIC = 'Space is now public. Anyone with the URL link can view.'
export const SPACE_DOMAIN = 'Space has been shared. Anyone within the domain can view.'
export const SPACE_PRIVATE = 'Space is now private. Only people added can access this space.'



