<template>
  <div class="file-view">
    <div class="file-content-view">
      <div class="request-access-content">
        <h1 class="modal-header">{{ header }}</h1>
        <div class="modal-paragraph"></div>
        <div class="paragraph-text empty-text">
          <p>Sorry, this document was not found. This might be due to one of the following reasons:</p>
          <ul>
            <li>You don’t have permission to open the file.</li>
            <li>The original file is in the trash.</li>
            <li>The original file is deleted.</li>
          </ul>
          <p v-if="googleUser">Open the document in Google Drive to see if requesting access is possible.</p>
        </div>
        <div class="modal-buttons-container">
          <div
              class="rounded-button-medium db-50-button"
              @click="openFileDirectly"
              v-if="googleUser">Open in Drive</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator'
import {State} from "vuex-class";
import Stat from "../../../modules/stat/Stat";

@Component({})

export default class RequestAccessPage extends Vue {
  @State
  googleUser: gapi.auth2.GoogleUser

  @Prop()
  fileId: string

  get header(): string {
    if (this.googleUser) {
      return 'You need access'
    } else {
      return 'File not found'
    }
  }

  openFileDirectly(): void {
    Stat.openInDriveRequestAccess()
    const url = 'https://drive.google.com/file/d/' + this.fileId + '/edit'
    window.open(url, '_blank')
  }
}
</script>

<style scoped>
.file-content-view {
  padding: 0 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.request-access-content {
  max-width: 450px;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  user-select: text;
  margin-bottom: 128px;
  margin-right: 128px;
}

.request-access-content ul {
  padding-left: 32px;
  margin: 8px 0;
  text-align: left;
}

.request-access-content li {
  list-style-type: none;
  position: relative;
}

.request-access-content li::before {
  content: '–';
  position: absolute;
  left: -16px;
  top: -1px;
}

@media only screen and (max-width: 1024px) {
  .request-access-content {
    margin-right: 0;
  }
}

@media only screen and (max-width: 480px) {
  .file-content-view {
    padding: 0 48px;
  }

  .request-access-content {
    margin-right: 0;
    padding: 0;
  }
}
</style>
