<template>
	<div class="dashboard">
		<DashboardHeader/>

		<div class="dashboard-container">
			<DashboardSidebar/>

			<div class="dashboard-content-container">
				<div class="spaces-header-container">
					<h2 class="spaces-header">{{ filterName }}</h2>
					<div class="create-space-button lb-sec-button" @click="openCreateSpace">
						<IconPlus class="icon-20"/>
					</div>
				</div>
				<div class="spaces-group-container">
					<div class="spaces-container">
						<SpaceCard
								:key="space.id"
								:space="space"
								v-for="space in filteredSpaces"
								@select="moveToSpace"/>
						<div class="create-new-space-card" @click="openCreateSpace">
							<IconPlus/>
							<div class="create-new-space-card-text">Create a new space</div>
						</div>
					</div>
				</div>

				<Loading :is-loading="isLoading" :bg-color="'var(--ds-50)'" :color="'var(--lb-70)'" class="loading-screen"/>
			</div>
		</div>

		<CreateSpace/>

		<DeleteModal/>
		<RenameModal/>

		<SetTopicModal/>

		<CreateTopicModal/>
		<RenameTopicModal/>
		<DeleteTopicModal/>
		<TopicHintModal/>

		<InviteTeamModal/>

		<RenameTeamModal/>

    <AccessErrorPage/>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import DashboardService from 'modules/dashboard/DashboardService'
	import AppService from 'modules/app/AppService'
	import SpaceCard from 'components/dashboard/SpaceCard.vue'
	import DashboardHeader from 'components/dashboard/DashboardHeader.vue'
	import RoutingUtils from 'utils/RoutingUtils'
	import IconPlus from 'components/icons/icons/IconPlus.vue'
	import CircleLoader from 'components/common/loaders/CircleLoader.vue'
	import Loading from 'components/common/Loading.vue'
	import Utils from 'utils/Utils'
	import DeleteModal from 'components/common/modals/DeleteModal.vue'
	import RenameModal from 'components/common/modals/RenameModal.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import AuthService from 'modules/app/AuthService'
	import Stat from 'modules/stat/Stat'
	import DashboardSidebar from 'components/dashboard/DashboardSidebar.vue'
	import {RouteParams} from 'router/Router'
	import {State} from 'vuex-class'
	import CreateTopicModal from 'components/topics/CreateTopicModal.vue'
	import RenameTopicModal from 'components/topics/RenameTopicModal.vue'
	import {SpaceliProperties} from 'enums/FileProps'
	import DeleteTopicModal from 'components/topics/DeleteTopicModal.vue'
	import TopicsUtils from 'components/topics/TopicsUtils'
	import TopicsService from 'components/topics/TopicsService'
	import CreateSpace from 'components/CreateSpace.vue'
	import SetTopicModal from 'components/topics/SetTopicModal.vue'
	import InviteTeamModal from 'components/common/modals/InviteTeamModal.vue'
	import TopicHintModal from 'components/topics/TopicHintModal.vue'
	import RenameTeamModal from 'components/dashboard/RenameTeamModal.vue'
  import {ErrorHandlingUtils} from "../../utils/ErrorHandlingUtils";
  import AccessErrorPage from "../common/AccessErrorPage.vue";

	enum DashboardFilters {
		ALL = 'all',
		STARRED = 'starred'
	}

	@Component({
		components: {
      AccessErrorPage,
			RenameTeamModal,
			TopicHintModal,
			InviteTeamModal,
			SetTopicModal,
			CreateSpace,
			DeleteTopicModal,
			RenameTopicModal,
			CreateTopicModal,
			DashboardSidebar,
			RenameModal,
			DeleteModal,
			Loading,
			SpaceCard,
			DashboardHeader,
			IconPlus,
			CircleLoader,
		},
	})

	export default class Dashboard extends Vue {
		@State(state => state.dashboardFilter)
		dashboardFilter: string

		@State(state => state.spaces)
		spaces: gapi.client.drive.File[]

		appService: AppService = AppService.getInstance()
		dashboardService: DashboardService = DashboardService.getInstance()
		topicsService: TopicsService = TopicsService.getInstance()

		private isLoading: boolean = false

		mounted() {
			this.isLoading = true

			if (this.dashboardFilter) {
				this.openFilter(this.dashboardFilter, true)
			} else {
				const filter = this.$route.params[RouteParams.DASHBOARD_FILTER] as string
				this.setCurrentFilterState(filter)
			}

			this.appService.isInit ? this.init() : this.appService.appInitialized.addOnce(this.init, this)
			Utils.setDocumentTitle('Dashboard')

			EventBus.$on(CustomEventNames.DASHBOARD_FILTER_SELECTED, this.openFilter)
			EventBus.$on(CustomEventNames.FILE_DELETED, this.onFileDeleted)
			EventBus.$on(CustomEventNames.FILE_UPDATED, this.onFileUpdated)
			EventBus.$on(CustomEventNames.TOPIC_DELETED, this.onTopicDeleted)

			this.topicsService.isInit ? this.onTopicsInited() : this.topicsService.topicsInited.addOnce(this.onTopicsInited, this)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.DASHBOARD_FILTER_SELECTED, this.openFilter)
			EventBus.$off(CustomEventNames.FILE_DELETED, this.onFileDeleted)
			EventBus.$off(CustomEventNames.FILE_UPDATED, this.onFileUpdated)
			EventBus.$off(CustomEventNames.TOPIC_DELETED, this.onTopicDeleted)
		}

		private init() {
			if (!AuthService.getInstance().firebaseUser) {
				RoutingUtils.goToLogin()
			} else {
				this.getSpaces()
				Stat.openDashboard()
			}
		}

		private getSpaces() {
			this.dashboardService.getSpaces().then(() => {
				this.isLoading = false
			}, error => {
        ErrorHandlingUtils.handleRequestDataErrorAndBlockApp(error, AuthService.getInstance(), 'dashboard')
      })
		}

		private moveToSpace(id: string) {
			RoutingUtils.goToSpace(id)
		}

		private onFileDeleted(file: gapi.client.drive.File) {
			this.isLoading = true
			this.getSpaces()
		}

		private onFileUpdated(file: gapi.client.drive.File) {
			this.isLoading = true
			this.getSpaces()
		}

		openCreateSpace() {
			EventBus.$emit(CustomEventNames.OPEN_CREATE_SPACE, this.getCurrentTopic())
		}

		private openFilter(filter: string, force: boolean = false) {
			if (this.dashboardFilter !== filter || force) {
				const route = this.$route.params[RouteParams.DASHBOARD_FILTER] as string
				if (filter === DashboardFilters.ALL && route == DashboardFilters.ALL) {
					//
				} else {
					RoutingUtils.openDashboardFilter(filter)
				}
				this.setCurrentFilterState(filter)
			}
		}

		private setCurrentFilterState(filter: string) {
			this.$store.commit('setDashboardFilter', filter)
		}

		get filterName(): string {
			if (this.dashboardFilter === DashboardFilters.ALL) {
				return 'All spaces'
			} else if (this.dashboardFilter === DashboardFilters.STARRED) {
				return 'Starred spaces'
			} else {
				const topic = TopicsUtils.getTopicById(this.dashboardFilter)
				return topic ? topic.name : ''
			}
		}

		get filteredSpaces(): gapi.client.drive.File[] {
			if (this.dashboardFilter === DashboardFilters.ALL) {
				return this.spaces
			} else if (this.dashboardFilter === DashboardFilters.STARRED) {
				return this.spaces.filter(space => space.starred)
			} else {
				return this.spaces.filter(space => space.appProperties && space.appProperties[SpaceliProperties.TOPIC] === this.dashboardFilter)
			}
		}

		private onTopicsInited() {
			if (this.getCurrentTopic()) {
				if (!this.topicsService.topicsAvailable) {
					this.openFilter(DashboardFilters.ALL)
					return
				}

				const topic = TopicsUtils.getTopicById(this.dashboardFilter)
				if (!topic) {
					this.openFilter(DashboardFilters.ALL)
				}
			}
		}

		private getCurrentTopic(): string {
			if (this.dashboardFilter !== DashboardFilters.ALL && this.dashboardFilter !== DashboardFilters.STARRED) {
				return this.dashboardFilter
			} else {
				return undefined
			}
		}

		private onTopicDeleted(deletedTopics: string[]) {
			deletedTopics.forEach(topic => {
				if (this.dashboardFilter === topic) {
					this.openFilter(DashboardFilters.ALL)
				}
			})
		}
	}
</script>

<style scoped>
	.dashboard {
		width: 100%;
		height: 100%;
		background-color: var(--ds-50);
		color: var(--white);
		display: flex;
		flex-direction: column;
		user-select: none;
		position: relative;
	}

	.dashboard-container {
		display: flex;
		padding: 16px 96px 0 80px;
		box-sizing: border-box;
		overflow: hidden;
		flex-grow: 1;
	}

	.dashboard-content-container {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding-left: 48px;
		padding-right: 16px;
		overflow-y: auto;
		position: relative;
		scrollbar-width: none;
	}

	.dashboard-content-container::-webkit-scrollbar {
		width: 0;
	}

	.dashboard-search {
		margin: 60px 0 80px 0;
	}

	.spaces-group-container {
		width: 100%;
		height: 100%;
	}

	.spaces-container {
		margin: 0px;
		display: grid;
		padding: 0 0 80px 0;
		gap: 16px;
		grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
		position: relative;
	}

	.create-new-space-card {
		border: 2px solid var(--ds-35);
		color: var(--g-60);
		border-radius: 16px;
		width: 100%;
		height: 100%;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		min-height: 135px;
		-webkit-tap-highlight-color: transparent;
	}

	.create-new-space-card-text {
		font-weight: 600;
		margin: 0 8px;
		line-height: 24px;
	}

	.create-new-space-card:hover {
		border-color: var(--lb-70);
		color: var(--lb-50);
	}

	.loading-screen {
		padding-bottom: 80px;
	}

	.spaces-header-container {
		display: flex;
	}

	.spaces-header {
		margin-bottom: 24px;
		min-height: 36px;
		line-height: 36px;
		color: var(--white);
		flex-grow: 1;
	}

	.create-space-button {
		border-radius: 8px;
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	@media only screen and (max-width: 768px) {
		.dashboard-container {
			padding: 12px 32px 0 32px;
		}

		.dashboard-content-container {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@media only screen and (max-width: 480px) {
		.create-new-space-card,
		.create-space-button {
			display: none;
		}
	}
</style>
