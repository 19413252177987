import AppService from 'modules/app/AppService'
import {store} from 'store/Store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import GoogleUser = gapi.auth2.GoogleUser

const ACCOUNTS_PATH = 'accounts'

export interface IAccount {
	accountName: string
	domain: string
}

export default class AccountService {
	private static _instance: AccountService
	private appService: AppService = AppService.getInstance()

	account: IAccount

	hasAccount: boolean = false

	private accountRef: firebase.firestore.DocumentReference

	isInit: boolean = false
	accountInited = new signals.Signal<() => void>()

	static getInstance(): AccountService {
		if (!AccountService._instance) {
			AccountService._instance = new AccountService()
		}
		return AccountService._instance
	}
	constructor() {
		this.appService.isInit ? this.init() : this.appService.appInitialized.addOnce(this.init, this)
	}

	renameAccount(name: string) {
		return new Promise<void>((resolve, reject): void => {
			const resolveHandler = () => {
				this.account.accountName = name
				this.setAccountState(this.account)
				resolve()
			}
			const data = {
				name: name
			}
			this.accountRef.get().then(doc => {
				if (doc.exists) {
					this.accountRef.update(data).then(resolveHandler).catch(error => reject(error))
				} else {
					this.accountRef.set(data).then(resolveHandler).catch(error => reject(error))
				}
			})
		})
	}

	private init() {
		const user = store.state.googleUser
		this.hasAccount = !!user && !!user.getHostedDomain()
		if (this.hasAccount) {
			const domain = user.getHostedDomain()
			this.accountRef = firebase.firestore().collection(ACCOUNTS_PATH).doc(domain)
			this.getAccountData(domain)
		} else {
			this.setPersonalAccount(user)
			this.isInit = true
			this.accountInited.dispatch()
		}
	}

	private getAccountData(domain: string) {
		this.accountRef.get().then(doc => {
			let data: IAccount
			if (doc.exists) {
				const accountData = doc.data()
				data = {
					domain: domain,
					accountName: accountData.name
				}
			} else {
				data = {
					domain: domain,
					accountName: this.getDefaultDomainName(domain)
				}
			}
			this.setAccountState(data)
			this.accountInited.dispatch()
		})
	}

	private getDefaultDomainName(domain: string) {
		const name = domain.split('.')[0]
		return name.charAt(0).toUpperCase() + name.slice(1)
	}

	private setPersonalAccount(user: GoogleUser) {
		let name = ''
		if (user) {
			name = user.getBasicProfile().getName()
		}
		this.setAccountState({
			domain: undefined,
			accountName: name
		})
	}

	private setAccountState(data: IAccount) {
		this.account = data
		store.commit('setAccount', data)
	}
}
