<template>
	<BaseModal ref="modal" @closed="onClosed" @ready="onReady">
		<div class="modal-content-container">
			<h2 class="modal-header">Rename</h2>

			<InputWhite ref="inputfield" v-model="name" :field-title="'Name'"/>

			<div class="modal-buttons-container">
				<div
						class="rounded-button-medium db-50-button button-margin-4"
						@click="saveData"
						:class="{'disabled-light-theme-button': name.length < 3}"
				>
					Save
				</div>
				<div class="rounded-button-medium db-50-text-button cancel-button" @click="close">Cancel</div>
			</div>
		</div>

		<Loading :is-loading="isLoading"/>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import Loading from 'components/common/Loading.vue'
	import InputWhite from 'components/common/form/InputWhite.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import NotificationService from 'modules/notifications/NotificationService'
	import {SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
	import AccountService from 'modules/account/AccountService'

	const MIN_NAME_LENGTH = 3

	@Component({
		components: {InputWhite, Loading, BaseModal}
	})

	export default class RenameTeamModal extends Vue {
		private accountService: AccountService = AccountService.getInstance()

		isLoading: boolean = false
		name: string = ''

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_RENAME_TEAM, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_RENAME_TEAM, this.open)
		}

		open(name: string) {
			this.isLoading = false
			this.name = name
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		close() {
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		onClosed() {
			this.name = ''
		}

		onReady() {
			const input = this.$refs.inputfield as any
			input.focusInput()
		}

		private saveData() {
			if (this.name.length < MIN_NAME_LENGTH) {
				return
			}
			this.isLoading = true

			this.accountService.renameAccount(this.name).then(() => {
				this.close()
			}, error => {
				this.handleError()
			})
		}

		private handleError() {
			NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
			this.close()
		}
	}
</script>

<style scoped>
	.rename-margin {
		margin-top: 32px;
	}
</style>
