import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'

import {ErrorsCodes} from "./ErrorHandlingUtils";

const VIEW_IN_DRIVE_URL = 'https://drive.google.com/drive/folders/'
const EMBED_PARAMETER = '?rm=embedded&embedded=true'
const EDIT_EMBED_PARAMETER = '/edit' + EMBED_PARAMETER

export default class GoogleUtils {
	static getEditUrl(file: gapi.client.drive.File): string {
		if (file.mimeType === GoogleMimeTypes.DOCS) {
			return 'https://docs.google.com/document/d/' + file.id + EDIT_EMBED_PARAMETER
		} else if (
			file.mimeType === GoogleMimeTypes.SLIDES
		) {
			return 'https://docs.google.com/presentation/d/' + file.id + EDIT_EMBED_PARAMETER
		} else if (
			file.mimeType === GoogleMimeTypes.SHEETS ||
			file.mimeType === GoogleMimeTypes.EXCEL_XLSX ||
			file.mimeType === GoogleMimeTypes.EXCEL_XLS
		) {
			return 'https://docs.google.com/spreadsheets/d/' + file.id + EDIT_EMBED_PARAMETER
		} else if (file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT) {
			const originalFile = {
				mimeType: file.shortcutDetails.targetMimeType,
				id: file.shortcutDetails.targetId
			}
			return GoogleUtils.getEditUrl(originalFile)
		} else {
			return ''
		}
	}

	static getViewInDriveUrl(file: gapi.client.drive.File): string {
		return file && file.parents && file.parents[0] ? VIEW_IN_DRIVE_URL + file.parents[0] : ''
	}

	static getPreviewUrl(file: gapi.client.drive.File): string {
		if (file.mimeType === GoogleMimeTypes.DOCS) {
			return 'https://docs.google.com/document/d/' + file.id + '/preview'
		} else if (file.mimeType === GoogleMimeTypes.SHEETS || file.mimeType === GoogleMimeTypes.EXCEL_XLSX || file.mimeType === GoogleMimeTypes.EXCEL_XLS) {
			return 'https://docs.google.com/spreadsheets/d/' + file.id + '/preview'
		} else if (file.mimeType === GoogleMimeTypes.SLIDES) {
			return 'https://docs.google.com/presentation/d/' + file.id + '/preview'
		} else if (file.mimeType === GoogleMimeTypes.FORMS) {
			return 'https://docs.google.com/forms/d/' + file.id + '/viewform?embedded=true'
		}
	}

	static isScopesGrantedError(error: any): boolean {
		return error.code === ErrorsCodes.NOT_PERMITTED && error.data && error.data[0] && error.data[0].reason && error.data[0].reason === 'insufficientPermissions'
	}
}
