<template>
	<div class="email-editor-component">
		<div class="input-block">
			<div class="input-container" @click="focusInput" :class="{'no-bottom-radius': suggestions.length}">
				<div class="wrap-container">
					<IconMail class="icon-mail"/>
					<EmailBadge v-for="email in emails" :invitee="email" :key="email.email" @delete="deleteEmail" @change="onEmailChange"/>
					<div
							class="email-editor"
							contenteditable="true"
							ref="inputfield"
							:class="{'placeholder': !fullView}"
							@input="onInput"
							@blur="onBlur"
							@paste="onInputPaste"
							@keydown="onKeyDown">

					</div>
				</div>

				<v-popover :container="'.popover-backdrop'">
					<div class="tooltip-target user-role" v-show="fullView">
						<div class="user-role-title">{{ selectedPermission.text }}</div>
						<IconChevron class="icon-chevron"/>
					</div>

					<ShareMenu slot="popover" :show-remove="false" @action="setPermission"/>
				</v-popover>
			</div>

			<div class="search-results-container" v-if="loadingSuggestions && !showScopesTip">
				<div class="search-result">
					<UserAvatar class="suggestion-avatar"/>
					<div class="suggestion-placeholder"></div>
				</div>
				<div class="search-result">
					<UserAvatar class="suggestion-avatar"/>
					<div class="suggestion-placeholder"></div>
				</div>
				<div class="search-result">
					<UserAvatar class="suggestion-avatar"/>
					<div class="suggestion-placeholder"></div>
				</div>
			</div>

			<div class="search-results-container" v-if="suggestions.length && !loadingSuggestions && !showScopesTip" id="suggestionsContainer">
				<div
						class="search-result"
						:key="item.emailAddress"
						@mousedown.prevent
						@mousedown.stop
						@click="onResultClick(item)"
						:ref="'suggestion-' + index"
						v-for="(item, index) in suggestions"
						:class="{'active-suggestion': index === selectedSuggestionIndex}"
				>
					<UserAvatar :user="item" class="suggestion-avatar"/>
					<div class="suggestion-name" v-if="item.displayName">{{ item.displayName }}</div>
					<div class="suggestion-separator" v-if="item.displayName">|</div>
					<div class="suggestion-email">{{ item.emailAddress }}</div>
				</div>
			</div>
		</div>
		<transition name="fadeHeight">
			<div class="actions-block" v-show="fullView">
				<div class="share-tip">
					<div class="rounded-button-medium db-50-outline-button" v-if="showScopesTip" @click="requestContactDomainScopes">Connect G Suite</div>
					<div class="scopes-suggest-text" v-if="showScopesTip"> to easily pick teammates to invite</div>
				</div>

				<div class="rounded-button-medium send-button db-50-button" v-if="!loading" :class="{'disabled-light-theme-button': !canSend}" @click="sendInvitations">Send invitations</div>

				<div class="rounded-button-medium send-button disabled-light-theme-button" v-if="loading">
					<CircleLoader :width="4" :size="'20px'"/>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconMail from 'components/icons/icons/IconMail.vue'
	import Keyboard from 'enums/Keyboard'
	import {isCharAs} from 'utils/KeyboardUtils'
	import {handlePastedString, parseEmailsString, validateEmail} from 'utils/emailsParser'
	import {IEmailsEditorInviteeInfo} from 'components/common/form/emaileditor/IEmailsEditorInviteeInfo'
	import EmailBadge from 'components/common/form/emaileditor/EmailBadge.vue'
	import ShareMenu from 'components/share/ShareMenu.vue'
	import IconChevron from 'components/icons/icons/IconChevron.vue'
	import IconAddUsers from 'components/icons/icons/IconAddUsers.vue'
	import {IInviteePermission, INVITEE_PERMISSIONS, PermissionRoles} from 'modules/permissions/PermissionHelpers'
	import uniqBy from 'lodash/uniqBy'
	import {State} from 'vuex-class'
	import {GoogleScopes} from 'enums/GoogleScopes'
	import AuthService from 'modules/app/AuthService'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import PeopleAPI, {UserSuggestion} from 'api/PeopleAPI'
	import debounce from 'lodash/debounce'
	import UserAvatar from 'components/common/elements/UserAvatar.vue'
	import Utils from 'utils/Utils'
	import CircleLoader from 'components/common/loaders/CircleLoader.vue'

	@Component({
		components: {
			CircleLoader,
			UserAvatar,
			ShareMenu,
			EmailBadge,
			IconMail,
			IconChevron,
			IconAddUsers
		}
	})

	export default class EmailEditor extends Vue {
		@Prop({default: true})
		loading: boolean

		private emails: IEmailsEditorInviteeInfo[] = []
		private fullView: boolean = false
		private inviteePermissions: IInviteePermission[] = INVITEE_PERMISSIONS
		private selectedPermission: IInviteePermission = this.inviteePermissions[0]

		private showScopesTip: boolean = false

		private connections: UserSuggestion[] = []
		private suggestions: UserSuggestion[] = []
		private loadingSuggestions: boolean = false

		private selectedSuggestionIndex: number = -1

		private onTrySearchContacts = debounce(() => {
			this.searchContacts()
		}, 500)

		mounted() {
			this.updateTip()
			EventBus.$on(CustomEventNames.SCOPES_GRANTED, this.onScopesGranted)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.SCOPES_GRANTED, this.onScopesGranted)
		}

		private getConnections() {
			PeopleAPI.getConnectionsList().then(res => {
				this.connections = res
			})
		}

		private updateTip() {
			this.showScopesTip = !this.checkScopes()
		}

		private onScopesGranted(scopes: string[]) {
			this.showScopesTip = !this.checkScopes(scopes)
		}

		private checkScopes(scopes?: string[]): boolean {
			if (!scopes) {
				scopes = AuthService.getInstance().googleUser.getGrantedScopes().split(' ')
			}
			if (!scopes.find(s => s == GoogleScopes.CONTACTS) || !scopes.find(s => s == GoogleScopes.DIRECTORY)) {
				return false
			} else {
				this.getConnections()
				return true
			}
		}

		get canSend(): boolean {
			let canSend = !!this.emails.length
			this.emails.forEach(e => {
				if (!e.isValid) {
					canSend = false
				}
			})
			return canSend
		}

		private setPermission(permission: IInviteePermission) {
			this.selectedPermission = permission
		}

		private checkFillView() {
			if (this.emails.length || this.getInputContent().length) {
				this.fullView = true
			} else {
				this.fullView = false
			}
		}

		focusInput() {
			const input = this.$refs.inputfield as any
			input.focus()
			let range = document.createRange()
			let selection = window.getSelection()

			range.selectNodeContents(input)
			range.collapse(false)

			if (selection) {
				selection.removeAllRanges()
				selection.addRange(range)
			}
		}

		private getInputContent(): string {
			const input = this.$refs.inputfield as any
			return input ? input.innerText : ''
		}

		private dropInput() {
			const input = this.$refs.inputfield as any
			if (input) {
				input.innerText = ''
			}
			this.dropSuggestions()
		}

		private onBlur() {
			this.processInput(this.getInputContent())
		}

		private onKeyDown(e: KeyboardEvent) {
			if (e.keyCode == Keyboard.ENTER || isCharAs(e, [';', ',']) || e.keyCode == Keyboard.SPACE) {
				e.preventDefault()
				if (this.selectedSuggestionIndex > -1) {
					this.selectSuggestion()
				} else {
					const input = this.getInputContent()
					if (e.keyCode == Keyboard.ENTER && !input.length && this.canSend) {
						this.sendInvitations()
					} else {
						this.processInput(input)
					}
				}
			} else if (e.keyCode == Keyboard.BACKSPACE && this.getInputContent().length === 0 && this.emails.length > 0) {
				let emailToDelete = this.emails[this.emails.length - 1].email
				this.deleteEmail(emailToDelete)
			} else if (e.keyCode == Keyboard.DOWN_ARROW || e.keyCode == Keyboard.UP_ARROW) {
				e.preventDefault()
				this.highlightSuggestion(e)
			}
		}

		private onInputPaste(e: ClipboardEvent): void {
			e.preventDefault()
			this.processInput(e.clipboardData.getData('Text'), true)
		}

		private parseEmails(emailsString: string, isOnPasteParsing: boolean = false): IEmailsEditorInviteeInfo[] {
			const emails = isOnPasteParsing ? handlePastedString(emailsString) : parseEmailsString(emailsString)
			return emails.map(email => ({email, isValid: validateEmail(email)}))
		}

		private processInput(input: string, isOnPasteProcessing: boolean = false): void {
			if (!input) {
				return
			}
			let emailsEntered = this.parseEmails(input, isOnPasteProcessing)
			emailsEntered.forEach(e => {
				if (!this.emails.find(email => email.email === e.email)) {
					this.emails.push(e)
				}
			})
			this.dropInput()
		}

		private deleteEmail(email: string): void {
			this.emails = [...this.emails].filter(invitee => invitee.email !== email)
			this.checkFillView()
		}

		private onEmailChange(email: string, newEmail: string) {
			let updates = this.parseEmails(newEmail)
			let emails = [...this.emails]
			let emailChangedIndex = this.emails.findIndex(invitee => invitee.email === email)
			emails.splice(emailChangedIndex, 1, ...updates)
			emails = uniqBy(emails, 'email')
			this.emails = emails
			this.focusInput()
		}

		private requestContactDomainScopes() {
			AuthService.getInstance().requestScopes([GoogleScopes.CONTACTS, GoogleScopes.DIRECTORY])
		}

		private onInput() {
			this.checkFillView()
			this.dropSuggestions()
			this.loadingSuggestions = !!this.getInputContent()
			this.onTrySearchContacts()
		}

		private dropSuggestions() {
			this.suggestions = []
			this.selectedSuggestionIndex = -1
		}

		private searchContacts() {
			if (this.checkScopes() && this.getInputContent().length) {
				const str = this.getInputContent()
				const connections = this.connections.filter(c => c.displayName.indexOf(str) !== -1 || c.emailAddress.indexOf(str) !== -1)
				this.suggestions = uniqBy(connections, 'emailAddress')
				if (AuthService.getInstance().googleUser.getHostedDomain()) {
					PeopleAPI.searchDomainContacts(this.getInputContent()).then((result: UserSuggestion[]) => {
						this.suggestions = uniqBy(result.concat(this.suggestions), 'emailAddress')
						this.loadingSuggestions = false
					}, () => {
						this.loadingSuggestions = false
					})
				} else {
					this.loadingSuggestions = false
				}
			} else {
				this.loadingSuggestions = false
			}
		}

		private highlightSuggestion(e: KeyboardEvent) {
			let direction
			if (e.keyCode === Keyboard.UP_ARROW) {
				direction = 'up'
			} else if (e.keyCode === Keyboard.DOWN_ARROW) {
				direction = 'down'
			} else {
				return
			}
			const results = this.suggestions

			if (results.length === 0) {
				return
			}

			let selectedItemIndex = this.selectedSuggestionIndex

			if (selectedItemIndex === -1) {
				if (direction === 'down') {
					selectedItemIndex = 0
				} else {
					selectedItemIndex = results.length - 1
				}
			} else {
				if (direction === 'down') {
					selectedItemIndex++
					if (selectedItemIndex === results.length) {
						selectedItemIndex = 0
					}
				} else {
					selectedItemIndex--
					if (selectedItemIndex < 0) {
						selectedItemIndex = results.length - 1
					}
				}
			}

			this.selectedSuggestionIndex = selectedItemIndex

			let elements = this.$refs['suggestion-' + selectedItemIndex]

			if (elements && elements[0]) {
				Utils.scrollIntoView(elements[0], document.getElementById('suggestionsContainer'))
			}
		}

		private selectSuggestion(suggestion?: UserSuggestion) {
			if (!suggestion) {
				if (this.selectedSuggestionIndex == -1) {
					return
				}
				suggestion = this.suggestions[this.selectedSuggestionIndex]
			}
			this.processInput(suggestion.emailAddress)
			this.dropSuggestions()
		}

		private onResultClick(item: UserSuggestion) {
			this.selectSuggestion(item)
		}

		private sendInvitations() {
			if (!this.canSend) {
				return
			}
			this.$emit('add-users', this.emails, this.selectedPermission)
			this.emails = []
		}
	}
</script>

<style scoped>
	.email-editor-component {
		display: flex;
		flex-direction: column;
	}

	.input-block,
	.actions-block {
		display: flex;
		position: relative;
	}

	.actions-block {
		margin-top: 20px;
	}

	.email-editor {
		flex-grow: 1;
		outline: none;
		word-break: break-word;
		line-height: 24px;
		margin-bottom: 6px;
	}

	.send-button {
		width: 120px;
		justify-content: center;
	}

	.send-button svg {
		margin-right: 0 !important;
	}

	.input-container {
		flex-grow: 1;
		position: relative;
		display: flex;
		padding: 12px 16px 8px 16px;
		box-sizing: border-box;
		background-color: var(--g-20);
		border-radius: 8px;
	}

	.no-bottom-radius {
		border-radius: 8px 8px 0 0;
	}

	.search-results-container {
		position: absolute;
		width: 100%;
		bottom: 0;
		transform: translateY(100%);
		display: flex;
		flex-direction: column;
		background-color: var(--white);
		z-index: 1;
		border: 1px solid var(--g-20);
		border-top: none;
		box-sizing: border-box;
		padding: 4px 0;
		border-radius: 0 0 8px 8px;
		max-height: 208px;
		overflow: hidden;
		overflow-y: auto;
		scrollbar-width: none;
	}

	.search-results-container::-webkit-scrollbar {
		width: 0;
	}

	.search-result {
		display: flex;
		align-items: center;
		padding: 6px 11px;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
	}

	.suggestion-avatar {
		margin-right: 8px;
	}

	.suggestion-separator {
		color: var(--g-60);
		margin: 0 8px;
	}

	.suggestion-email {
		color: var(--g-60);
	}

	.active-suggestion {
		background-color: var(--db-5);
	}

	.suggestion-placeholder {
		background-color: var(--g-20);
		border-radius: 4px;
		height: 20px;
		flex-grow: 1;
	}

	.wrap-container {
		display: flex;
		flex-wrap: wrap;
		flex-grow: 1;
	}

	.icon-mail {
		flex: none;
		width: 20px;
		height: 20px;
		color: var(--primary-color);
		margin: 2px 8px 0 0;
	}

	.fadeHeight-enter-active,
	.fadeHeight-leave-active {
		transition: all 0.2s;
		max-height: 100px;
	}

	.fadeHeight-enter,
	.fadeHeight-leave-to {
		opacity: 0;
		max-height: 0;
	}

	.user-role {
		display: flex;
		flex: none;
		align-items: center;
		color: var(--db-45);
		cursor: pointer;
		margin-top: 2px;
		padding-left: 8px;
	}

	.icon-chevron {
		width: 16px;
		height: 16px;
		margin-top: 3px;
		margin-left: 2px;
	}

	.placeholder::before {
		content: 'Enter emails...';
		color: var(--g-60);
	}

	.share-tip {
		display: flex;
		flex-grow: 1;
		color: var(--db-45);
	}

	.scopes-suggest-text {
		line-height: 36px;
		padding: 0 8px;
		font-weight: 600;
		user-select: none;
		font-size: 12px;
	}

	.loading-button {
		outline: none;
		border-radius: 20px;
		height: 36px;
		line-height: 36px;
		padding: 0 16px;
		font-weight: 600;
		cursor: pointer;
		user-select: none;
		font-size: 12px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		text-decoration: none;
	}
</style>
