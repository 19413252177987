<template>
	<BaseModal ref="modal" @ready="onReady">
		<div class="modal-content-container">
			<h2 class="modal-header">Invite your team</h2>
			<div class="modal-paragraph">To invite your team just copy a link and send it to your teammates.</div>

			<InputWhite ref="inputfield" v-model="inviteLink" :readonly="true"/>

			<div class="modal-buttons-container">
				<button
						class="rounded-button-medium db-50-button button-margin-4"
						id="copyLinkButton"
						:data-clipboard-text="inviteLink"
				>
					<IconLink/> Copy link</button>
				<div class="copied-message" v-if="showCopiedMessage">Link copied!</div>
			</div>
		</div>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IconLink from 'components/icons/icons/IconLink.vue'
	import ClipboardJS from 'clipboard'
	import InputWhite from 'components/common/form/InputWhite.vue'

	@Component({
		components: {
			InputWhite,
			BaseModal,
			IconLink,
		}
	})

	export default class InviteTeamModal extends Vue {
		private clipboard: ClipboardJS

		private showCopiedMessage: boolean = false

		get inviteLink(): string {
			return 'https://app.spaceli.io/?ref=invite'
		}

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_INVITE_TEAM, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_INVITE_TEAM, this.open)
		}

		open() {
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		onReady() {
			const button = document.getElementById('copyLinkButton')
			this.clipboard = new ClipboardJS(button)
			this.clipboard.on('success', () => {
				this.showCopiedMessage = true
				setTimeout(() => {
					this.showCopiedMessage = false
				}, 2000)
			})
		}

		close() {
			this.clipboard.destroy()
			const modal = this.$refs.modal as any
			modal.closeModal()
		}
	}
</script>

<style scoped>
	.modal-paragraph {
		margin-bottom: 16px;
	}

	.copied-message {
		color: var(--db-50);
		margin-left: 12px;
		user-select: none;
	}
</style>
