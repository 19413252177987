import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from 'components/dashboard/Dashboard.vue'
import Space from 'components/space/Space.vue'
import Login from 'components/Login.vue'

Vue.use(VueRouter)

export enum RouteName {
	SPACE = 'space',
	PAGE = 'page',
	DASHBOARD = 'dashboard',
	LOGIN = 'login',
}

export enum RouteParams {
	SPACE_ID = 'folderId',
	PAGE_ID = 'pageId',
	DASHBOARD_FILTER = 'dashboardFilter',
}

const routes = [
	{
		path: '/dashboard/:' + RouteParams.DASHBOARD_FILTER,
		name: RouteName.DASHBOARD,
		component: Dashboard,
	},
	{
		path:'/login',
		name: RouteName.LOGIN,
		component: Login
	},
	{
		path: '/space/:' + RouteParams.SPACE_ID,
		name: RouteName.SPACE,
		component: Space,
		children: [
			{
				path: 'page/:' + RouteParams.PAGE_ID,
				name: RouteName.PAGE,
				component: Space
			}
		]
	},
	{
		path: '/',
		redirect: '/dashboard/all'
	},
	{
		path: '*',
		redirect: '/dashboard/all'
	},
]

export const router = new VueRouter({
	mode: 'history',
	routes
})
