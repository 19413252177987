<template>
	<div class="dashboard-header">
		<a href="https://spaceli.io" class="logo">
			<LogoTransparent class="header-logo"/>
		</a>

		<div class="header-middle">
			<div class="open-sidebar-button" @click="openSidebar">
				<IconMenu/>
			</div>
		</div>
		<div class="header-right">
			<CurrentUserAvatar/>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import CurrentUserAvatar from 'components/common/CurrentUserAvatar.vue'
	import LogoTransparent from 'components/icons/logo/LogoTransparent.vue'
	import IconMenu from 'components/icons/icons/IconMenu.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'

	@Component({
		components: {
			CurrentUserAvatar,
			LogoTransparent,
			IconMenu,
		},
	})

	export default class DashboardHeader extends Vue {
		private openSidebar() {
			EventBus.$emit(CustomEventNames.OPEN_DASHBOARD_SIDEBAR)
		}
	}
</script>

<style scoped>
	.dashboard-header {
		width: 100%;
		height: 80px;
		display: flex;
		background-color: var(--ds-50);
	}

	.logo {
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin-left: 28px;
		outline: none;
	}

	.header-logo {
		width: 36px;
		height: 36px;
		color: var(--lb-70);
	}

	.header-middle {
		flex-grow: 1;
		display: flex;
		align-items: center;
	}

	.header-right {
		display: flex;
		align-items: center;
		margin-right: 28px;
	}

	.open-sidebar-button {
		display: none;
		margin-left: 32px;
		border-radius: 8px;
		width: 36px;
		height: 36px;
		align-items: center;
		justify-content: center;
		border: 1px solid var(--ds-35);
		color: var(--white);
	}

	@media only screen and (max-width: 768px) {
		.logo {
			display: none;
		}

		.open-sidebar-button {
			display: flex;
		}

		.header-right {
			margin-right: 32px;
		}
	}

	@media only screen and (max-width: 480px) {

	}
</style>
