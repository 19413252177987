import {GOOGLE_CONFIG} from 'modules/GoogleConfig'
import NotificationService from 'modules/notifications/NotificationService'
import {FAILED_LOAD_GAPI} from 'modules/notifications/NotificationMessages'

const GAPI_URL = 'https://apis.google.com/js/api.js'
const DEVELOPER_KEY = 'AIzaSyDkFX8zBRXD6HCRuNMjvJu8QQVzEOju09E'
const APP_ID = '618650423333'

export default class GoogleApiClient {
	private static _instance: GoogleApiClient

	private gapiLoadClientPromise: any = null

	static getInstance(): GoogleApiClient {
		if (!GoogleApiClient._instance) {
			GoogleApiClient._instance = new GoogleApiClient()
		}
		return GoogleApiClient._instance
	}

	constructor() {}

	getGapiClient(): Promise<typeof gapi> {
		return new Promise((resolve, reject) => {
			if (
				this.gapiLoadClientPromise &&
				this.gapiLoadClientPromise.status === 0
			) {
				return resolve(gapi)
			} else {
				this.resolveAuth2Client(resolve, reject)
			}
		})
	}

	buildPicker(mimeTypes: string, includeFolders = true, multiselect = false): google.picker.Picker {
		const currentUser = gapi.auth2.getAuthInstance().currentUser.get()
		const authResponse = currentUser.getAuthResponse(true)

		const docsView: any = new google.picker.DocsView(google.picker.ViewId.DOCS)
			.setParent("root")
			.setIncludeFolders(true)
			.setMimeTypes(mimeTypes)
			.setSelectFolderEnabled(includeFolders)
			.setMode(window.google.picker.DocsViewMode.LIST)

		const sharedWithMeView: any = new google.picker.DocsView(google.picker.ViewId.DOCS)
			.setOwnedByMe(false)
			.setMimeTypes(mimeTypes)
			.setMode(window.google.picker.DocsViewMode.LIST)

		const sharedDriveView: any = new google.picker.DocsView(google.picker.ViewId.DOCS)
			.setIncludeFolders(false)
			.setMimeTypes(mimeTypes)
			.setSelectFolderEnabled(includeFolders)
			.setEnableDrives(true)
			.setLabel("Shared Drives")
			.setMode(window.google.picker.DocsViewMode.LIST)

		return new google.picker.PickerBuilder().
		addView(docsView).
		// addView(sharedWithMeView).
		addView(sharedDriveView).
		setAppId(APP_ID).
		enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES).
		setOAuthToken(authResponse.access_token).
		setDeveloperKey(DEVELOPER_KEY).
		build()
	}

	private resolveAuth2Client = (resolve, reject) => {
		console.time('Loading')
		this.loadGAPIScript().then(() => {
			if (!gapi) {
				NotificationService.getInstance().showNotification(FAILED_LOAD_GAPI, true)
				return
			}
			if (!gapi.auth) {
				gapi.load('client:auth2', () => {
					this.gapiLoadClientPromise = gapi.client
						.init({
							apiKey: GOOGLE_CONFIG.apiKey,
							clientId: GOOGLE_CONFIG.clientId,
							discoveryDocs: GOOGLE_CONFIG.discoveryDocs,
							scope: GOOGLE_CONFIG.scopes.join(' '),
						})
						.then(() => {
							console.log('gapi client initialised.')
							console.timeLog('Loading')
							this.gapiLoadClientPromise.status = 0
							resolve(gapi)
						})
						.catch(err => {
							if (err.error) {
								const error = err.error
								NotificationService.getInstance().showNotification(FAILED_LOAD_GAPI, true)
								console.error(
									'Failed to initialize gapi: %s (status=%s, code=%s)', error.message, error.status, error.code, err)
							}
						})
				})
				gapi.load('picker', () => {})
			} else {
				resolve(gapi)
			}
		})
	}

	private loadGAPIScript(): Promise<void> {
		return new Promise((resolve, reject) => {
			const script: any = document.createElement('script')
			script.src = GAPI_URL
			script.onreadystatechange = script.onload = () => {
				const interval = setInterval(() => {
					if (!script.readyState || /loaded|complete/.test(script.readyState)) {
						clearInterval(interval)
						console.log('gapi.js loaded.')
						console.timeLog('Loading')
						resolve()
					}
				}, 100)
			}
			script.onerror = () => {
				console.log('gapi.js ONERROR')
				script.src = ''
				script.src = GAPI_URL
			}
			document.getElementsByTagName('head')[0].appendChild(script)
		})
	}
}
