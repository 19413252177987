<template>
	<transition name="full-screen-slide-bottom" @after-enter="onModalOpened">
		<div class="create-file-modal" v-if="isOpened">
			<transition name="fade-slide-element" @enter="onEnter">
				<div class="create-file-slide" v-if="currentSlide === 0" key="select">
					<h2 class="create-file-modal-header">What would you like to add?</h2>

					<div class="elements-groups-container">

						<div class="elements-container">
<!--							<div class="add-element" @click="addExistingFile">-->
<!--								<IconShortcut48 class="add-element-icon"/>-->
<!--								<div class="add-element-text-container">-->
<!--									<div class="add-element-title">Add an existing file</div>-->
<!--									<div class="add-element-desc">Move, copy or create a shortcut</div>-->
<!--								</div>-->
<!--							</div>-->
														<div class="add-element" @click="importByMove">
															<IconMoveFrom48 class="add-element-icon"/>
															<div class="add-element-text-container">
																<div class="add-element-title">Import by move</div>
																<div class="add-element-desc">Move a file from other folder</div>
															</div>
														</div>

														<div class="add-element" @click="importByCopy">
															<IconCopy48 class="add-element-icon"/>
															<div class="add-element-text-container">
																<div class="add-element-title">Import by copy</div>
																<div class="add-element-desc">Copy a file from other folder</div>
															</div>
														</div>

														<div class="add-element" @click="importAsShortcut">
															<IconShortcut48 class="add-element-icon"/>
															<div class="add-element-text-container">
																<div class="add-element-title">Import as shortcut</div>
																<div class="add-element-desc">Create a shortcut to a file or folder</div>
															</div>
														</div>
						</div>

						<div class="elements-container">
							<div
									class="add-element"
									:key="element.title"
									v-for="element in googleFiles"
									@click="selectItem(element)">
								<img class="add-element-icon" :src="element.icon">
								<div class="add-element-text-container">
									<div class="add-element-title">{{ element.title }}</div>
									<div class="add-element-desc">{{ element.desc }}</div>
								</div>
							</div>
						</div>

						<div class="elements-container">
							<div
									class="add-element"
									:key="element.title"
									v-for="element in externalFiles"
									@click="selectItem(element)">
								<img class="add-element-icon" :src="element.icon">
								<div class="add-element-text-container">
									<div class="add-element-title">{{ element.title }}</div>
									<div class="add-element-desc">{{ element.desc }}</div>
								</div>
							</div>
						</div>

					</div>
				</div>

				<div class="create-file-slide" v-else-if="currentSlide === 1" key="naming">
					<div class="create-file-slide second-slide">
						<h2 class="second-slide-header">{{ selectedFileType.buttonText }}</h2>

						<InputWhite class="input-margin" ref="titleInput" v-model="titleString" :field-title="'Name'" :placeholder="'Enter a title'"/>

						<TextareaWhite
								v-if="selectedFileType.codeField"
								v-model="codeFieldValue"
								:field-title="selectedFileType.codeFieldTitle || 'iFrame code'"
								:height="116"
								:placeholder="selectedFileType.codeFieldPlaceholder || '<iframe src=...></iframe>'"
						/>

						<a :href="selectedFileType.tipLink" class="iframe-tip" v-if="selectedFileType.codeField" target="_blank">{{ selectedFileType.tip }}</a>

						<div class="buttons-container">
							<div
									class="rounded-button-medium db-50-button"
									:class="{'disabled-light-theme-button': !isButtonEnabled}"
									@click="createFile">
								{{ selectedFileType.buttonText }}
							</div>
						</div>
					</div>

					<div class="circle-button g20-button back-button" @click="backToFirstSlide">
						<IconArrowLeft/>
					</div>
				</div>

				<div class="create-file-slide loading-slide" v-if="currentSlide === 2" key="loading">
					<CircleLoader :width="4"/>
				</div>

				<div class="create-file-slide" v-if="currentSlide === 3" key="options">
					<h2 class="create-file-modal-header">What would you like to do?</h2>

					<div class="elements-groups-container">
						<div class="elements-container">
							<div class="add-element" @click="importByMove">
								<IconMoveFrom48 class="add-element-icon"/>
								<div class="add-element-text-container">
									<div class="add-element-title">Import by move</div>
									<div class="add-element-desc">Move a file from other folder</div>
								</div>
							</div>

							<div class="add-element" @click="importByCopy">
								<IconCopy48 class="add-element-icon"/>
								<div class="add-element-text-container">
									<div class="add-element-title">Import by copy</div>
									<div class="add-element-desc">Copy a file from other folder</div>
								</div>
							</div>

							<div class="add-element" @click="importAsShortcut">
								<IconShortcut48 class="add-element-icon"/>
								<div class="add-element-text-container">
									<div class="add-element-title">Import as shortcut</div>
									<div class="add-element-desc">Create a shortcut to a file or folder</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>

			<div class="circle-button g20-button close-button" @click="close">
				<IconCross/>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {ExternalFileDefs, GoogleFileDefs, IFileDef} from 'filedefs/FileDefs'
	import IconCross from 'components/icons/icons/IconCross.vue'
	import IconArrowLeft from 'components/icons/icons/IconArrowLeft.vue'
	import DriveAPI from 'api/DriveAPI'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import CircleLoader from 'components/common/loaders/CircleLoader.vue'
	import NotificationService from 'modules/notifications/NotificationService'
	import {CANT_COPY_FILE, INVALID_IFRAME_CODE, SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
	import InputWhite from 'components/common/form/InputWhite.vue'
	import TextareaWhite from 'components/common/form/TextareaWhite.vue'
	import IconMoveFrom48 from 'components/icons/icons/IconMoveFrom48.vue'
	import IconCopy48 from 'components/icons/icons/IconCopy48.vue'
	import Utils from 'utils/Utils'
	import Stat from 'modules/stat/Stat'
	import IconShortcut48 from 'components/icons/icons/IconShortcut48.vue'
	import OrderGenerator from 'utils/OrderGenerator'
	import FileUtils from 'utils/FileUtils'
	import {SpaceliProperties} from 'enums/FileProps'

	@Component({
		components: {
			TextareaWhite,
			InputWhite,
			CircleLoader,
			IconCross,
			IconArrowLeft,
			IconMoveFrom48,
			IconCopy48,
			IconShortcut48
		}
	})

	export default class CreateFile extends Vue {
		private isOpened: boolean = false
		private parentFile: gapi.client.drive.File

		private googleFiles = GoogleFileDefs
		private externalFiles = ExternalFileDefs

		private selectedFileType: IFileDef|undefined
		private currentSlide: number = 0

		private titleString: string = ''
		private codeFieldValue: string = ''

		private existingFile: gapi.client.drive.File

		open(file: gapi.client.drive.File, item?: IFileDef) {
			this.currentSlide = 0
			this.parentFile = file
			this.isOpened = true
			if (item) {
				this.selectItem(item)
			}
		}

		close() {
			this.parentFile = undefined
			this.selectedFileType = undefined
			this.titleString = ''
			this.codeFieldValue = ''
			this.isOpened = false
		}

		private onEsc() {
			if (!this.isOpened) {
				return
			}
			this.close()
		}

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_CREATE_FILE, this.open)
			EventBus.$on(CustomEventNames.ESCAPE_PRESSED, this.onEsc)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_CREATE_FILE, this.open)
			EventBus.$off(CustomEventNames.ESCAPE_PRESSED, this.onEsc)
		}

		private selectItem(item: IFileDef) {
			this.selectedFileType = item
			this.currentSlide = 1
		}

		private backToFirstSlide() {
			this.selectedFileType = undefined
			this.currentSlide = 0
			this.titleString = ''
			this.codeFieldValue = ''
		}

		private onEnter() {
			const titleInput = this.$refs.titleInput as any
			if (titleInput) {
				titleInput.focusInput()
			}
		}

		private onModalOpened() {
			if (this.selectedFileType) {
				this.onEnter()
			}
		}

		get isButtonEnabled() {
			return this.titleString.length && (this.selectedFileType.codeField ? this.codeFieldValue.length : true)
		}

		private createFile() {
			let shortcutData
			if (this.selectedFileType.mimeType === GoogleMimeTypes.EXTERNAL_SHORTCUT) {
				if (!this.codeFieldValue) {
					NotificationService.getInstance().showNotification(INVALID_IFRAME_CODE)
					return
				} else {
					if (this.selectedFileType.type === 'link') {
						shortcutData = {
							src: this.codeFieldValue
						}
					} else {
						shortcutData = this.getIframeAttrs(this.codeFieldValue)
					}
					shortcutData.type = this.selectedFileType.type

					if (!shortcutData.src) {
						NotificationService.getInstance().showNotification(INVALID_IFRAME_CODE)
						return
					}
				}
			}

			this.currentSlide = 2

			const fileIndex = this.getNewFileIndex()

			DriveAPI.createNewFile(this.titleString, this.selectedFileType.mimeType, this.parentFile, fileIndex, shortcutData).then(
				(data: {file: any, newFolder: gapi.client.drive.File|undefined}) => {
					if (data.file.error) {
						const message = data.file.error?.message || SOMETHING_WENT_WRONG
						NotificationService.getInstance().showNotification(message, true)
					} else {
						Stat.createFile(this.selectedFileType.type)
						let folderForUpdate
						if (data.newFolder) {
							folderForUpdate = data.newFolder.parents[0]
						}
						EventBus.$emit(CustomEventNames.FILE_CREATED, data.file, true, true, folderForUpdate)
					}
					this.close()
				})
		}

		private getNewFileIndex(): string {
			const leftIndex = ''
			let rightIndex = ''
			const children = FileUtils.getChildrenForFolder(this.parentFile)
			if (children[0]) {
				rightIndex = Utils.getItemIndex(children[0])
			}
			return OrderGenerator.generateBetween(leftIndex, rightIndex)
		}

		private getIframeAttrs(code: string) {
			let tempElement = document.createElement('div')
			tempElement.innerHTML = code
			const frame: HTMLIFrameElement = tempElement.childNodes[0] as HTMLIFrameElement
			return {
				src: frame.src,
				width: frame.width,
				height: frame.height
			}
		}

		private importByMove() {
			Utils.importFileByMove(this.parentFile, (finished: boolean) => {
				if (!finished) {
					this.currentSlide = 2
				} else {
					this.close()
				}
			})
		}

		private importByCopy() {
			Utils.importFileByCopy(this.parentFile, (finished: boolean) => {
				if (!finished) {
					this.currentSlide = 2
				} else {
					this.close()
				}
			})
		}

		private importAsShortcut() {
			Utils.importAsShortcut(this.parentFile, (finished: boolean) => {
				if (!finished) {
					this.currentSlide = 2
				} else {
					this.close()
				}
			})
		}

		private addExistingFile() {
			Utils.getFilePicker(true, result => {
				if (result && result.action && result.action == 'picked' && result.docs && result.docs[0]) {

					DriveAPI.getFile(result.docs[0].id).then((file: any) => {
						if (!file.error) {
							// const c = file.capabilities
							this.existingFile = file
							this.currentSlide = 3
							// if (c.canCopy) {
							// 	const res: any = {
							// 		parents: [parentFile.id],
							// 		properties: {
							// 			[SpaceliProperties.SPACELI_PAGE_INDEX]: null,
							// 		},
							// 		appProperties: {
							// 			[SpaceliProperties.HIDDEN]: null
							// 		}
							// 	}
							// 	DriveAPI.copyFile(file, res).then(copiedFile => {
							// 		EventBus.$emit(CustomEventNames.FILE_CREATED, copiedFile, false, copiedFile.mimeType !== GoogleMimeTypes.FOLDER)
							// 		if (copiedFile.mimeType === GoogleMimeTypes.FOLDER) {
							// 			EventBus.$emit(CustomEventNames.UPDATE_SPACE_FILES)
							// 		}
							// 		Stat.copyFile()
							// 		callback(true)
							// 	}, error => {
							// 		NotificationService.getInstance().showNotification(error.message)
							// 		callback(true)
							// 	})
							// } else {
							// 	NotificationService.getInstance().showNotification(CANT_COPY_FILE)
							// 	callback(true)
							// }
						} else {
							NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
							this.close()
						}
					})
				}
			})
		}
	}
</script>

<style scoped>
	.create-file-modal {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: var(--white);
		z-index: var(--modal-z-index);
		user-select: none;
	}

	.create-file-slide {
		width: 100%;
		height: 100%;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		scrollbar-width: none;
	}

	.second-slide {
		width: 350px;
	}

	.second-slide-header {
		margin-top: 120px;
		margin-bottom: 36px;
	}

	.loading-slide {
		justify-content: center;
	}

	.create-file-modal-header {
		margin-top: 120px;
		margin-bottom: 48px;
	}

	.elements-groups-container {
		display: flex;
		flex-direction: column;
	}

	.create-file-slide::-webkit-scrollbar {
		width: 0;
	}

	.elements-container {
		margin: 0px;
		display: grid;
		padding: 0 0 48px 0;
		gap: 16px;
		grid-template-columns: repeat(3, 1fr);
		position: relative;
	}

	.add-element {
		background-color: var(--g-10);
		border-radius: 16px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 10px 14px;
		border: 2px solid transparent;
		transition: border-color 0.1s;
		cursor: pointer;
	}

	.add-element:hover {
		border-color: var(--db-50);
	}

	.add-element-icon {
		width: 48px;
		height: 48px;
		margin-right: 8px;
	}

	.add-element-text-container {
		display: flex;
		flex-direction: column;
	}

	.add-element-title {
		font-weight: 600;
		color: var(--primary-color);
	}

	.add-element-desc {
		color: var(--g-60);
	}

	.buttons-container {
		display: flex;
	}

	.close-button {
		position: absolute;
		top: 36px;
		right: 36px;
	}

	.input-margin {
		margin-bottom: 32px;
	}

	.iframe-tip {
		color: var(--db-45);
		text-decoration: none;
		margin-bottom: 32px;
		margin-top: 12px;
	}

	@media only screen and (max-width: 768px) {
		.elements-container {
			grid-template-columns: repeat(2, 1fr);
		}
	}
</style>
