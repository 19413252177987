<template>
	<div class="sidebar-item" :id="file.id" :data-expanded="expanded" :data-dnd-disabled="!allowDND">
		<div
				class="sidebar-item-title-container"
				:class="{'active': currentPageId == file.id, 'item-hovered': menuOpened}">

			<div
					class="indent-line"
					:class="{'indent-line-first': index === 0 && level - 1 === i}"
					:key="lvl"
					v-for="(lvl, i) in level">
			</div>

			<div
					class="sidebar-item-title"
					:class="{'bold-title': boldTitle}"
					@mousedown="onMouseDown"
					@click="fileClicked(file.id)"
					v-tooltip="{content: name, placement: 'top', delay: 2000}">
				{{ name }}
			</div>

			<slot></slot>

			<v-popover
					:container="'.popover-backdrop'"
					@show="onMenuShow(true)"
					@hide="onMenuShow(false)">
				<div class="tooltip-target small-button item-button" v-if="showButtons && menuPermitted">
					<Icon3Dots/>
				</div>

				<FileMenu slot="popover" :file="file" :file-link="fileLink"/>
			</v-popover>

			<div
					v-if="isFolder && ((showButtons && canEdit) || (permanentShowAdd && canEdit))"
					class="item-button"
					:class="{'expand-icon plus-button': permanentShowAdd, 'small-button': !permanentShowAdd}"
					@click="openCreateFile">
				<IconPlus/>
			</div>

			<IconChevron
					class="expand-icon"
					:class="{'expanded': expanded}"
					v-if="expandIconVisibility"
					@click.native="toggleExpandChildren"/>

			<IconExternalLink class="external-link-icon" v-if="isExternalLink"/>
		</div>

		<div class="children-container" v-if="childrenVisibility" v-show="childContainerVisibility">
			<SidebarItem
					v-for="(file, i) in children"
					:index="i"
					:key="file.id"
					:file="file"
					:level="level + 1"/>
			<SidebarItem
					v-if="!children.length && !isError"
					class="no-pages"
					:file="{}"
					:level="level + 1"
					:showChildren="false"
					:showButtons="false"
					:customName="'No pages inside'"/>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import IconChevron from 'components/icons/icons/IconChevron.vue'
	import Icon3Dots from 'components/icons/icons/Icon3Dots.vue'
	import IconPlus from 'components/icons/icons/IconPlus.vue'
	import FileMenu from 'components/common/menus/FileMenu.vue'
	import Utils from 'utils/Utils'
	import PermissionService from 'modules/permissions/PermissionService'
	import {SpaceAction} from 'modules/permissions/PermissionHelpers'
	import DNDService from 'modules/space/DNDService'
	import FileUtils from 'utils/FileUtils'
	import IconExternalLink from 'components/icons/icons/IconExternalLink.vue'
	import {IChildrenMap, IErrorsMap} from 'modules/space/SpaceService'

	@Component({
		components: {
			FileMenu,
			IconChevron,
			Icon3Dots,
			IconPlus,
			IconExternalLink
		},
		name: 'SidebarItem'
	})

	export default class SidebarItem extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@State(state => state.childrenMap)
		childrenMap: IChildrenMap

		@State(state => state.errorsMap)
		errorsMap: IErrorsMap

		@Prop({default: true})
		showButtons: boolean

		@Prop({default: false})
		permanentShowAdd: boolean

		@Prop({default: 0})
		index: number

		@Prop({default: 0})
		level: number

		@Prop({default: true})
		showChildren: boolean

		@Prop()
		customName: string

		@Prop({default: true})
		allowDND: boolean

		@Prop({default: false})
		boldTitle: boolean

		@Prop({default: false})
		defaultExpanded: boolean

		@State
		currentPageId: string

		expanded: boolean = false
		menuOpened: boolean = false

		private dndService: DNDService = DNDService.getInstance()

		@Watch('currentPageId')
		onCurrentPageChanged(newVal) {
			if (newVal === this.file.id) {
				this.expandParents()
			}
		}

		@Watch('childrenMap')
		onChildrenMapChanged() {
			if (!this.defaultExpanded) {
				return
			}
			if (this.childrenMap[this.file.id]) {
				this.expandChildren()
			}
		}

		mounted() {
			if (this.currentPageId === this.file.id) {
				this.expandParents()
			}

			if (this.defaultExpanded && this.children.length) {
				this.expandChildren()
			}
			EventBus.$on(CustomEventNames.DRAG_STARTED, this.onDragStart)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.DRAG_STARTED, this.onDragStart)
		}

		get fileLink(): string {
			return Utils.getPageLinkInCurrentSpace(this.file.id)
		}

		get isError(): boolean {
			return !!this.errorsMap[this.file.id]
		}

		get isShortcutAndFolderInSpace(): boolean {
			return FileUtils.isShortcutForFolder(this.file) && FileUtils.isFileExistInSpace(this.file.shortcutDetails.targetId)
		}

		get parentIsTargetForShortcut(): boolean {
			return FileUtils.isShortcutForFolder(this.file) && this.file.parents.includes(this.file.shortcutDetails.targetId)
		}

		get childrenVisibility(): boolean {
			return this.showChildren && !this.parentIsTargetForShortcut && !this.isShortcutAndFolderInSpace
		}

		get menuPermitted(): boolean {
			return PermissionService.getInstance().calcPermission(this.file, SpaceAction.EDIT_PAGE) && PermissionService.getInstance().calcPermission(this.file, SpaceAction.RENAME_PAGE)
		}

		get canEdit(): boolean {
			return PermissionService.getInstance().calcPermission(this.file, SpaceAction.EDIT_PAGE)
		}

		get name(): string {
			return this.customName ? this.customName : this.file.name
		}

		get children(): gapi.client.drive.File[] {
			if (!FileUtils.isFolder(this.file)) {
				return []
			}
			return FileUtils.getChildrenForFolder(this.file)
		}

		get expandIconVisibility(): boolean {
			return this.childrenVisibility && FileUtils.isFolder(this.file) && !this.isError
		}

		get childContainerVisibility(): boolean {
			return this.expanded && FileUtils.isFolder(this.file)
		}

		get isFolder(): boolean {
			return FileUtils.isFolder(this.file)
		}

		get isExternalLink(): boolean {
			return FileUtils.isExternalLink(this.file)
		}

		private onDragStart(fileId: string) {
			if (fileId === this.file.id) {
				this.expanded = false
			}
		}

		private expandParents() {
			let parent = this.$parent as any
			while (parent.expandChildren) {
				parent.expandChildren()
				parent = parent.$parent
			}
			setTimeout(() => {
				this.scrollToItem()
			})
		}

		private scrollToItem() {
			Utils.scrollIntoView(this.$el, document.getElementById('sidebarItemsContainer'), 200)
		}

		private fileClicked(fileId) {
			if (fileId) {
				if (this.isExternalLink) {
					window.open(this.file.description, '_blank')
					return
				}
				this.expandChildren()
				if (fileId === this.file.id && this.isShortcutAndFolderInSpace) {
					EventBus.$emit(CustomEventNames.SIDEBAR_ITEM_SELECTED, this.file.shortcutDetails.targetId)
					return
				}
				EventBus.$emit(CustomEventNames.SIDEBAR_ITEM_SELECTED, fileId)
			}
		}

		toggleExpandChildren(e: Event) {
			e.stopPropagation()
			this.expanded = !this.expanded
		}

		expandChildren() {
			this.expanded = true
		}

		private openCreateFile() {
			EventBus.$emit(CustomEventNames.OPEN_CREATE_FILE, this.file)
		}

		private onMenuShow(state: boolean) {
			this.menuOpened = state
		}

		private onMouseDown(e: MouseEvent) {
			if (this.allowDND) {
				this.dndService.onMouseDown(e, this.$el)
			}
		}
	}
</script>

<style scoped>
	.sidebar-item {
		color: var(--primary-color);
		cursor: pointer;
		flex: none;
		-webkit-tap-highlight-color: transparent;
	}

	.sidebar-item-title-container {
		padding: 0 8px 0 16px;
		line-height: 28px;
		display: flex;
		position: relative;
		border-radius: 6px;
		margin: 0px 20px;
	}

	.sidebar-item-title-container:not(.active):hover,
	.item-hovered {
		background-color: var(--g-20);
		z-index: 1;
	}

	.sidebar-item-title-container:hover .small-button,
	.item-hovered .small-button {
		display: block;
	}

	.sidebar-item-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex-grow: 1;
		box-sizing: border-box;
		position: relative;
		padding-right: 8px;
		-webkit-tap-highlight-color: transparent;
	}

	.small-button {
		width: 20px;
		height: 20px;
		margin: 4px 4px 4px 0;
		border-radius: 4px;
		color: var(--g-60);
		cursor: pointer;
		display: none;
	}

	.small-button svg,
	.expand-icon svg {
		width: 20px;
		height: 20px;
	}

	.expand-icon {
		width: 20px;
		height: 20px;
		margin: 4px 0;
		flex: none;
		color: var(--db-50);
		border-radius: 4px;
		transform: rotate(-90deg);
		transition: transform 0.2s;
	}

	.external-link-icon {
		width: 20px;
		height: 20px;
		color: var(--g-60);
		margin: 4px 0;
    flex: none;
	}

	.plus-button {
		transform: rotate(0);
		height: 28px;
		margin: 0;
		padding: 4px 0;
		box-sizing: border-box;
	}

	.expanded {
		transform: rotate(0);
	}

	.expand-icon:hover,
	.small-button:hover {
		color: var(--db-40);
	}

	.sidebar-item-title-container.active .expand-icon,
	.sidebar-item-title-container.active .small-button {
		color: var(--db-20);
	}

	.sidebar-item-title-container.active .expand-icon:hover,
	.sidebar-item-title-container.active .small-button:hover {
		color: var(--white);
	}

	.sidebar-item-title-container:not(.active):hover .expand-icon:not(:hover),
	.item-hovered .expand-icon:not(:hover) {
		color: var(--g-60);
	}

	.children-container {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.sidebar-item-title-container:not(.active):hover .indent-line,
	.item-hovered .indent-line {
		border-left: 2px solid transparent;
	}

	.active {
		background: var(--db-40);
		color: var(--white);
		z-index: 1;
	}

	.active .indent-line {
		border-left: 2px solid transparent;
	}

	.no-pages {
		color: var(--g-60);
	}

	.indent-line {
		margin: -8px 16px 4px 1px;
		border-left: 2px solid var(--g-20);
	}

	.transparent-line {
		border-left: 2px solid transparent;
	}

	.indent-line-first {
		margin-top: 4px;
	}

	.bold-title {
		font-weight: 600;
	}

	body.disable-hovers .sidebar-item-title-container:not(.active):hover {
		background-color: transparent;
		z-index: 0;
	}

	body.disable-hovers .sidebar-item-title-container:hover .small-button {
		display: none;
	}

	body.disable-hovers .sidebar-item-title-container:not(.active):hover .indent-line {
		border-left: 2px solid var(--g-20);
	}

	body.disable-hovers .sidebar-item-title-container:not(.active):hover .expand-icon:not(:hover) {
		color: var(--db-50);
	}

	body.disable-hovers .active {
		background: transparent;
		color: var(--primary-color);
	}

	body.disable-hovers .active .indent-line {
		border-left: 2px solid var(--g-20);
	}

	@media only screen and (max-width: 480px) {
		.item-button {
			display: none;
		}
	}
</style>
