import firebase from 'firebase/compat/app'
import 'firebase/compat/auth';
import {GoogleFileDefs} from 'filedefs/FileDefs'

export default class Stat {
	static identifyUser(credentials: firebase.auth.UserCredential, hostedDomain?: string) {
		mixpanel.identify(credentials.user.email)
		if (credentials.additionalUserInfo.isNewUser) {
			const details: any = {}
			if (hostedDomain) {
				details.domain = hostedDomain
			}
			mixpanel.track('registering', {
				domain: hostedDomain
			})
		}
		mixpanel.people.set({
			$email: credentials.user.email,
			$name: credentials.user.displayName,
			sign_up_date: credentials.user.metadata.creationTime,
		})
	}

	static authError(error: string) {
		mixpanel.track('auth_error', {
			error_message: error
		})
	}

	static spaceFileError(errorCode: number, errorMessage: string) {
		mixpanel.track('space_file_error', {
			error_code: errorCode,
			error_message: errorMessage
		})
	}

	static spaceDashboardDataError(place: string, errorCode: number, errorMessage: string, hasFirebaseUser: boolean) {
		mixpanel.track('error_request_data_space_dashboard', {
			place: place,
			error_code: errorCode,
			error_message: errorMessage,
			has_firebase_user: hasFirebaseUser
		})
	}

	static insufficientPermissionsError() {
		mixpanel.track('insufficient_permissions_error')
	}

	static basicScopesGranted() {
		mixpanel.track('basic_scopes_granted')
	}

	static basicScopesRejected() {
		mixpanel.track('basic_scopes_rejected')
	}

	static openInDriveRequestAccess() {
		mixpanel.track('open_request_access')
	}

	static openSpace(user?: any) {
		if (user) {
			mixpanel.track('open_space')
		} else {
			mixpanel.track('open_space_viewer')
		}
	}

	static openDashboard() {
		mixpanel.track('open_dashboard')
	}

	static createSpace(currentNumber = 0, newFolder) {
		const space_type = newFolder ? 'new' : 'existing'
		mixpanel.track('create_space', {
			space_type: space_type,
			spaces_count: currentNumber + 1
		})
	}

	static removeSpace() {
		mixpanel.track('remove_space')
	}

	static createFile(type: string) {
		mixpanel.track('create_file', {
			file_type: type
		})
	}

	static moveFile() {
		mixpanel.track('move_file')
	}

	static copyFile() {
		mixpanel.track('copy_file')
	}

	static removeFile() {
		mixpanel.track('remove_file')
	}

	static openEditor(mimeType: string) {
		const fileDef = GoogleFileDefs.find(def => def.mimeType == mimeType)
		const type = fileDef ? fileDef.type : mimeType
		mixpanel.track('open_editor', {
			file_type: type
		})
	}

	static inviteUser(number = 1) {
		mixpanel.track('invite_user', {
			number_of_users: number
		})
	}

	static setSpaceVisibility(type: string) {
		mixpanel.track('set_space_visibility', {
			visibility_type: type
		})
	}

	//SURVEY

	static openSurvey() {
		mixpanel.track('open_first_survey')
	}

	static skipSurvey() {
		mixpanel.track('skip_first_survey')
	}

	//TOPICS

	static createTopic() {
		mixpanel.track('create_topic')
	}

	static deleteTopic() {
		mixpanel.track('delete_topic')
	}
}
