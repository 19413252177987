<template>
	<div class="file-menu">
		<div class="file-menu-item" @click="renameItem">
			<div class="file-menu-item-icon">
				<IconEdit/>
			</div>
			<div class="file-menu-item-text">Rename</div>
		</div>
		<div class="file-menu-item" @click="deleteItem">
			<div class="file-menu-item-icon">
				<IconFileMinus/>
			</div>
			<div class="file-menu-item-text">Delete</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconEdit from 'components/icons/icons/IconEdit.vue'
	import IconFileMinus from 'components/icons/icons/IconFileMinus.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IconLink from 'components/icons/icons/IconLink.vue'
	import {ITopic} from 'components/topics/TopicsService'

	@Component({
		components: {
			IconEdit,
			IconFileMinus,
			IconLink,
		}
	})

	export default class TopicMenu extends Vue {
		@Prop()
		topic: ITopic

		private renameItem() {
			EventBus.$emit(CustomEventNames.OPEN_RENAME_TOPIC, this.topic)
		}

		private deleteItem() {
			EventBus.$emit(CustomEventNames.OPEN_DELETE_TOPIC, this.topic)
		}
	}
</script>

<style scoped>

</style>
