<template>
	<div
			class="sidebar-container"
			@click="closeSidebarClickHandler"
			:class="{'container-opened-sidebar': sidebarOpened}"
			v-touch:swipe.left="closeSidebarClickHandler">

		<div class="dashboard-sidebar" :class="{'opened-sidebar': sidebarOpened}">
			<div class="sidebar-header-container">
				<h1 class="sidebar-header">{{ sidebarHeader }}</h1>
				<div class="edit-button circle-button-36" v-if="account && account.domain" @click="openDomainNameEditor">
					<IconEdit/>
				</div>
			</div>
			<div class="items-container" id="dashboardSidebarItemsScrollContainer">
				<div class="default-items">
					<div
							class="d-sidebar-item"
							@click="openFilter('all')"
							:class="{'active': dashboardFilter === 'all'}"
					>
						<IconDashboard class="d-sidebar-item-icon"/>
						<div class="d-sidebar-item-text">All spaces</div>
					</div>
					<div
							class="d-sidebar-item"
							@click="openFilter('starred')"
							:class="{'active': dashboardFilter === 'starred'}"
					>
						<IconStarDashboard class="d-sidebar-item-icon"/>
						<div class="d-sidebar-item-text">Starred spaces</div>
					</div>
				</div>

				<TopicsList v-if="isTeamMember"/>
			</div>
			<div class="sidebar-footer" v-if="isTeamMember">
				<div class="rounded-button-medium lb-sec-button" @click="inviteTeam">
					<IconPlus class="icon-20 margin-r-8"/>
					Invite your team
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import IconDashboard from 'components/icons/icons/IconDashboard.vue'
	import IconStarDashboard from 'components/icons/icons/IconStarDashboard.vue'
	import IconPlus from 'components/icons/icons/IconPlus.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import TopicsList from 'components/topics/TopicsList.vue'
	import {State} from 'vuex-class'
	import {TABLET_WIDTH} from 'utils/Constants'
	import {IAccount} from 'modules/account/AccountService'
	import IconEdit from 'components/icons/icons/IconEdit.vue'

	@Component({
		components: {
			TopicsList,
			IconDashboard,
			IconStarDashboard,
			IconPlus,
			IconEdit,
		}
	})

	export default class DashboardSidebar extends Vue {
		@State(state => state.dashboardFilter)
		dashboardFilter: string

		@State(state => state.googleUser)
		googleUser: gapi.auth2.GoogleUser

		@State(state => state.account)
		account: IAccount

		private sidebarOpened: boolean = true

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_DASHBOARD_SIDEBAR, this.openSidebar)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_DASHBOARD_SIDEBAR, this.openSidebar)
		}

		get isTeamMember(): boolean {
			return !!this.googleUser && !!this.googleUser.getHostedDomain()
		}

		get sidebarHeader(): string {
			if (!this.account) {
				return '...'
			}
			return this.account.accountName
		}

		private openFilter(filter: string) {
			EventBus.$emit(CustomEventNames.DASHBOARD_FILTER_SELECTED, filter)
		}

		private inviteTeam() {
			EventBus.$emit(CustomEventNames.OPEN_INVITE_TEAM)
		}

		closeSidebarClickHandler() {
			if (document.body.clientWidth <= TABLET_WIDTH) {
				this.sidebarOpened = false
			}
		}

		toggleSidebar() {
			this.sidebarOpened = !this.sidebarOpened
		}

		openSidebar(e?: MouseEvent) {
			if (e) {
				e.stopPropagation()
			}
			this.sidebarOpened = true
		}

		private openDomainNameEditor() {
			if (this.account && this.account.domain) {
				EventBus.$emit(CustomEventNames.OPEN_RENAME_TEAM, this.account.accountName)
			}
		}
	}
</script>

<style scoped>
	.sidebar-container {
		flex: none;
		width: 256px;
		height: 100%;
		position: relative;
		margin-left: -256px;
	}

	.container-opened-sidebar {
		margin-left: 0;
	}

	.dashboard-sidebar {
		flex: none;
		width: 100%;
		height: 100%;
		color: var(--white);
		display: flex;
		flex-direction: column;
		background-color: var(--ds-50);
		transition: all 0.25s;
		box-sizing: border-box;
	}

	.sidebar-header-container {
		padding-bottom: 24px;
		margin: 0 6px 0 16px;
		display: flex;
	}

	.sidebar-header {
		color: var(--white);
		flex-grow: 1;
	}

	.edit-button {
		color: var(--white);
		background-color: transparent;
		opacity: 0;
		flex: none;
		outline: none;
		width: 24px;
		height: 24px;
		border-radius: 20px;
		margin-top: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		user-select: none;
		box-sizing: border-box;
		transition: all 0.1s;
	}

	.edit-button svg {
		width: 20px;
		height: 20px;
	}

	.sidebar-header-container:hover .edit-button {
		opacity: 1;
	}

	.items-container {
		display: flex;
		flex-direction: column;
		position: relative;
		overflow-y: auto;
		padding-bottom: 24px;
		scrollbar-width: none;
	}

	.items-container::-webkit-scrollbar {
		width: 0;
	}

	.default-items {
		margin-bottom: 32px;
	}

	.d-sidebar-item {
		display: flex;
		line-height: 32px;
		align-items: center;
		color: var(--g-60);
		font-size: 16px;
		cursor: pointer;
		padding: 0 16px;
		border-radius: 6px;
		-webkit-tap-highlight-color: transparent;
	}

	.active {
		color: var(--lb-50);
		background-color: var(--ds-60);
	}

	.d-sidebar-item:not(.active):hover {
		background-color: var(--ds-60);
	}

	.d-sidebar-item-icon {
		width: 20px;
		height: 20px;
		margin-right: 8px;
	}

	.d-sidebar-item-text {

	}

	.sidebar-footer {
		border-top: 1px solid var(--ds-35);
		padding: 24px 0 32px 0;
		display: flex;
		margin: 0 16px;
	}

	.invite-button {
		border-radius: 36px;
		height: 40px;
		display: flex;
		align-items: center;
		padding: 0 16px 0 12px;
		font-size: 14px;
		line-height: 40px;
		font-weight: 600;
		box-sizing: border-box;
		border: 2px solid var(--lb-70);
		color: var(--lb-50);
		cursor: pointer;
	}

	@media only screen and (max-width: 768px) {
		.sidebar-container {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			pointer-events: none;
			z-index: 1;
			transition: all 0.25s;
			margin-left: 0;
		}

		.dashboard-sidebar {
			width: 90%;
			max-width: 320px;
			padding: 32px;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(-100%);
		}

		.opened-sidebar {
			transform: translateX(0) !important;
		}

		.container-opened-sidebar {
			transform: translateX(0);
			pointer-events: auto !important;
			background-color: rgba(33, 38, 42, 0.92);
		}
	}
</style>
