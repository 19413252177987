import OrderGenerator from 'utils/OrderGenerator'
import {SpaceliProperties} from 'enums/FileProps'
import {store} from 'store/Store'
import GoogleApiClient from 'modules/GoogleApiClient'
import {SUPPORTED_FILE_TYPES} from 'filedefs/SupportedFileTypes'
import DriveAPI from 'api/DriveAPI'
import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
import {EventBus} from 'EventBus'
import {CustomEventNames} from 'enums/CustomEventNames'
import NotificationService from 'modules/notifications/NotificationService'
import {CANT_COPY_FILE, CANT_MOVE_FILE, SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
import Stat from 'modules/stat/Stat'
import FileUtils from 'utils/FileUtils'
import {ISpaceFiles} from 'modules/space/SpaceService'

const moment = require('moment')

export default class Utils {
	static setDocumentTitle(title?: string) {
		let titleStr = 'Spaceli — Turn Google Docs into a knowledge base'
		if (title) {
			titleStr = 'Spaceli - ' + title
		}
		document.title = titleStr
	}

	static isItemChildOf(itemId: string, parentId: string): boolean {
		const parentFile = FileUtils.getFileById(parentId)
		const children = FileUtils.getChildrenForFolder(parentFile)
		return !!children.find(child => child.id === itemId)
	}

	static scrollIntoView(element: Element, parent: Element, duration = 100) {
		const bounds = element.getBoundingClientRect()
		const parentBounds = parent.getBoundingClientRect()
		let diff = 0
		if (bounds.top >= parentBounds.top && bounds.bottom <= parentBounds.bottom) {
			return
		} else if (bounds.bottom >= parentBounds.bottom) {
			diff = bounds.bottom - parentBounds.bottom
		} else if (bounds.top <= parentBounds.top) {
			diff = bounds.top - parentBounds.top
		}

		const perTick = (diff / duration) * 1000 / 60

		const animFunc = () => {
			parent.scrollTop = parent.scrollTop + perTick
			diff = Math.abs(diff) - Math.abs(perTick)
			if (diff > 0) {
				window.requestAnimationFrame(animFunc)
			}
		}
		window.requestAnimationFrame(animFunc)
	}

	static isElementInViewport(element: Element, parent: Element): {inViewport: boolean, scrollDirection?: 'top'|'down'} {
		const bounds = element.getBoundingClientRect()
		const parentBounds = parent.getBoundingClientRect()
		if (bounds.top >= parentBounds.top && bounds.bottom <= parentBounds.bottom) {
			return {inViewport: true}
		} else if (bounds.bottom >= parentBounds.bottom) {
			return {inViewport: false, scrollDirection: 'down'}
		} else if (bounds.top <= parentBounds.top) {
			return {inViewport: false, scrollDirection: 'top'}
		}
	}

	static getUserInitials(user): string {
		if (!user || (!user.displayName && !user.emailAddress)) {
			return ''
		}
		let name = ''

		if (user.displayName) {
			const parts = user.displayName.split(' ')
			if (parts[0]) {
				name += parts[0].charAt(0).toUpperCase()
			}
			if (parts[1]) {
				name += parts[1].charAt(0).toUpperCase()
			}
		} else if (user.emailAddress) {
			name += user.emailAddress.charAt(0).toUpperCase()
		}

		return name
	}

	static getItemIndex(file: gapi.client.drive.File) {
		return file.properties && file.properties[SpaceliProperties.SPACELI_PAGE_INDEX] ?
			OrderGenerator.fromRLE(file.properties[SpaceliProperties.SPACELI_PAGE_INDEX]) :
			OrderGenerator.getStringIndexFromTimestamp(moment(file.createdTime).unix())
	}

	static getSpaceUrl(spaceId: string) {
		return 'https://app.spaceli.io/space/' + spaceId
	}

	static getPageLinkInCurrentSpace(pageId: string) {
		return 'https://app.spaceli.io/space/' + store.state.currentSpaceId + '/page/' + pageId
	}

	static getFilePicker(includeFolders = true, callback) {
		const picker = GoogleApiClient.getInstance().buildPicker(SUPPORTED_FILE_TYPES.join(','), includeFolders)
		picker.setCallback(callback)
		picker.setVisible(true)
	}

	static importFileByMove(parentFile, callback) {
		Utils.getFilePicker(true, result => {
			if (result && result.action && result.action == 'picked' && result.docs && result.docs[0]) {
				callback(false)

				DriveAPI.getFile(result.docs[0].id).then((file: any) => {
					if (!file.error) {
						const c = file.capabilities
						if (c.canMoveItemIntoTeamDrive && c.canMoveItemOutOfDrive && c.canMoveItemWithinDrive) {
							const parent = parentFile.id
							const res: any = {
								properties: {
									[SpaceliProperties.SPACELI_PAGE_INDEX]: null
								},
								appProperties: {
									[SpaceliProperties.HIDDEN]: null
								}
							}
							DriveAPI.moveFile(file, parent, res).then(movedFile => {
								EventBus.$emit(CustomEventNames.FILE_CREATED, movedFile, false, movedFile.mimeType !== GoogleMimeTypes.FOLDER)
								if (movedFile.mimeType === GoogleMimeTypes.FOLDER) {
									EventBus.$emit(CustomEventNames.UPDATE_SPACE_FILES)
								}
								Stat.moveFile()
								callback(true)
							}, error => {
								NotificationService.getInstance().showNotification(error.message)
								callback(true)
							})
						} else {
							NotificationService.getInstance().showNotification(CANT_MOVE_FILE)
							callback(true)
						}
					} else {
						callback(true)
						NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
					}

				})
			}
		})
	}

	static importFileByCopy(parentFile, callback) {
		Utils.getFilePicker(false, result => {
			if (result && result.action && result.action == 'picked' && result.docs && result.docs[0]) {
				callback(false)

				DriveAPI.getFile(result.docs[0].id).then((file: any) => {
					if (!file.error) {
						const c = file.capabilities
						if (c.canCopy) {
							const res: any = {
								parents: [parentFile.id],
								properties: {
									[SpaceliProperties.SPACELI_PAGE_INDEX]: null,
								},
								appProperties: {
									[SpaceliProperties.HIDDEN]: null
								}
							}
							DriveAPI.copyFile(file, res).then(copiedFile => {
								EventBus.$emit(CustomEventNames.FILE_CREATED, copiedFile, false, copiedFile.mimeType !== GoogleMimeTypes.FOLDER)
								if (copiedFile.mimeType === GoogleMimeTypes.FOLDER) {
									EventBus.$emit(CustomEventNames.UPDATE_SPACE_FILES)
								}
								Stat.copyFile()
								callback(true)
							}, error => {
								NotificationService.getInstance().showNotification(error.message)
								callback(true)
							})
						} else {
							NotificationService.getInstance().showNotification(CANT_COPY_FILE)
							callback(true)
						}
					} else {
						callback(true)
						NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
					}
				})
			}
		})
	}

	static importAsShortcut(parentFile, callback) {
		Utils.getFilePicker(true, result => {
			if (result && result.action && result.action == 'picked' && result.docs && result.docs[0]) {
				callback(false)

				DriveAPI.getFile(result.docs[0].id).then((file: any) => {
					if (!file.error) {
						const data: any = {
							name: file.name,
							mimeType: 'application/vnd.google-apps.shortcut',
							parents: [parentFile.id],
							shortcutDetails: {
								targetId: file.id
							}
						}

						DriveAPI.createFileWithResource(data).then((shortcut: any) => {
							EventBus.$emit(CustomEventNames.FILE_CREATED, shortcut, false, shortcut.shortcutDetails.targetMimeType !== GoogleMimeTypes.FOLDER)
							if (shortcut.shortcutDetails.targetMimeType === GoogleMimeTypes.FOLDER) {
								EventBus.$emit(CustomEventNames.UPDATE_SPACE_FILES)
							}
							Stat.createFile('shortcut')
							callback(true)
						}, error => {
							NotificationService.getInstance().showNotification(error.message)
							callback(true)
						})
					} else {
						NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
						callback(true)
					}
				})
			}
		})
	}

	static getHtmlForOtherFile(fileId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			const token = gapi.auth.getToken()
			if (!token) {
				NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
				reject()
				return
			}
			const accessToken = token.access_token
			const url = 'https://docs.google.com/feeds/download/documents/export/Export?id=' + fileId + '&mimeType=text%2Fhtml&access_token=' + accessToken
			fetch(url).then(response => {
				if (response.ok) {
					response.text().then(txt => {
						resolve(txt)
					})
				} else {
					reject()
				}
			})
		})
	}
}
