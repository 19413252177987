import AppService from 'modules/app/AppService'
import {RouteName, RouteParams} from 'router/Router'
import {RouteAnimation} from 'utils/AnimationUtils'
import {store} from 'store/Store'

export default class RoutingUtils {
	static openDashboardFilter(filter: string) {
		const router = AppService.getInstance().appRouter
		router.push({name: RouteName.DASHBOARD, params: {[RouteParams.DASHBOARD_FILTER]: filter}})
	}

	static goToSpace(spaceId: string) {
		const router = AppService.getInstance().appRouter
		router.push({name: RouteName.SPACE, params: {[RouteParams.SPACE_ID]: spaceId}})
	}

	static openPage(pageId: string) {
		const router = AppService.getInstance().appRouter
		router.push({name: RouteName.PAGE, params: {[RouteParams.PAGE_ID]: pageId}})
	}

	static goToDashboard() {
		const router = AppService.getInstance().appRouter
		router.push({name: RouteName.DASHBOARD, params: {[RouteParams.DASHBOARD_FILTER]: 'all'}})
	}

	static saveRouteAndGoToLogin() {
		const route: string = AppService.getInstance().route.path
		store.commit('setRemainedRoute', route)
		RoutingUtils.goToLogin()
	}

	static goToLogin() {
		const router = AppService.getInstance().appRouter
		router.push({name: RouteName.LOGIN})
	}

	static goToApp() {
		const router = AppService.getInstance().appRouter
		router.push({name: RouteName.DASHBOARD})
	}

	static getAnimationNameForRoute(routeName: string): string {
		if (routeName === RouteName.LOGIN) {
			return ''
		}
		if (routeName === RouteName.DASHBOARD) {
			return RouteAnimation.SLIDE_LEFT
		}
		const anims = Object.keys(RouteAnimation).map(key => RouteAnimation[key])
		const index = Math.floor(Math.random() * anims.length)

		return anims[index]
	}
}
