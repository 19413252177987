<template>
	<div class="loading-screen">
		<LogoTransparent class="logo"/>
<!--		<LogoTextGradient/>-->
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import LogoTextGradient from 'components/icons/logo/LogoTextGradient.vue'
	import LogoTransparent from 'components/icons/logo/LogoTransparent.vue'

	@Component({
		components: {
			LogoTextGradient,
			LogoTransparent,
		},
	})

	export default class LoadingScreen extends Vue {}
</script>

<style scoped>
	.loading-screen {
		width: 100%;
		height: 100%;
		background-color: var(--ds-50);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		z-index: 500;
	}

	.logo {
		width: 44px;
		height: 44px;
		color: var(--lb-70);
	}
</style>
