<template>
	<div class="file-view">
		<div class="file-content-view base-file-view-padding">
			<div class="file-header-container" v-if="actionFiles.length">
				<h1 class="file-header">Tasks & Mentions</h1>
			</div>

			<div
				v-for="file in actionFiles"
				:key="file.id"
				class="file-container"
			>
				<div class="file-item">
					<h4 class="file-item-title">{{ file.name }}</h4>
					<div class="no-comments" v-if="!getCommentsForFile(file.id).length">
						<div class="primary-outline-button rounded-button-small" @click="openComment(file.id)" v-if="canOpenFile(file.id)">Open</div>
					</div>
				</div>
				<div class="comments-container">
					<div
							class="comment-container"
							v-for="(comment, index) in getCommentsForFile(file.id)"
							:key="comment.commentId + index"
					>
						<div class="comment-top-container">
							<UserAvatar class="comment-author-avatar" :user="comment.author"/>
							<div class="comment-author-name">{{ comment.author.displayName }}</div>
							<div class="primary-outline-button rounded-button-small" @click="openComment(file.id, comment.commentId)" v-if="canOpenFile(file.id)">Open</div>
						</div>
						<div class="comment-text" v-html="comment.htmlContent"></div>
						<div class="comment-footer-container">
							<div class="comment-data">{{ getCommentDate(comment.createdTime) }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="empty-doc-container" v-if="!actionFiles.length">
				<ImgEmpty class="img-empty"/>
				<div class="paragraph-text empty-text">Looks like you have no action items in this space.</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {ISpacePage} from 'components/space/pages/ISpacePage'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import ImgEmpty from 'components/icons/icons/ImgEmpty.vue'
	import TasksMentionsService, {IFilesCommentsMap} from 'modules/tasksmentions/TasksMentionsService'
	import {store} from 'store/Store'
	import FileUtils from 'utils/FileUtils'
	import UserAvatar from 'components/common/elements/UserAvatar.vue'
	import GoogleUtils from 'utils/GoogleUtils'
	import {isEditableByEditorFile} from 'filedefs/SupportedFileTypes'

	interface ISpaceActionItemsMap {
		[key: string]: {
			commentId: string
			author: gapi.client.drive.User
			htmlContent: string
			content: string
			createdTime: string
		}[]
	}

	const moment = require('moment')

	@Component({
		components: {
			UserAvatar,
			ImgEmpty
		}
	})

	export default class MentionsPage extends Vue implements ISpacePage {
		private tasksMentionsService: TasksMentionsService = TasksMentionsService.getInstance()

		private spaceActionItemsMap: ISpaceActionItemsMap = {}

		@Prop()
		file: gapi.client.drive.File

		@State(state => state.spaceLoadedPartially)
		spaceLoadedPartially: boolean

		@State(state => state.spaceLoadedFully)
		spaceLoadedFully: boolean

		get actionFiles(): gapi.client.drive.File[] {
			const files = []
			Object.keys(this.spaceActionItemsMap).forEach(key => {
				const file = FileUtils.getFileOrShortcutForFileById(key)
				if (file) {
					files.push(file)
				}
			})
			return files
		}

		getCommentsForFile(fileId: string) {
			return this.spaceActionItemsMap[fileId]
		}

		getCommentDate(date: string): string {
			return moment(date).fromNow()
		}

		update(): void {
			//
		}

		loaded(): void {
			if (this.spaceLoadedPartially || this.spaceLoadedFully) {
				EventBus.$emit(CustomEventNames.PAGE_LOADED)
			}
		}

		mounted() {
			if (this.tasksMentionsService.isInitForCurrentSpace) {
				this.getActionFilesCommentsMap()
			}
			this.tasksMentionsService.onGetActionItemFilesForSpace.add(this.getActionFilesCommentsMap, this)
		}

		canOpenFile(fileId: string) {
			const file = FileUtils.getFileOrShortcutForFileById(fileId)
			return isEditableByEditorFile(FileUtils.getFileMimeType(file))
		}

		private openComment(fileId, commentId?) {
			const file = FileUtils.getFileOrShortcutForFileById(fileId)
			let url = GoogleUtils.getEditUrl(file)
			if (commentId) {
				url += '&disco=' + commentId
			}
			EventBus.$emit(CustomEventNames.OPEN_FILE_EDITOR, file, url)
		}

		private getActionFilesCommentsMap() {
			this.tasksMentionsService.getActionFilesCommentsMap().then(result => {
				if (result) {
					this.processComments(result)
				}
				this.loaded()
			})
		}

		private processComments(filesCommentsMap: IFilesCommentsMap) {
			const userEmail = store.state.googleUser.getBasicProfile().getEmail()
			this.spaceActionItemsMap = {}

			const pushElement = (fileId, commentId, htmlContent, content, author, createdTime) => {
				this.spaceActionItemsMap[fileId].push({
					commentId: commentId,
					htmlContent: htmlContent,
					content: content,
					author: author,
					createdTime: createdTime,
				})
			}

			const isTextContainsEmail = (text: string) => {
				return text && (text.indexOf('+' + userEmail) !== -1 || text.indexOf('@' + userEmail) !== -1)
			}

			Object.keys(filesCommentsMap).forEach(fileId => {
				const file = FileUtils.getFileOrShortcutForFileById(fileId)
				Vue.set(this.spaceActionItemsMap, file.id, [])

				const comments = filesCommentsMap[fileId]
				comments.forEach(comment => {
					if (comment.resolved) {
						return
					}
					if (isTextContainsEmail(comment.content)) {
						pushElement(file.id, comment.id, comment.htmlContent, comment.content, comment.author, comment.createdTime)
					}
					comment.replies.forEach(reply => {
						if (isTextContainsEmail(reply.content)) {
							pushElement(file.id, comment.id, reply.htmlContent, reply.content, reply.author, reply.createdTime)
						}
					})
				})
			})
		}
	}
</script>

<style scoped>
	.file-content-view {
		align-items: center;
	}

	.file-header-container {
		width: 100%;
		max-width: 600px;
		margin-bottom: 24px;
	}

	.empty-doc-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
	}

	.img-empty {
		color: var(--g-60);
		margin-bottom: 24px;
	}

	.empty-text {
		max-width: 210px;
		text-align: center;
	}

	.file-container {
		border-bottom: 1px solid var(--g-20);
		padding: 20px 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 600px;
		flex: none;
	}

	.file-container:last-child {
		border-bottom: none;
	}

	.file-item {
		display: flex;
		align-items: center;
	}

	.file-item-title {
		flex-grow: 1;
	}

	.comments-container {
		display: flex;
		flex-direction: column;
	}

	.comment-container {
		background-color: var(--g-10);
		border-radius: 24px;
		box-sizing: border-box;
		padding: 16px 20px;
		margin-bottom: 16px;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 600px;
	}

	.comment-container:first-child {
		margin-top: 12px;
	}

	.comment-container:last-child {
		margin-bottom: 0;
	}

	.comment-top-container {
		display: flex;
		margin-bottom: 8px;
		align-items: center;
		margin-left: -2px;
	}

	.comment-author-avatar {
		margin-right: 6px;
	}

	.comment-author-name {
		font-weight: 600;
		flex-grow: 1;
		margin-right: 16px;
	}

	.comment-text {
		margin-bottom: 8px;
	}

	.comment-footer-container {
		display: flex;
	}

	.comment-data {
		color: var(--g-60);
		flex-grow: 1;
	}

	.no-comments {
		display: flex;
		align-items: center;
		margin-right: 20px;
	}

	@media only screen and (max-width: 480px) {
		.file-content-view {
			padding: 0 32px;
		}
	}
</style>
