<template>
	<a v-if="calcPermission(page, SpaceAction.EDIT_PAGE)" class="circle-button-32" :href="getViewInDriveUrl" v-tooltip="{content: 'Open in drive', placement: 'bottom'}" target="_blank">
		<IconDrive/>
	</a>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import GoogleUtils from 'utils/GoogleUtils'
	import IconDrive from 'components/icons/vendors/IconDrive.vue'
	import FileUtils from 'utils/FileUtils'

	@Component({
		components: {
			IconDrive,
		}
	})

	export default class ViewInDrive extends Vue {
		@Prop()
		page: gapi.client.drive.File

		get getViewInDriveUrl(): string {
			const originalFile = FileUtils.getFileOrShortcutForFileById(this.page.id)
			return GoogleUtils.getViewInDriveUrl(originalFile)
		}
	}
</script>

<style scoped>

</style>
