<template>
	<div class="page-footer" :class="{'top-line': showTopLine}">
		<div class="left-container"></div>

		<LastUpdated :file="file" v-if="showLastUpdate"/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {State} from 'vuex-class'
	import Utils from 'utils/Utils'
	import FileRow from 'components/common/elements/FileRow.vue'
	import RoutingUtils from 'utils/RoutingUtils'
	import LastUpdated from 'components/space/elements/LastUpdated.vue'

	@Component({
		components: {
			LastUpdated,
			FileRow
		}
	})

	export default class PageFooter extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@Prop({default: true})
		showTopLine: boolean

		@Prop({default: true})
		showLastUpdate: boolean
	}
</script>

<style scoped>
	.page-footer {
		display: flex;
		padding: 24px 0;
		position: relative;
		user-select: none;
		flex-shrink: 0;
	}

	.top-line {
		border-top: 1px solid var(--g-20);
	}

	.left-container {
		display: flex;
		flex-grow: 1;
	}

	.related-pages {
		display: flex;
		flex-direction: column;
	}

	.pages-header {
		margin-bottom: 12px;
	}

	.related-item {
		margin-left: -12px;
	}

	.related-item:hover {
		background-color: var(--g-20);
	}
</style>
