<template>
	<div class="tabs">
		<div
			class="tabs__item"
			v-for="tab in tabs"
			:ref="tab.value"
			:key="tab.title"
			:class="[{ 'tabs__item_active' : tab.value === currentTab }]"
			@click="handleClick(tab.value)"
			v-html="tab.title"
		/>

		<div
			class="tabs__active-block"
			:style="{ width: `${activeLineWidth}px`, transform: `translateX(${activeLineOffset}px)` }"
		/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

	@Component({ name: 'Tabs'})
	export default class Tabs extends Vue {
		@Prop({required: true})
		currentTab: string

		@Prop({required: true})
		tabs: any[]

		private activeLineWidth: number = 0
		private activeLineOffset: number = 0

		@Watch('currentTab')
		watchCurrentTab(val: string) {
			this.moveActiveLine(val)
		}

		mounted() {
			this.moveActiveLine(this.currentTab)
		}

		private handleClick(value) {
			this.$emit('tab-clicked', value)
			this.moveActiveLine(value)
		}

		private moveActiveLine(newValue) {
			if (!this.currentTab) {
				return
			}

			if (!this.$refs || !this.$refs[newValue]) {
				return
			}
			const element = this.$refs[newValue][0] as HTMLElement

			this.activeLineWidth = element.clientWidth - 8
			this.activeLineOffset = element.offsetLeft
		}
	}
</script>

<style lang="scss" scoped>
	.tabs {
		position: relative;
		display: flex;
		height: 36px;
		background-color: rgba(0,0,0,0.03);
		border-radius: 20px;
		color: #a5a5a5;
		overflow: hidden;

		&__item {
			display: flex;
			align-items: center;
			padding: 0 28px;
			height: 100%;
			font-size: 12px;
			font-weight: 600;
			text-decoration: none;
			border: none;
			cursor: pointer;
			transition: all 0.25s;
			z-index: 1;

			&_active {
				color: var(--db-45);
			}

			&:hover {
				color: var(--db-45);
			}

			&:focus {
				outline: none;
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&__active-block {
			position: absolute;
			top: 0;
			left: 0;
			height: 28px;
			border-radius: 16px;
			background: var(--white);
			transition: all 0.4s ease, width 0.4s ease;
			margin: 4px;
			box-shadow: 0 5px 20px 0 rgba(0,0,0,0.1);
		}
	}
</style>
