<template>
	<div
			v-if="calcPermission(page, SpaceAction.CAN_ADD_CHILD)"
			class="rounded-button-medium db-50-outline-button"
			@click="addNewPage">
		<IconPlus/>
		New page
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IconPlus from 'components/icons/icons/IconPlus.vue'

	@Component({
		components: {
			IconPlus
		}
	})

	export default class AddPageButton extends Vue {
		@Prop()
		page: gapi.client.drive.File

		private addNewPage() {
			EventBus.$emit(CustomEventNames.OPEN_CREATE_FILE, this.page)
		}
	}
</script>

<style scoped>
	.rounded-button-medium {
		padding-left: 12px;
	}

	.rounded-button-medium svg {
		width: 20px;
		height: 20px;
		margin-right: 4px;
	}
</style>
