enum Keyboard {
	BACKSPACE = 8, //backspace
	TAB = 9, //tab
	ENTER = 13, //enter
	SHIFT = 16, //shift
	CTRL = 17, //ctrl
	ALT = 18, //alt
	PAUSE_BREAK = 19, //pause, //break
	CAPS_LOCK = 20, //caps lock
	ESCAPE = 27, //escape
	PAGE_UP = 33, //page up, to avoid displaying alternate character and confusing people
	PAGE_DOWN = 34, //page down
	END = 35, //end
	HOME = 36, //home
	LEFT_ARROW = 37, //left arrow
	UP_ARROW = 38, //up arrow
	RIGHT_ARROW = 39, //right arrow
	DOWN_ARROW = 40, //down arrow
	INSERT = 45, //insert
	DELETE = 46, //delete
	LEFT_WINDOW = 91, //left window
	RIGHT_WINDOW = 92, //right window
	SELECT_KEY = 93, //select key
	NUMPAD_0 = 96, //numpad 0
	NUMPAD_1 = 97, //numpad 1
	NUMPAD_2 = 98, //numpad 2
	NUMPAD_3 = 99, //numpad 3
	NUMPAD_4 = 100, //numpad 4
	NUMPAD_5 = 101, //numpad 5
	NUMPAD_6 = 102, //numpad 6
	NUMPAD_7 = 103, //numpad 7
	NUMPAD_8 = 104, //numpad 8
	NUMPAD_9 = 105, //numpad 9

	NUM_LOCK = 144, //num lock
	SCROLL_LOCK = 145, //scroll lock
	COMMA = 188, // , *
	DASH = 189, // - *
	PERIOD = 190, // . *
	SINGLE_QUOTE = 222, // ' *

	SEMICOLON = 186, // ; *
	EQUAL = 187, // = *
	MINUS = 189, // - *
	SLASH = 191, // \/ *
	BACKQUOTE = 192, // ` *
	LEFTBRACKET = 219, // [ *
	BACKSLASH = 220, // \ *
	RIGHTBRACKET = 221, // ] *
	COMMAND = 15,
	SPACE = 32,
	COMMERCIAL_AT = 64, //@ *

	NUMBER_0 = 48,
	NUMBER_1 = 49,
	NUMBER_2 = 50,
	NUMBER_3 = 51,
	NUMBER_4 = 52,
	NUMBER_5 = 53,
	NUMBER_6 = 54,
	NUMBER_7 = 55,
	NUMBER_8 = 56,
	NUMBER_9 = 57,

	A = 65,
	B = 66,
	C = 67,
	D = 68,
	E = 69,
	F = 70,
	G = 71,
	H = 72,
	I = 73,
	J = 74,
	K = 75,
	L = 76,
	M = 77,
	N = 78,
	O = 79,
	P = 80,
	Q = 81,
	R = 82,
	S = 83,
	T = 84,
	U = 85,
	V = 86,
	W = 87,
	X = 88,
	Y = 89,
	Z = 90,

	F1 = 112,
	F2 = 113,
	F3 = 114,
	F4 = 115,
	F5 = 116,
	F6 = 117,
	F7 = 118,
	F8 = 119,
	F9 = 120,
	F10 = 121,
	F11 = 122,
	F12 = 123,
	F13 = 124,
	F14 = 125,
	F15 = 126,

	NUMPAD = 21,
	NUMPAD_ADD = 107,
	NUMPAD_DECIMAL = 110,
	NUMPAD_DIVIDE = 111,
	NUMPAD_ENTER = 108,
	NUMPAD_MULTIPLY = 106,
	NUMPAD_SUBTRACT = 109,

	PROCESSING_KEY = 229,

	EQUAL_FIREFOX = 61, // = *
	MINUS_FIREFOX = 173, // - *
}

export default Keyboard
