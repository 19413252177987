<template>
	<div class="action-items-container">
		<div
				class="action-item"
				@click="itemSelected('mentions')"
				v-if="calcPermission(file, SpaceAction.CAN_COMMENT)"
				:class="{'active': currentPageId == 'mentions'}"
		>
			<IconMention class="action-item-icon"/>
			<div class="action-item-text">Tasks & Mentions</div>
			<div class="action-item-badge" v-if="spaceActionFiles.length">{{ spaceActionFiles.length }}</div>
		</div>
<!--		<div-->
<!--				class="action-item"-->
<!--				@click="itemSelected('activity')"-->
<!--				v-if="calcPermission(file, SpaceAction.EDIT_PAGE)"-->
<!--				:class="{'active': currentPageId == 'activity'}"-->
<!--		>-->
<!--			<IconActivity class="action-item-icon"/>-->
<!--			<div class="action-item-text">Activity</div>-->
<!--		</div>-->
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconMention from 'components/icons/icons/IconMention.vue'
	import IconActivity from 'components/icons/icons/IconActivity.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import {IErrorsMap} from 'modules/space/SpaceService'

	@Component({
		components: {
			IconMention,
			IconActivity,
		}
	})

	export default class SpaceActions extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@State(state => state.currentPageId)
		currentPageId: string

		@State(state => state.spaceActionFiles)
		spaceActionFiles: gapi.client.drive.File[]

		private itemSelected(itemName: string) {
			EventBus.$emit(CustomEventNames.SIDEBAR_ITEM_SELECTED, itemName)
		}
	}
</script>

<style scoped>
	.action-items-container {
		/*padding-bottom: 20px;*/
	}

	.action-item {
		display: flex;
		align-items: center;
		padding: 4px 8px 4px 14px;
		margin: 0 20px;
		border-radius: 6px;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
	}

	.action-item:last-child {
		margin-bottom: 20px;
	}

	.action-item:not(.active):hover {
		background-color: var(--g-20);
		z-index: 1;
	}

	.active {
		background: var(--db-40);
		color: var(--white);
	}

	.action-item-icon {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}

	.action-item-text {
		flex-grow: 1;
	}

	.action-item-badge {
		flex: none;
		background-color: var(--red);
		color: var(--white);
		border-radius: 16px;
		padding: 0px 9px;
		font-size: 12px;
		line-height: 18px;
		font-weight: 600;
		box-sizing: border-box;
	}
</style>
