<template>
	<div class="dropdown-element">
		<div class="input-container" @click="toggleOptions">
			<div class="element-text">{{displayText}}</div>
			<div class="expand-icon">
				<IconChevron/>
			</div>
		</div>
		<div class="options-container" v-if="opened">
			<div class="scroll-container">
				<slot></slot>
			</div>
		</div>
	</div>

</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconChevron from 'components/icons/icons/IconChevron.vue'

	@Component({
		components: {
			IconChevron,
		}
	})

	export default class DropdownWhite extends Vue {
		@Prop({default: ''})
		displayText: string

		private opened: boolean = false

		toggleOptions(e: MouseEvent) {
			if (e) {
				e.stopPropagation()
			}
			this.opened = !this.opened
		}

		closeOptions() {
			this.opened = false
		}
	}
</script>

<style scoped>
	.dropdown-element {
		position: relative;
		width: 100%;
	}

	.input-container {
		width: 100%;
		height: 58px;
		position: relative;
		padding: 16px 20px;
		box-sizing: border-box;
		border-radius: 16px;
		border: 1px solid var(--g-20);
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.input-container:hover {
		border-color: var(--db-45);
	}

	.element-text {
		flex-grow: 1;
	}

	.expand-icon {
		display: flex;
	}

	.expand-icon svg {
		width: 20px;
		height: 20px;
		color: var(--db-50);
	}

	.options-container {
		position: absolute;
		top: 58px;
		left: 0;
		width: 100%;
		background-color: var(--white);
		box-shadow: 0 1px 10px 0 rgba(145, 145, 145, 0.3);
		border-radius: 16px;
		padding: 8px 4px;
		max-height: 240px;
		box-sizing: border-box;
		display: flex;
	}

	.scroll-container {
		width: 100%;
		overflow-y: auto;
		scrollbar-color: var(--g-30);
		scrollbar-width: thin;
	}

	.scroll-container::-webkit-scrollbar {
		width: 4px;
	}

	.scroll-container::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: var(--g-30);
		border: 0 solid transparent;
	}
</style>
