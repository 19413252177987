<template>
	<div class="space-page-view" v-touch:swipe.right="openSidebar">
		<SpaceHeader :file="page"/>
		<component
				v-if="showPageComponent"
				:ref="'pageComponent'"
				:is="component"
				@show-loading="showLoading"
				:file="page">
		</component>

		<ErrorPage :error-code="errorCode" :message="errorMessage"/>

    <RequestAccessPage :file-id="requestAccessFileId" v-if="requestAccessFileId"/>

		<Loading :is-loading="isLoading"/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import SpaceService from 'modules/space/SpaceService'
	import AppService from 'modules/app/AppService'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import DocumentPage from 'components/space/pages/DocumentPage.vue'
	import DefaultPage from 'components/space/pages/DefaultPage.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IframePage from 'components/space/pages/IframePage.vue'
	import SpreadsheetPage from 'components/space/pages/SpreadsheetPage.vue'
	import FormPage from 'components/space/pages/FormPage.vue'
	import SlidesPage from 'components/space/pages/SlidesPage.vue'
	import CircleLoader from 'components/common/loaders/CircleLoader.vue'
	import Loading from 'components/common/Loading.vue'
	import HomePage from 'components/space/pages/HomePage.vue'
	import ImagePage from 'components/space/pages/ImagePage.vue'
	import PreviewPage from 'components/space/pages/PreviewPage.vue'
	import {State} from 'vuex-class'
	import {isImageFile, isPreviewFile} from 'filedefs/SupportedFileTypes'
	import SpaceHeader from 'components/space/elements/SpaceHeader.vue'
	import ActivityPage from 'components/space/pages/actionpages/ActivityPage.vue'
	import MentionsPage from 'components/space/pages/actionpages/MentionsPage.vue'
	import {ActionPages, getActionPageFileData, isActionPage} from 'components/space/pages/actionpages/ActionPages'
	import ErrorPage from 'components/space/elements/ErrorPage.vue'
  import Stat from "../../modules/stat/Stat";
  import {ErrorsCodes} from "../../utils/ErrorHandlingUtils";
  import RequestAccessPage from "./elements/RequestAccessPage.vue";

	@Component({
		components: {
			ErrorPage,
			MentionsPage,
			ActivityPage,
			SpaceHeader,
			HomePage,
			Loading,
			CircleLoader,
			DefaultPage,
			DocumentPage,
			SpreadsheetPage,
			FormPage,
			SlidesPage,
			IframePage,
			ImagePage,
			PreviewPage,
      RequestAccessPage,
		}
	})
	export default class SpacePage extends Vue {
		@Prop()
		pageId: string

		@State
		spaceFiles: gapi.client.drive.File[]

		@State
		currentSpaceId: string

		private appProcessor: AppService = AppService.getInstance()
		private spaceProcessor: SpaceService = SpaceService.getInstance()

		private isLoading: boolean = false

		errorCode: ErrorsCodes | null = null
    errorMessage: string | null = null

    requestAccessFileId: string | null = null

		page: gapi.client.drive.File = {}

		get targetPageId(): string {
			if (!this.page) {
				return this.pageId
			} else {
				return this.page.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT ? this.page.shortcutDetails.targetId : this.pageId
			}
		}

		get showPageComponent(): boolean {
			return !!this.page.mimeType || isActionPage(this.pageId)
		}

		@Watch('pageId')
		watchPageId(val: string, oldVal: string) {
			if (val !== oldVal) {
				this.onPageIdChanged()
			}
		}

		mounted() {
			if (this.pageId) {
				this.onPageIdChanged()
			}
			EventBus.$on(CustomEventNames.FILE_EDITOR_CLOSED, this.update)
			EventBus.$on(CustomEventNames.PAGE_LOADED, this.onPageLoaded)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.FILE_EDITOR_CLOSED, this.update)
			EventBus.$off(CustomEventNames.PAGE_LOADED, this.onPageLoaded)
		}

		onPageIdChanged() {
			this.isLoading = true
			this.errorCode = null
      this.errorMessage = null
      this.requestAccessFileId = null
			this.page = {}
			this.appProcessor.isInit ? this.getPageData() : this.appProcessor.appInitialized.addOnce(this.getPageData, this)
		}

		update() {
			const pageId = this.targetPageId
			if (!isActionPage(pageId)) {
				this.isLoading = true
				this.errorCode = null
        this.errorMessage = null
        this.requestAccessFileId = null
				this.fetchPageDataFromServer(this.targetPageId)
				const component = this.$refs.pageComponent as any
				component.update()
			}
		}

		private onPageLoaded() {
			this.isLoading = false
		}

		private showLoading() {
			this.isLoading = true
		}

		private getPageData() {
			if (isActionPage(this.pageId)) {
				this.page = getActionPageFileData(this.pageId)
				return
			}

			const file = this.spaceFiles[this.pageId]
			if (file) {
				file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT ? this.fetchPageDataFromServer(file.shortcutDetails.targetId, file) : this.page = file
			} else {
				this.fetchPageDataFromServer(this.pageId)
			}
		}

		private fetchPageDataFromServer(pageId: string, shortcut?: gapi.client.drive.File) { //title because of shortcuts
			this.spaceProcessor.getFileData(pageId).then(file => {
				if (file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT) {
					this.fetchPageDataFromServer(file.shortcutDetails.targetId, file)
				} else {
					if (shortcut) {
						file.name = shortcut.name
						file.shortcutDetails = shortcut.shortcutDetails
						if (!file.properties) {
							file.properties = {}
						}
						file.properties.shortcutId = file.id
					}
					this.page = file
				}
			}, error => {
        if (error.code === ErrorsCodes.NOT_FOUND) {
          this.requestAccessFileId = pageId
        } else {
          this.errorCode = error.code
          this.errorMessage = error.message ?? null
          Stat.spaceFileError(this.errorCode, error.message ?? '')
        }
        this.isLoading = false
			})
		}

		get component() {
			if (isActionPage(this.pageId)) {
				return this.getActionPage(this.pageId)
			}
			return this.getPageType(this.page.mimeType)
		}

		getActionPage(page: string) {
			if (page === ActionPages.ACTIVITY) {
				return 'ActivityPage'
			} else if (page === ActionPages.MENTIONS) {
				return 'MentionsPage'
			}
		}

		getPageType(mimeType: string) {
			if (!mimeType) {
				return 'DefaultPage'
			}

			if (mimeType === GoogleMimeTypes.DOCS || mimeType === GoogleMimeTypes.DOCS_OLD) {
				return 'DocumentPage'
			} else if (
				mimeType === GoogleMimeTypes.SHEETS ||
				mimeType === GoogleMimeTypes.EXCEL_XLSX ||
				mimeType === GoogleMimeTypes.EXCEL_XLS
			) {
				return 'SpreadsheetPage'
			} else if (mimeType === GoogleMimeTypes.SLIDES) {
				return 'SlidesPage'
			} else if (mimeType === GoogleMimeTypes.FORMS) {
				return 'FormPage'
			} else if (mimeType === GoogleMimeTypes.FOLDER) {
				return 'HomePage'
			} else if (mimeType === GoogleMimeTypes.SPACELI_SHORTCUT) {
				return 'IframePage'
			} else if (isImageFile(mimeType)) {
				return 'ImagePage'
			} else if (isPreviewFile(mimeType)) {
				return 'PreviewPage'
			} else {
				return 'DefaultPage'
			}
		}

		openSidebar() {
			EventBus.$emit(CustomEventNames.OPEN_SIDEBAR)
		}
	}
</script>

<style scoped>
	.space-page-view {
		width: 100%;
		height: 100%;
		overflow: hidden;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		box-shadow: 0 1px 30px 0 rgba(145, 145, 145, 0.3);
		border-left: 1px solid var(--g-20);
		position: relative;
		z-index: 2;
	}

	@media only screen and (max-width: 768px) {
		.space-page-view {
			z-index: auto;
			box-shadow: none;
		}
	}
</style>
