import DriveAPI from 'api/DriveAPI'

export default class FileActions {
	static toggleStarFile(file: gapi.client.drive.File) {
		const currentState = !!file.starred
		const res = {
			starred: !currentState
		}
		DriveAPI.updateFileProps(file.id, res)
	}
}
