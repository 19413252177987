import Vue from 'vue'
import {SpaceAction} from 'modules/permissions/PermissionHelpers'
import PermissionService from 'modules/permissions/PermissionService'

Vue.mixin({
	data: () => {
		return {
			SpaceAction: SpaceAction
		}
	},
	methods: {
		calcPermission(file: gapi.client.drive.File, action: SpaceAction): boolean {
			return PermissionService.getInstance().calcPermission(file, action)
		}
	}
})
