<template>
	<BaseModal ref="modal" @closed="onClosed">
		<div class="modal-content-container">
			<h2 class="modal-header">Important: Spaceli 1.0 will be discontinued</h2>

<!--			<div class="mb-2">Hey there,</div>-->
<!--			<div class="mb-2">Eric from Spaceli here.</div>-->
			<div class="mb-2">Due to recent changes in Google’s API, we’re no longer able to support Spaceli 1.0, and it will be discontinued on<strong> December 1st.</strong></div>
			<div class="mb-2"><strong>But don’t worry — we’ve built Spaceli 2.0! </strong>It includes everything you loved about Spaceli 1.0, plus new features like video support, dark mode, and more. Spaceli 2.0 is faster, gives you more control over file access, and is actively being improved with even more updates coming soon.</div>
			<div class="mb-2">Transitioning to Spaceli 2.0 is simple—log in, create your spaces using existing folders, and share them with your team.</div>
			<div class="mb-2">If you have any questions, reach out to us in the chat—we’re happy to help!</div>

			<a href="https://spaceli.io/spaceli-2" target="_blank">Learn more about the changes in Spaceli 2.0</a>

			<div class="modal-buttons-container">
				<div class="rounded-button-medium db-50-button button-margin-4" @click="open2">Open Spaceli 2</div>
				<div class="rounded-button-medium db-50-text-button cancel-button" @click="close">Try Later</div>
			</div>
		</div>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import Loading from 'components/common/Loading.vue'
	import TextareaWhite from 'components/common/form/TextareaWhite.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'

	const SPACELI_2_ITEM = 'spaceli_2_message'

	@Component({
		components: {TextareaWhite, Loading, BaseModal}
	})

	export default class Spaceli2Modal extends Vue {
		mounted() {
			EventBus.$on(CustomEventNames.OPEN_MEET_SPACELI_2, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_MEET_SPACELI_2, this.open)
		}

		open() {
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		close() {
			localStorage.setItem(SPACELI_2_ITEM, 'true')
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		open2() {
			window.open('https://app2.spaceli.io', '_blank')
			this.close()
		}

		onClosed() {
			//
		}
	}
</script>

<style scoped>
	.rename-margin {
		margin-top: 32px;
	}
</style>
