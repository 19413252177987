<template>
	<div class="file-view">
		<div class="file-content-view frame-file-container-padding">

			<div>
				<img
						class="image-frame"
						referrerPolicy="no-referrer"
						ref="contentFrame"
						@load="loaded"
						@error="loaded"
						:src="imgUrl">
			</div>

			<PageFooter :file="file" v-if="file.lastModifyingUser" :show-top-line="false"/>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {ISpacePage} from 'components/space/pages/ISpacePage'
	import PageFooter from 'components/space/elements/PageFooter.vue'
	import EditByEditor from 'components/space/buttons/EditByEditor.vue'
	import ViewInDrive from 'components/space/buttons/ViewInDrive.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'

	@Component({
		components: {
			PageFooter,
			EditByEditor,
			ViewInDrive,
		}
	})

	export default class ImagePage extends Vue implements ISpacePage {
		@Prop()
		file: gapi.client.drive.File

		update(): void {
			const frame = this.$refs.contentFrame as any
			frame.src += ''
		}

		loaded(): void {
			EventBus.$emit(CustomEventNames.PAGE_LOADED)
		}

		get imgUrl(): string {
			if (this.file.mimeType === 'image/svg+xml') {
				const url = this.file.webContentLink.replace("=download", "=preview")
				const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
				if (isSafari) {
					return this.file.thumbnailLink ? this.file.thumbnailLink.replace(/=s\d+$/, '=s1080') : ''
				} else {
					return url
				}
			}
			let elementSize
			if (this.file.imageMediaMetadata && this.file.imageMediaMetadata.width) {
				elementSize = this.file.imageMediaMetadata.width
			} else {
				if (!this.$el) {
					elementSize = 1080
				} else {
					const bounds = this.$el.getBoundingClientRect()
					elementSize = bounds.width - 176
				}
			}
			return this.file.thumbnailLink.replace(/=s\d+$/, '=s' + elementSize)
		}
	}
</script>

<style scoped>
	.file-content-view {
		padding: 0 72px;
	}

	.image-frame {
		flex: none;
		box-sizing: border-box;
		border-radius: 4px;
	}

	img {
		max-width: 100%;
	}

	@media only screen and (max-width: 480px) {
		.file-content-view {
			padding: 0 32px;
		}
	}
</style>
