<template>
	<transition name="fade" @enter="onEnter">
		<div class="modal-overlay" v-if="opened" @click="closeModal">
			<div class="modal" v-if="opened" @mousedown.stop @click.stop :class="{'modal-fixed-position': fixedPosition}" :style="{width: width + 'px'}">
				<slot></slot>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'

	@Component({})

	export default class BaseModal extends Vue {
		@Prop({default: false})
		fixedPosition: boolean

		@Prop({default: 604})
		width: boolean

		private opened: boolean = false

		mounted() {
			EventBus.$on(CustomEventNames.ESCAPE_PRESSED, this.closeModal)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.ESCAPE_PRESSED, this.closeModal)
		}

		openModal() {
			this.opened = true
		}

		closeModal() {
			this.opened = false
			this.$emit('closed')
		}

		private onEnter() {
			this.$emit('ready')
		}
	}
</script>

<style scoped>
	.modal-overlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: var(--dialog-modal-z-index);
		display: flex;
		position: fixed;
		align-items: center;
		transition: all 0.2s;
		justify-content: center;
		background-color: rgba(33, 38, 42, 0.92);
		color: rgba(25, 27, 49, 0.98);
	}

	.modal {
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		background-color: var(--white);
		box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.2);
		box-sizing: border-box;
		position: relative;
		max-width: 100%;
		/*overflow: hidden;*/
	}

	.modal-fixed-position {
		position: absolute;
		top: 20%;
	}
</style>
