import firebase from 'firebase/compat/app'
import AuthService from 'modules/app/AuthService'
import {GOOGLE_CONFIG} from 'modules/GoogleConfig'
import GoogleApiClient from 'modules/GoogleApiClient'
import DashboardService from 'modules/dashboard/DashboardService'
import {Vue} from 'vue/types/vue'
import {VueRouter} from 'vue-router/types/router'
import {Route} from 'vue-router'
import KeyboardService from 'modules/app/KeyboardService'
import SurveyService from 'modules/onboarding/SurveyService'
import AccountService from 'modules/account/AccountService'

export default class AppService {
	private static _instance: AppService
	private vueApp: Vue

	authService: AuthService
	dashboardProcessor: DashboardService
	keyboardService: KeyboardService
	accountService: AccountService

	//onboarding
	surveyService: SurveyService

	isInit: boolean = false
	appInitialized = new signals.Signal<() => void>()
	gapiInitialized = new signals.Signal<() => void>()

	static getInstance(): AppService {
		return AppService._instance
	}

	static createAppProcessor(vueApp: Vue) {
		firebase.initializeApp(GOOGLE_CONFIG)
		AppService._instance = new AppService(vueApp)
		return AppService._instance
	}

	constructor(vueApp: Vue) {
		this.vueApp = vueApp
		GoogleApiClient.getInstance().getGapiClient().then(() => {
			this.gapiInitialized.dispatch()
			this.createModules()
		})
	}

	get appRouter(): VueRouter {
		return this.vueApp.$router
	}

	get route(): Route {
		return this.vueApp.$route
	}

	private createModules() {
		this.authService = AuthService.getInstance()
		this.authService.authStateChanged.add(this.onAuthStateChanged, this)
		this.dashboardProcessor = DashboardService.getInstance()
		this.keyboardService = KeyboardService.getInstance()
		this.surveyService = SurveyService.getInstance()
		this.accountService = AccountService.getInstance()
	}

	private onAuthStateChanged(isSignedIn, isFirstCheck: boolean) {
		if (isFirstCheck) {
			this.isInit = true
			this.appInitialized.dispatch(isSignedIn)
			console.timeEnd('Loading')
		}
	}
}
