<template>
	<transition name="full-screen-slide-bottom">
		<div class="create-space-view" v-if="isOpened">
			<transition name="fade-slide-element" @enter="onEnter">
				<div class="create-space-slide" v-if="currentTab === 'select'" key="select">
					<h2 class="create-space-title">Create a new space</h2>

					<div class="space-select-elements-container">
						<div class="space-select-element" @click="selectTab('new')">
							<div class="space-select-element-title">Create a new folder</div>
							<div class="space-select-element-desc">Create a new folder to start a space from scratch</div>
						</div>

						<div class="space-select-element-separator"></div>

						<div class="space-select-element" @click="selectTab('existing')">
							<div class="space-select-element-title">Use an existing folder</div>
							<div class="space-select-element-desc">Select a folder with your existing files</div>
						</div>
					</div>
				</div>

				<div class="create-space-slide" v-if="currentTab === 'new'" key="new">
					<h2 class="create-space-title">Create a new folder</h2>

					<div class="content-container">
						<InputWhite :field-title="'Name'" v-model="newSpaceTitle" ref="titleInput"/>

						<h4 class="drives-header" v-if="drives.length > 1">Choose a Drive</h4>

						<div class="drives-container" v-if="drives.length > 1">
							<div
								class="drive-element"
								:class="{'selected-drive': selectedDrive === drive.id}"
								v-for="drive in drives"
								@click="selectDrive(drive)"
								:key="drive.id">
								<div class="drive-element-title">{{ drive.name }}</div>
							</div>
						</div>

						<div class="modal-buttons-container">
							<div
									class="rounded-button-medium db-50-button button-margin-4"
									:class="{'disabled-light-theme-button': !isButtonEnabled}"
									@click="createNewSpace">
								Create
							</div>
							<div class="rounded-button-medium db-50-text-button" @click="close">Cancel</div>
						</div>
					</div>
				</div>

				<div class="create-space-slide" v-else-if="currentTab === 'existing'" key="existing">
					<h2 class="create-space-title">Select a folder</h2>

					<div class="content-container">
						<div class="rounded-button-medium db-50-outline-button select-button" @click="clickSelectFolder" v-if="!selectedFolder.id && !isLoading">
							<IconDrive/>
							Select a folder
						</div>

						<div class="select-button selected-folder" @click="clickSelectFolder" v-if="selectedFolder.id && !isLoading">
							<IconFolder class="selected-folder-icon"/>
							<div class="selected-folder-text-container">
								<div class="selected-folder-title">{{ selectedFolder.name }}</div>
								<div class="selected-folder-updated">Last updated {{ date }}</div>
							</div>
						</div>

						<div class="select-button loading-button" v-if="isLoading">
							<CircleLoader width="4"/>
						</div>

						<div class="modal-buttons-container">
							<div
									class="rounded-button-medium db-50-button button-margin-4"
									:class="{'disabled-light-theme-button': !isButtonEnabled}"
									@click="createNewSpace">
								Create
							</div>
							<div class="rounded-button-medium db-50-text-button" @click="close">Cancel</div>
						</div>
					</div>
				</div>
			</transition>

			<div class="circle-button g20-button back-button" @click="back" v-if="currentTab !== 'select'">
				<IconArrowLeft/>
			</div>

			<div class="circle-button g20-button close-button" @click="close">
				<IconCross/>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import DashboardService from 'modules/dashboard/DashboardService'
	import RoutingUtils from 'utils/RoutingUtils'
	import Tabs from 'components/external/Tabs.vue'
	import IconArrowLeft from 'components/icons/icons/IconArrowLeft.vue'
	import IconFolder from 'components/icons/icons/IconFolder.vue'
	import InputWhite from 'components/common/form/InputWhite.vue'
	import IconDrive from 'components/icons/vendors/IconDrive.vue'
	import NotificationService from 'modules/notifications/NotificationService'
	import {CANT_CREATE_SPACE, CANT_CREATE_SPACE_SHORTCUT, SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
	import DriveAPI from 'api/DriveAPI'
	import CircleLoader from 'components/common/loaders/CircleLoader.vue'
	import Stat from 'modules/stat/Stat'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IconCross from 'components/icons/icons/IconCross.vue'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'

	interface IDriveCard {
		name: string
		id: string
	}

	const moment = require('moment')

	@Component({
		components: {
			CircleLoader,
			InputWhite,
			Tabs,
			IconArrowLeft,
			IconDrive,
			IconFolder,
			IconCross,
		}
	})

	export default class CreateSpace extends Vue {
		private isOpened: boolean = false
		private isLoading: boolean = false
		private dashboardService: DashboardService = DashboardService.getInstance()
		currentTab: string = 'select'
		newSpaceTitle: string = ''
		selectedFolder: any = {}

		private drives: IDriveCard[] = []
		private selectedDrive = ''

		private topic: string

		open(topic?: string) {
			this.topic = topic
			this.currentTab = 'select'
			this.newSpaceTitle = ''
			this.selectedFolder = {}
			this.focusTitleInput()
			this.isOpened = true
			this.getDrives()
		}

		close() {
			this.topic = undefined
			this.isOpened = false
			this.currentTab = 'select'
			this.newSpaceTitle = ''
			this.selectedFolder = {}
			this.drives = []
			this.selectedDrive = ''
		}

		private onEsc() {
			if (!this.isOpened) {
				return
			}
			this.close()
		}

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_CREATE_SPACE, this.open)
			EventBus.$on(CustomEventNames.ESCAPE_PRESSED, this.onEsc)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_CREATE_SPACE, this.open)
			EventBus.$off(CustomEventNames.ESCAPE_PRESSED, this.onEsc)
		}

		private getDrives() {
			DriveAPI.getDrives().then(drives => {
				this.populateDrives(drives)
			}, error => {
				this.populateDrives([])
			})
		}

		private populateDrives(drives: gapi.client.drive.Drive[]) {
			this.drives = []
			this.drives.push({name: 'My Drive', id: ''})
			drives.forEach(d => {
				this.drives.push({name: d.name, id: d.id})
			})
		}

		private selectDrive(drive: IDriveCard) {
			this.selectedDrive = drive.id
		}

		get isButtonEnabled() {
			if (this.currentTab === 'new') {
				return this.newSpaceTitle.length
			} else {
				return this.selectedFolder.id
			}
		}

		get date() {
			return this.selectedFolder && this.selectedFolder.modifiedTime ? moment(this.selectedFolder.modifiedTime).fromNow() : ''
		}

		private focusTitleInput() {
			setTimeout(() => {
				const input = this.$refs.titleInput as any
				if (input) {
					input.focusInput()
				}
			}, 500)
		}

		private createNewSpace() {
			if (!this.isButtonEnabled) {
				return
			}
			const currentSpacesLength = this.$store.state.spaces.length

			if (this.currentTab === 'new') {
				this.dashboardService.createNewSpace(this.newSpaceTitle, this.topic, this.selectedDrive).then(id => {
					Stat.createSpace(currentSpacesLength, true)
					RoutingUtils.goToSpace(id)
				}).catch(() => {
					NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG, true)
				})
			} else if (this.currentTab === 'existing') {
				this.dashboardService.markFolderAsRoot(this.selectedFolder.id, this.topic).then(id => {
					Stat.createSpace(currentSpacesLength, false)
					RoutingUtils.goToSpace(id)
				}).catch(() => {
					NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG, true)
				})
			}
		}

		private clickSelectFolder() {
			this.dashboardService.showFolderPicker(result => {
				if (result && result.action && result.action == 'picked' && result.docs && result.docs[0]) {
					if (result && result.action && result.action == 'picked' && result.docs && result.docs[0]) {
						this.isLoading = true
						DriveAPI.getFile(result.docs[0].id).then((file: any) => {
							if (!file.error) {
								if (file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT) {
									NotificationService.getInstance().showNotification(CANT_CREATE_SPACE_SHORTCUT)
								} else {
									if (file.capabilities.canEdit && file.capabilities.canRename) {
										this.selectedFolder = file
									} else {
										NotificationService.getInstance().showNotification(CANT_CREATE_SPACE)
									}
								}
								this.isLoading = false
							} else {
								NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
								this.isLoading = false
							}

						})
					}
				}
			})
		}

		private back() {
			if (this.currentTab === 'new' || this.currentTab === 'existing') {
				this.currentTab = 'select'
			} else {
				this.close()
			}
		}

		private selectTab(newTab: string) {
			this.currentTab = newTab
		}

		private onEnter() {
			if (this.currentTab === 'new') {
				this.focusTitleInput()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.create-space-view {
		width: 100%;
		height: 100%;
		background-color: var(--white);
		user-select: none;
		position: absolute;
		top: 0;
		left: 0;
		z-index: var(--modal-z-index);
	}

	.create-space-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
	}

	.create-space-title {
		margin-top: 120px;
		margin-bottom: 48px;
	}

	.create-space-slide {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.content-container {
		width: 350px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 48px;
	}

	.select-button {
		width: 100%;
		justify-content: center;
		border-radius: 8px;
		height: 58px;
	}

	.loading-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.selected-folder {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		border: 1px solid var(--g-20);
		padding: 8px 20px;
	}

	.selected-folder-icon {
		margin-right: 12px;
	}

	.selected-folder-title {
		font-weight: 600;
		font-size: 14px;
		color: var(--primary-color);
	}

	.selected-folder-updated {
		color: var(--g-60);
	}

	.space-select-elements-container {
		display: flex;
	}

	.space-select-element {
		background-color: var(--g-10);
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding: 12px 20px;
		border: 2px solid transparent;
		transition: border-color 0.1s;
		cursor: pointer;
		width: 240px;
		-webkit-tap-highlight-color: transparent;
	}

	.space-select-element:hover {
		border-color: var(--db-50);
	}

	.space-select-element-title {
		font-weight: 600;
		color: var(--primary-color);
	}

	.space-select-element-desc {
		color: var(--g-60);
	}

	.space-select-element-separator {
		margin: 0 12px;
	}

	.close-button {
		position: absolute;
		top: 36px;
		right: 36px;
	}

	.drives-header {
		margin: 32px 0 16px 0;
	}

	.drives-container {
		margin: 0px;
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(3, 1fr);
		position: relative;
	}

	.drive-element {
		background-color: var(--g-10);
		border-radius: 16px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 10px 14px;
		border: 2px solid transparent;
		transition: border-color 0.1s;
		cursor: pointer;
	}

	.drive-element:not(.selected-drive):hover {
		background-color: var(--g-20);
	}

	.drive-element-title {
		font-weight: 600;
		color: var(--primary-color);
	}

	.selected-drive {
		border-color: var(--db-50);
		/*background-color: var(--db-40);*/
	}
</style>
