import AppService from 'modules/app/AppService'
import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
import DriveAPI from 'api/DriveAPI'
import {SpaceHelpers} from 'modules/space/SpaceHelpers'
import {store} from 'store/Store'
import {SpaceliProperties} from 'enums/FileProps'
import OrderGenerator from 'utils/OrderGenerator'
import NotificationService from 'modules/notifications/NotificationService'
import {SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
import cloneDeep from 'lodash/cloneDeep'
import FileUtils from 'utils/FileUtils'
import TasksMentionsService from 'modules/tasksmentions/TasksMentionsService'
import {ErrorsCodes} from "../../utils/ErrorHandlingUtils";

export interface IChildrenMap {
	[key: string]: string[]
}

export interface IErrorsMap {
	[key: string]: any
}

export interface ISpaceFiles {
	[key: string]: gapi.client.drive.File
}

export default class SpaceService {
	private static _instance: SpaceService
	private appService: AppService
	private tasksMentionsService: TasksMentionsService = TasksMentionsService.getInstance()

	private space: gapi.client.drive.File
	private spaceFiles: ISpaceFiles = {}

	private childrenMap: IChildrenMap = {}
	private errorsMap: IErrorsMap = {}

	static getInstance(): SpaceService {
		if (!SpaceService._instance) {
			SpaceService._instance = new SpaceService()
		}
		return SpaceService._instance
	}

	constructor() {
		this.appService = AppService.getInstance()
	}

	//can be called on partial load (2 levels of files)
	private completeLoadingSpace(fullLoad: boolean) {
		this.setSpaceFilesState()
		this.setChildrenMapState(this.childrenMap)
		this.setErrorsMap()

		fullLoad ? this.setSpaceLoadedFullyState() : this.setSpaceLoadedPartiallyState()
	}

	private dropState() {
		this.space = {}
		this.spaceFiles = {}
		this.childrenMap = {}
		this.errorsMap = {}
		this.tasksMentionsService.dropTasksState()
		this.setSpaceObjectState()
		this.setSpaceFilesState()
		this.dropChildrenMapState()
	}

	private setSpaceObjectState() {
		store.commit('setSpace', this.space)
	}

	private setSpaceFilesState() {
		store.commit('setSpaceFiles', this.spaceFiles)
	}

	private setChildrenMapState(map: IChildrenMap) {
		store.commit('setChildrenMap', map)
	}

	private dropChildrenMapState() {
		store.commit('dropChildrenMap')
	}

	private setErrorsMap() {
		store.commit('setErrorsMap', this.errorsMap)
	}

	private dropSpaceLoadedState() {
		store.commit('dropSpaceLoadedState')
	}
	private setSpaceLoadedPartiallyState() {
		store.commit('setSpaceLoadedPartially')
	}
	private setSpaceLoadedFullyState() {
		store.commit('setSpaceLoadedFully')
	}

	getSpaceData(spaceId: string): Promise<gapi.client.drive.File> {
		return new Promise((resolve, reject) => {
			this.dropState()
			this.dropSpaceLoadedState()
			DriveAPI.getFile(spaceId).then(file => {
				if ((<any>file).error) {
					reject((<any>file).error)
					return
				}
				if (file.mimeType == GoogleMimeTypes.FOLDER) {
					this.space = file
					this.setSpaceObjectState()
					this.getAllSpaceFiles()
					resolve(this.space)
				} else {
					reject({code: ErrorsCodes.NOT_FOLDER})
				}
			})
		})
	}

	private getAllSpaceFiles() {
		this.errorsMap = {}
		this.childrenMap = {}
		this.spaceFiles = {}

		SpaceHelpers.getFilesDeep(this.space, 0, (
			fullLoad: boolean,
			fileSet: ISpaceFiles,
			childrenMap: IChildrenMap,
			errorsMap: IErrorsMap,
		) => {
			this.spaceFiles = fileSet
			this.childrenMap = childrenMap
			this.errorsMap = errorsMap
			this.completeLoadingSpace(fullLoad)

			if (fullLoad) {
				console.log('space files length:', Object.keys(this.spaceFiles).length)
				console.log('errors:', Object.keys(this.errorsMap).length)
				this.getActionItems()
			}
		})
	}

	updateSpaceFilesForFolder(parent: string | gapi.client.drive.File, deepness = 1): Promise<void> {
		return new Promise((resolve, reject) => {
			const parentFile = typeof parent === 'string' ? FileUtils.getFileById(parent) : parent
			if (!parentFile) {
				reject()
				return
			}
			SpaceHelpers.getFilesDeep(parentFile, deepness, (
				fullLoad: boolean,
				fileSet: ISpaceFiles,
				childrenMap: IChildrenMap,
				errorsMap: IErrorsMap,
			) => {
				Object.assign(this.spaceFiles, fileSet)
				Object.assign(this.childrenMap, childrenMap)
				Object.assign(this.errorsMap, errorsMap)

				this.setSpaceFilesState()
				this.setChildrenMapState(childrenMap)
				this.setErrorsMap()

				resolve()
			})
		})
	}

	getFileData(fileId: string): Promise<gapi.client.drive.File> {
		return new Promise((resolve, reject) => {
			DriveAPI.getFile(fileId).then(file => {
				if ((<any>file).error) {
					reject((<any>file).error)
					return
				}
				resolve(file)
			})
		})
	}

	setPageIndex(pageId: string, newIndex: string, newParentId: string) {
		const page = this.spaceFiles[pageId]
		if (!pageId) {
			return
		}
		const backupPage = cloneDeep(page)
		const currentParentId = page.parents[0]
		const currentParents = page.parents

		newIndex = OrderGenerator.toRLE(newIndex)

		//save locally
		if (!page.properties) {
			page.properties = {
				[SpaceliProperties.SPACELI_PAGE_INDEX]: newIndex
			}
		} else {
			page.properties[SpaceliProperties.SPACELI_PAGE_INDEX] = newIndex
		}

		const resource: any = {
			properties: {
				[SpaceliProperties.SPACELI_PAGE_INDEX]: newIndex
			}
		}
		let parentToAdd = ''
		let parentsToRemove = ''

		if (newParentId !== currentParentId) {
			parentsToRemove = currentParents.join(',')
			parentToAdd = newParentId
			page.parents = [newParentId]
		}

		currentParents.forEach(parentId => {
			if (!this.childrenMap[parentId]) {
				return
			}
			const pageIndex = this.childrenMap[parentId].findIndex(id => id === pageId)
			this.childrenMap[parentId].splice(pageIndex, 1)
			this.setChildrenMapState({[parentId]: this.childrenMap[parentId]})
		})
		if (!this.childrenMap[newParentId]) {
			this.childrenMap[newParentId] = []
		}
		this.childrenMap[newParentId].push(page.id)
		this.setChildrenMapState({[newParentId]: this.childrenMap[newParentId]})

		//save to server
		DriveAPI.saveNewIndex(pageId, parentToAdd, parentsToRemove, resource).then((result: any) => {
			if (result.error) {
				page.parents.forEach(parentId => {
					if (!this.childrenMap[parentId]) {
						return
					}
					this.childrenMap[parentId].push(backupPage.id)
					this.setChildrenMapState({[parentId]: this.childrenMap[parentId]})
				})
				const pageIndex = this.childrenMap[newParentId].findIndex(id => id === pageId)
				this.childrenMap[newParentId].splice(pageIndex, 1)
				this.setChildrenMapState({[newParentId]: this.childrenMap[newParentId]})
				NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG, true)
			}
		})
	}

	getActionItems() {
		this.tasksMentionsService.getActionItemFilesForSpace()
	}
}
