<template>
	<div class="file-menu">
		<div
				class="file-menu-item"
				@click="sendAction(p)"
				v-for="p in permissions"
				:key="p.value"
		>
			<div class="file-menu-item-text">{{ p.text }}</div>
		</div>
		<div
				class="file-menu-item"
				@click="sendAction({text: '', value: 'remove'})"
				v-if="showRemove">
			<div class="file-menu-item-text">Remove</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconEdit from 'components/icons/icons/IconEdit.vue'
	import IconTrash from 'components/icons/icons/IconTrash.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {IInviteePermission, INVITEE_PERMISSIONS} from 'modules/permissions/PermissionHelpers'

	@Component({
		components: {
			IconEdit,
			IconTrash,
		}
	})

	export default class ShareMenu extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@Prop({default: true})
		showRemove: boolean

		private permissions: IInviteePermission[] = INVITEE_PERMISSIONS

		private sendAction(action: IInviteePermission) {
			this.$emit('action', action)
		}
	}
</script>

<style scoped>

</style>
