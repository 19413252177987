<template>
	<BaseModal ref="modal" @closed="onClosed" @ready="onReady">
		<div class="modal-content-container">
			<h2 class="modal-header">Rename</h2>

			<InputWhite ref="inputfield" v-model="name" :field-title="'Name'"/>

			<div class="modal-buttons-container">
				<div
						class="rounded-button-medium db-50-button button-margin-4"
						@click="saveData"
						:class="{'disabled-light-theme-button': name.length < 3}"
				>
					Save
				</div>
				<div class="rounded-button-medium db-50-text-button cancel-button" @click="close">Cancel</div>
			</div>
		</div>

		<Loading :is-loading="isLoading"/>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import Loading from 'components/common/Loading.vue'
	import InputWhite from 'components/common/form/InputWhite.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import TopicsService, {ITopic} from 'components/topics/TopicsService'
	import NotificationService from 'modules/notifications/NotificationService'
	import {SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'

	const MIN_NAME_LENGTH = 3

	@Component({
		components: {InputWhite, Loading, BaseModal}
	})

	export default class RenameTopicModal extends Vue {
		private topicsService: TopicsService = TopicsService.getInstance()
		private topic: ITopic
		isLoading: boolean = false

		name: string = ''

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_RENAME_TOPIC, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_RENAME_TOPIC, this.open)
		}

		open(topic: ITopic) {
			this.isLoading = false
			this.topic = topic
			this.name = topic.name
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		close() {
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		onClosed() {
			this.topic = undefined
			this.name = ''
		}

		onReady() {
			const input = this.$refs.inputfield as any
			input.focusInput()
		}

		private saveData() {
			if (this.name.length < MIN_NAME_LENGTH || this.name === this.topic.name) {
				return
			}
			this.isLoading = true

			this.topicsService.renameTopic(this.topic.id, this.name).then(() => {
				this.topicsService.getTopics().then(() => {
					EventBus.$emit(CustomEventNames.TOPIC_UPDATED, this.topic.id)
					this.close()
				}, () => {
					this.handleError()
				})
			}, () => {
				this.handleError()
			})
		}

		private handleError() {
			NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
			this.close()
		}
	}
</script>

<style scoped>
	.rename-margin {
		margin-top: 32px;
	}
</style>
