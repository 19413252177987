<template>
	<div class="file-view" ref="documentPageContainer" tabindex="0">
		<div class="file-content-view">
			<div class="file-header-container" v-if="!emptyDoc">
				<h1 class="file-header">{{ file.name }}</h1>
			</div>

			<div class="document-view-content" ref="documentContent" id="documentContent" v-html="html" v-show="!emptyDoc"></div>
<!--			v-if="docType === 'googleDoc'"-->
<!--			<iframe ref="iframe" height="100%" class="document-view-content" frameborder="0" scrolling="no" v-if="docType === 'office'"></iframe>-->

			<div class="empty-doc-container" v-if="emptyDoc && !calcPermission(file, SpaceAction.EDIT_PAGE)">
				<ImgEmpty class="img-empty"/>
				<div class="paragraph-text empty-text">Looks like the page is empty.</div>
			</div>

			<div class="empty-doc-container" v-if="emptyDoc && calcPermission(file, SpaceAction.EDIT_PAGE)">
				<ImgEmpty class="img-empty"/>
				<div class="paragraph-text empty-text">Looks like the page is empty. Open editor and share your knowledge!</div>
				<div class="modal-buttons-container">
					<div class="rounded-button-medium db-50-button button-margin-4" @click="openEditor">Open editor</div>
				</div>
			</div>

			<PageFooter class="page-footer" :file="file" v-if="file.lastModifyingUser && !emptyDoc"/>
		</div>

		<div class="page-sidebar">
			<TableOfContents ref="toc" :content-selector="'.document-view-content'" :scrollContainer="'.file-view'"/>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import DriveAPI from 'api/DriveAPI'
	import {ISpacePage} from 'components/space/pages/ISpacePage'
	import PageFooter from 'components/space/elements/PageFooter.vue'
	import EditByEditor from 'components/space/buttons/EditByEditor.vue'
	import ViewInDrive from 'components/space/buttons/ViewInDrive.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import RoutingUtils from 'utils/RoutingUtils'
	import TableOfContents from 'components/space/elements/TableOfContents.vue'
	import ImgEmpty from 'components/icons/icons/ImgEmpty.vue'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import Utils from 'utils/Utils'
  import {ErrorsCodes, ErrorMessages} from "../../../utils/ErrorHandlingUtils";

	// const inlineCss = require('inline-css')

	@Component({
		components: {
			TableOfContents,
			PageFooter,
			EditByEditor,
			ViewInDrive,
			ImgEmpty,
		}
	})
	export default class DocumentPage extends Vue implements ISpacePage {
		@Prop()
		file: gapi.client.drive.File
		html: string = ''
		emptyDoc: boolean = false

		private clickWrapper = this.onClick.bind(this)
		private docType: string = 'googleDoc'

		update(): void {
			if (this.file && this.file.id) {
				this.getFileHtml()
			}
		}

		loaded(): void {
			EventBus.$emit(CustomEventNames.PAGE_LOADED)
		}

		mounted() {
			this.getFileHtml()
			const doc = this.$refs.documentContent as HTMLElement
			doc.addEventListener('click', this.clickWrapper)
		}

		beforeDestroy() {
			const doc = this.$refs.documentContent as HTMLElement
			doc.removeEventListener('click', this.clickWrapper)
		}

		@Watch('file')
		onFileChanged(val: string, oldVal: string) {
			if (val !== oldVal) {
				this.getFileHtml()
				this.scrollToTop()
			}
		}

		private scrollToTop() {
			const scrollContainer = this.$refs.documentPageContainer as HTMLElement
			scrollContainer.scrollTop = 0
		}

		private getFileHtml() {
			this.emptyDoc = false
			if (this.file.mimeType === GoogleMimeTypes.DOCS || this.file.mimeType === GoogleMimeTypes.DOCS_OLD) {
				this.getHtmlForGoogleDocs()
			} else {
				this.getHtmlForOtherFile()
			}
		}

		private getHtmlForGoogleDocs() {
			DriveAPI.exportFileToHtml(this.file.id).then((result: any) => {
				if (result.error) {
					if (result.error.code === ErrorsCodes.NOT_FOUND) {
						EventBus.$emit(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, ErrorMessages.NOT_FOUND)
					} else {
						EventBus.$emit(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, ErrorMessages.SOMETHING_WENT_WRONG, true)
					}
					return
				}
				this.processContent(result.body)
			})
		}

		private processContent(html: string) {
			this.html = this.processHtml(html)
			this.checkForEmptyDoc()
			this.loaded()
			setTimeout(() => {
				this.refreshToc()
        const scrollContainer = this.$refs.documentPageContainer as HTMLElement
        scrollContainer.focus()
			})
		}

		private getHtmlForOtherFile() {
			Utils.getHtmlForOtherFile(this.file.id).then(html => {
				// console.log(html)
				this.processContent(html)
				// const iframe = this.$refs.iframe as HTMLIFrameElement
				// const doc = iframe.contentWindow.document
				// doc.open()
				// doc.write(html)
				// doc.close()
				// inlineCss(html, {url: ''}).then((ihtml => {
				// 	this.processContent(ihtml)
				// }))
				// iframe.onload = () => {
				// 	iframe.style.height = doc.body.scrollHeight + 'px';
				// }
			}, () => {
				//todo
			})
		}

		private checkForEmptyDoc() {
			const tempDoc = document.createElement('div')
			tempDoc.innerHTML = this.html
			if (!tempDoc.innerText) {
				this.emptyDoc = true
			}
		}

		private refreshToc() {
			const toc = this.$refs.toc as any
			if (toc) {
				toc.refresh()
			}
		}

		private processHtml(html) {
			html = html
          .replace(/(line-height:)(\d+\.?\d*|\.\d+)/g, function (match, p1, p2) {
            return p1 + (p2 * 1.2).toFixed(2)
          })
          .replace(/(<li[\s\S]+?)(margin-left:)(\d+\.?\d*|\.\d+)(\w+)/g, function (match, p1, p2, p3, p4) {
            return p1 + p2 + (p3 - 18) + p4
          })
          // remove comments from the exported doc. <sup> is for anchor in the text, <div> is for comment
          .replace(/<sup><a href="#cmnt\w.+?<\/a><\/sup>|<div style="border:1px solid black;margin:5px">[\w\W]+<\/div>/g, '')
          // .replace(/(<li[\s\S]+?)(padding-top:)(\d+\.?\d*|\.\d+)(\w+)/g, function (match, p1, p2, p3, p4) {
          //   return p1 + p2 + (p3 - 18) + p4
          // })
          // .replace(/(<li[\s\S]+?)(padding-bottom:)(\d+\.?\d*|\.\d+)(\w+)/g, function (match, p1, p2, p3, p4) {
          //   return p1 + p2 + (p3 - 18) + p4
          // })
          .replace(/href="mailto:/, 'class="google-user-mention" href="mailto:')
			return html
		}

		private openEditor() {
			EventBus.$emit(CustomEventNames.OPEN_FILE_EDITOR, this.file)
		}

		private onClick(e: MouseEvent) {
			const target = e.target as HTMLElement
			if (!target) {
				return
			}
			const closestLink = target.closest('a')
			if (!closestLink) {
				return
			}
			const href = closestLink.href || ''

			if (href) {
				e.preventDefault()
				const originalUrl = href.replace(/https:\/\/www.google.com\/url\?q=/g, '')
				const url = new URL(originalUrl)
				if (url.host === 'docs.google.com' || url.host === 'drive.google.com') {
					const fileId = this.getDocId(originalUrl)
					if (fileId) {
						RoutingUtils.openPage(fileId)
					} else {
						window.open(href, '_blank')
					}
				} else {
					window.open(href, '_blank')
				}
			}
		}

		getDocId(url): string | null {
			url = url.replace(/https:\/\/docs\.google\.com\/[^"]+\/d\//g, '')
				.replace(/https:\/\/drive\.google\.com\/drive\/folders\//g, '')
				.replace(/https:\/\/drive\.google\.com\/open\?id%3D/g, '')
				.replace(/https:\/\/drive\.google\.com\/file\/d\//g, '')

			const res = url.match(/^[^/&]+/)
			return res ? res[0] : null
		}
	}
</script>

<style scoped>
	.file-content-view {
		padding: 0 48px 0 72px;
		align-items: center;
	}

	.file-header-container {
		max-width: 768px;
		width: 100%;
	}

	.page-footer {
		width: 100%;
		max-width: 768px;
	}

	.document-view-content {
		width: 100%;
		max-width: 768px;
		user-select: text;
		margin-bottom: 32px;
		flex-shrink: 0;
	}

	.page-sidebar {
		margin-right: 24px;
    position: sticky;
    top: 0;
		min-width: 16px;
		height: 100%;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding: 58px 0 40px 0;
		flex: none;
	}

	.empty-doc-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
		margin-bottom: 128px;
	}

	.img-empty {
		color: var(--g-60);
		margin-bottom: 24px;
	}

	.empty-text {
		max-width: 210px;
		text-align: center;
	}

	@media only screen and (max-width: 1024px) {
		.page-sidebar {
			display: none;
		}

		.file-content-view {
			padding: 0 72px;
		}
	}

	@media only screen and (max-width: 480px) {
		.file-content-view {
			padding: 0 32px;
		}

		.modal-buttons-container {
			display: none;
		}
	}
</style>
