import Vue from 'vue'
import PopupMessage from "./PopupMessage.vue"

export default class NotificationService {
	private static _instance: NotificationService

	private popupInstance: any

	static getInstance(): NotificationService {
		if (!NotificationService._instance) {
			NotificationService._instance = new NotificationService()
		}
		return NotificationService._instance
	}

	constructor() {
		const ComponentClass = Vue.extend(PopupMessage)
		this.popupInstance = new ComponentClass()
		this.popupInstance.$mount()
		document.body.appendChild(this.popupInstance.$el)
	}

	showNotification(message, danger = false) {
		this.popupInstance.shopPopupMessage(message, danger)
	}
}
