<template>
	<div class="space-view">
		<SpaceSidebar/>

		<SpacePage ref="spacePage" :pageId="currentPageId"/>

		<FileEditor/>

		<CreateFile/>

		<DeleteModal/>
		<RenameModal/>

		<QuickSwitcher/>

		<AccessErrorPage/>
	</div>
</template>

<script lang="ts">
	import {Component, Vue, Watch} from 'vue-property-decorator'
	import SpaceSidebar from 'components/space/SpaceSidebar.vue'
	import SpaceService from 'modules/space/SpaceService'
	import {RouteParams} from 'router/Router'
	import AppService from 'modules/app/AppService'
	import RoutingUtils from 'utils/RoutingUtils'
	import SpacePage from 'components/space/SpacePage.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import FileEditor from 'components/space/editor/FileEditor.vue'
	import CreateFile from 'components/space/createfile/CreateFile.vue'
	import Utils from 'utils/Utils'
	import DeleteModal from 'components/common/modals/DeleteModal.vue'
	import RenameModal from 'components/common/modals/RenameModal.vue'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import AuthService from 'modules/app/AuthService'
	import QuickSwitcher from 'components/common/modals/QuickSwitcher.vue'
	import Stat from 'modules/stat/Stat'
	import FileUtils from 'utils/FileUtils'
	import {isActionPage} from 'components/space/pages/actionpages/ActionPages'
  import {ErrorHandlingUtils} from "../../utils/ErrorHandlingUtils";
  import AccessErrorPage from "../common/AccessErrorPage.vue";

	declare const $crisp: any

	@Component({
		components: {
			QuickSwitcher,
      AccessErrorPage,
			RenameModal,
			DeleteModal,
			CreateFile,
			SpaceSidebar,
			SpacePage,
			FileEditor,
		}
	})

	export default class Space extends Vue {
		private appProcessor: AppService = AppService.getInstance()
		private spaceProcessor: SpaceService = SpaceService.getInstance()

		@State
		space: gapi.client.drive.File

		@State
		currentPageId: string

		@State
		currentSpaceId: string

		mounted() {
			const spaceId = this.$route.params[RouteParams.SPACE_ID] as string
			const pageId = this.$route.params[RouteParams.PAGE_ID] as string

			this.setCurrentSpaceId(spaceId)
			pageId ? this.setCurrentPageId(pageId) : this.setCurrentPageId(spaceId)

			this.appProcessor.isInit ? this.init() : this.appProcessor.appInitialized.addOnce(this.init, this)

			EventBus.$on(CustomEventNames.SIDEBAR_ITEM_SELECTED, this.openPage)
			EventBus.$on(CustomEventNames.FILE_CREATED, this.onFileCreated)
			EventBus.$on(CustomEventNames.FILE_DELETED, this.onFileDeleted)
			EventBus.$on(CustomEventNames.FILE_UPDATED, this.onFileUpdated)
			EventBus.$on(CustomEventNames.UPDATE_SPACE_FILES, this.updateSpaceFiles)

			EventBus.$on(CustomEventNames.FILE_EDITOR_CLOSED, this.onFileEditorClosed)

      AuthService.getInstance().authStateChanged.add(this.controlChatBox, this)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.SIDEBAR_ITEM_SELECTED, this.openPage)
			EventBus.$off(CustomEventNames.FILE_CREATED, this.onFileCreated)
			EventBus.$off(CustomEventNames.FILE_DELETED, this.onFileDeleted)
			EventBus.$off(CustomEventNames.FILE_UPDATED, this.onFileUpdated)
			EventBus.$off(CustomEventNames.UPDATE_SPACE_FILES, this.updateSpaceFiles)

			EventBus.$off(CustomEventNames.FILE_EDITOR_CLOSED, this.onFileEditorClosed)

			this.setCurrentPageId('')
			this.setCurrentSpaceId('')

      AuthService.getInstance().authStateChanged.remove(this.controlChatBox, this)
		}

		private onFileEditorClosed() {
			if (isActionPage(this.currentPageId)) {
				this.spaceProcessor.getActionItems()
			}
		}

		private controlChatBox(isSignedIn: boolean) {
			if (isSignedIn) {
				$crisp.push(['do', 'chat:show'])
			} else {
				$crisp.push(['do', 'chat:hide'])
			}
		}

		private setCurrentSpaceId(spaceId: string) {
			this.$store.commit('setCurrentSpaceId', spaceId)
		}

		private setCurrentPageId(pageId: string) {
			this.$store.commit('setCurrentPageId', pageId)
		}

		private setRemainedRoute(route: string) {
			this.$store.commit('setRemainedRoute', route)
		}

		private updateSpaceFiles() {
			this.getSpaceData()
		}

		private init() {
			this.getSpaceData()
		}

		private getSpaceData() {
			this.spaceProcessor.getSpaceData(this.currentSpaceId).then(space => {
        Stat.openSpace(this.$store.state.googleUser)
				this.setDocumentTitle()
			}, error => {
        ErrorHandlingUtils.handleRequestDataErrorAndBlockApp(error, AuthService.getInstance(), 'space')
			})
		}

		private setDocumentTitle() {
			if (this.space && this.space.name) {
				Utils.setDocumentTitle(this.space.name)
			}
		}

		beforeRouteUpdate(to: any, from: any, next: any) {
			const pageId = to.params[RouteParams.PAGE_ID]
			if (pageId && pageId !== this.currentPageId) {
				this.setCurrentPageId(pageId)
			}
			next()
		}

		openPage(fileId: string) {
			if (fileId !== this.currentPageId) {
				this.setCurrentPageId(fileId)
				RoutingUtils.openPage(fileId)
			}
		}

		private onFileCreated(file: gapi.client.drive.File, openEditor = true, updateFolder = true, folderForUpdate: string = undefined) {
			RoutingUtils.openPage(file.id)
			if (
				file.mimeType !== GoogleMimeTypes.EXTERNAL_SHORTCUT &&
				file.mimeType !== GoogleMimeTypes.SPACELI_SHORTCUT &&
				file.mimeType !== GoogleMimeTypes.FOLDER &&
				file.mimeType !== GoogleMimeTypes.FORMS &&
				openEditor
			) {
				EventBus.$emit(CustomEventNames.OPEN_FILE_EDITOR, file)
			}
			if (updateFolder) {
				this.updateFolderFiles(file.parents[0])
				if (folderForUpdate) {
					this.updateFolderFiles(folderForUpdate)
				}
			}
		}

		private onFileDeleted(file: gapi.client.drive.File) {
			const children = FileUtils.getChildrenForFolder(file)
			children.forEach(child => {
				if (this.currentPageId === child.id) {
					this.openPage(this.space.id)
				}
			})
			if (this.currentPageId === file.id) {
				this.openPage(this.space.id)
			}
			this.updateFolderFiles(file.parents[0])
		}

		private onFileUpdated(file: gapi.client.drive.File) {
			this.updateFolderFiles(file.parents[0])
			if (this.currentPageId === file.id) {
				const component = this.$refs.spacePage as any
				component.update()
			}
		}

		private updateFolderFiles(parent: string) {
			this.spaceProcessor.updateSpaceFilesForFolder(parent)
		}
	}
</script>

<style scoped>
	.space-view {
		width: 100%;
		height: 100%;
		display: flex;
		background-color: var(--white);
	}

	@media only screen and (max-width: 768px) {

	}

	@media only screen and (max-width: 480px) {

	}
</style>
