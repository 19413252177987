<template>
	<div class="input-container" @click="onInputClick">
		<div class="field-title" v-if="fieldTitle">{{ fieldTitle }}</div>
		<input
			ref="inputfield"
			:readonly="readonly"
			:value="value"
			v-on:input="$emit('input', $event.target.value)"
			:placeholder="placeholder"
		/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'

	@Component({})

	export default class InputWhite extends Vue {
		@Prop()
		value: string

		@Prop({default: false})
		readonly

		@Prop()
		placeholder: string

		@Prop()
		fieldTitle: string

		focusInput() {
			const input = this.$refs.inputfield as any
			input.focus()
		}

		onInputClick() {
			if (this.readonly) {
				const input = this.$refs.inputfield as HTMLInputElement
				input.select()
			}
		}
	}
</script>

<style scoped>
	.input-container {
		width: 100%;
		height: 58px;
		position: relative;
		padding: 0px 20px;
		box-sizing: border-box;
		border-radius: 16px;
		border: 1px solid var(--g-20);
		display: flex;
		align-items: center;
	}

	.field-title {
		position: absolute;
		top: -8px;
		left: 20px;
		font-size: 14px;
		line-height: 16px;
		color: var(--g-60);
		padding: 0 12px 0 8px;
		background-color: var(--white);
		user-select: none;
	}

	input {
		font-family: 'Jost', Helvetica, Arial, sans-serif;
		height: 100%;
		width: 100%;
		border: none;
		padding: 0;
		font-size: 14px;
		outline: none;
		box-sizing: border-box;
		background-color: transparent;
	}

	input:read-only {
		color: var(--g-60);
	}

	input::placeholder {
		color: var(--g-70);
	}
</style>
