<template>
	<div class="file-menu">
		<div class="user-data-container">
			<div class="user-name text-ellipsis">{{ getUserName }}</div>
			<div class="user-email text-ellipsis">{{ getUserEmail }}</div>
		</div>
<!--		<div class="file-menu-item" @click="openFeedback">-->
<!--			<div class="file-menu-item-icon">-->
<!--				<IconFeedback/>-->
<!--			</div>-->
<!--			<div class="file-menu-item-text">Give feedback</div>-->
<!--		</div>-->
		<div class="file-menu-item" @click="signOut">
			<div class="file-menu-item-icon">
				<IconLogout/>
			</div>
			<div class="file-menu-item-text">Sign out</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import IconFeedback from 'components/icons/icons/IconFeedback.vue'
	import IconLogout from 'components/icons/icons/IconLogout.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import AuthService from 'modules/app/AuthService'
	import {State} from 'vuex-class'

	@Component({
		components: {
			IconFeedback,
			IconLogout,
		}
	})

	export default class FileMenu extends Vue {
		@State(state => state.googleUser)
		googleUser: gapi.auth2.GoogleUser

		private openFeedback() {
			EventBus.$emit(CustomEventNames.OPEN_FEEDBACK)
		}

		signOut() {
      AuthService.getInstance().signOut()
		}

		get getUserName(): string {
			return this.googleUser?.getBasicProfile().getName() || ''
		}

		get getUserEmail(): string {
			return this.googleUser?.getBasicProfile().getEmail() || ''
		}
	}
</script>

<style scoped>
	.user-data-container {
		display: flex;
		flex-direction: column;
		padding: 12px 16px;
		box-sizing: border-box;
		border-bottom: 1px solid var(--white-10);
		max-width: 260px;
	}

	.user-name {
		font-weight: 600;
	}

	.user-email {
		color: var(--g-40);
	}

	.file-menu-item {
		display: flex;
		padding: 8px 16px;
		border-bottom: 1px solid var(--white-10);
		cursor: pointer;
	}
</style>
