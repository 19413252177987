<template>
	<div v-if="calcPermission(page, SpaceAction.EDIT_PAGE)" class="circle-button-32" v-tooltip="{content: 'Edit', placement: 'bottom'}" @click="editFile">
		<IconEdit/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IconEdit from 'components/icons/icons/IconEdit.vue'

	@Component({
		components: {
			IconEdit,
		}
	})

	export default class EditByEditor extends Vue {
		@Prop()
		page: gapi.client.drive.File

		private editFile() {
			EventBus.$emit(CustomEventNames.OPEN_FILE_EDITOR, this.page)
		}
	}
</script>

<style scoped>

</style>
