<template>
	<BaseModal ref="modal" :width="404" @closed="onClosed">
		<div class="modal-content-container" @click="onModalClick">
			<h2 class="modal-header">Set a topic</h2>

			<DropdownWhite :display-text="selectedTopicTitle" ref="topicsDropdown">
				<div class="topics-container">
					<LightTopicItem v-for="topic in rootTopics" :key="topic.id" :topic="topic"/>
					<div class="no-topics" v-if="!rootTopics.length">Looks like you have no topics</div>
				</div>
			</DropdownWhite>

			<div class="modal-buttons-container">
				<div class="rounded-button-medium db-50-button button-margin-4" @click="saveData">Save</div>
				<div class="rounded-button-medium db-50-text-button cancel-button" @click="close">Cancel</div>
				<div class="grow-container"></div>
				<div v-if="hasTopic()" class="rounded-button-medium db-50-text-button remove-button" @click="removeTopic">Remove topic</div>
			</div>
		</div>

		<Loading :is-loading="isLoading"/>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import Loading from 'components/common/Loading.vue'
	import InputWhite from 'components/common/form/InputWhite.vue'
	import TextareaWhite from 'components/common/form/TextareaWhite.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import DropdownWhite from 'components/common/form/DropdownWhite.vue'
	import TopicsService, {ITopic} from 'components/topics/TopicsService'
	import TopicsUtils from 'components/topics/TopicsUtils'
	import LightTopicItem from 'components/topics/LightTopicItem.vue'
	import {SpaceliProperties} from 'enums/FileProps'
	import {SAVED, SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
	import NotificationService from 'modules/notifications/NotificationService'
	import DashboardService from 'modules/dashboard/DashboardService'

	@Component({
		components: {LightTopicItem, DropdownWhite, TextareaWhite, InputWhite, Loading, BaseModal}
	})

	export default class SetTopicModal extends Vue {
		space: gapi.client.drive.File = {}
		isLoading: boolean = false

		selectedTopicTitle: string = 'None'
		selectedTopic: ITopic

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_SET_TOPIC, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_SET_TOPIC, this.open)
		}

		hasTopic(): boolean {
			return !!this.selectedTopic
		}

		get rootTopics(): ITopic[] {
			return TopicsUtils.getTopicChildren('root')
		}

		private onModalClick() {
			const topicsDropdown = this.$refs.topicsDropdown as any
			if (topicsDropdown) {
				topicsDropdown.closeOptions()
			}
		}

		private topicSelected(topicId) {
			const topicsDropdown = this.$refs.topicsDropdown as any
			if (topicsDropdown) {
				topicsDropdown.closeOptions()
			}

			if (topicId) {
				const topic = TopicsUtils.getTopicById(topicId)
				if (topic) {
					this.selectedTopic = topic
					this.selectedTopicTitle = topic.name
					return
				}
			}
		}

		private removeTopic() {
			this.isLoading = true
			this.setTopicToSpace(null)
		}

		open(space: gapi.client.drive.File) {
			this.isLoading = false
			this.space = space

			this.selectedTopicTitle = 'None'

			if (this.space.appProperties && this.space.appProperties[SpaceliProperties.TOPIC]) {
				this.topicSelected(this.space.appProperties[SpaceliProperties.TOPIC])
			}

			const modal = this.$refs.modal as any
			modal.openModal()
			EventBus.$on(CustomEventNames.LIGHT_TOPIC_ITEM_SELECTED, this.topicSelected)
		}

		close() {
			EventBus.$off(CustomEventNames.LIGHT_TOPIC_ITEM_SELECTED, this.topicSelected)
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		onClosed() {
			this.space = undefined
			this.selectedTopic = undefined
			this.selectedTopicTitle = ''
		}

		saveData() {
			if (
				!this.selectedTopic ||
				(
					this.space.appProperties &&
					this.space.appProperties[SpaceliProperties.TOPIC] &&
					this.selectedTopic &&
					this.selectedTopic.id === this.space.appProperties[SpaceliProperties.TOPIC]
				)
			) {
				this.close()
				return
			}

			this.isLoading = true

			this.setTopicToSpace(this.selectedTopic.id)
		}

		private setTopicToSpace(topicId: string | null) {
			TopicsService.getInstance().setTopicToSpace(this.space, topicId).then(() => {
				//update local
				if (!this.space.appProperties) {
					this.space.appProperties = {}
				}
				this.space.appProperties[SpaceliProperties.TOPIC] = topicId
				DashboardService.getInstance().updateSpace(this.space)

				NotificationService.getInstance().showNotification(SAVED)
				this.close()
			}, () => {
				NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
				this.close()
			})
		}
	}
</script>

<style scoped>
	.modal-content-container {
		width: 404px;
	}

	.topics-container {

	}

	.no-topics {
		padding: 16px;
		color: var(--g-60);
	}

	.grow-container {
		flex-grow: 1;
	}
</style>
