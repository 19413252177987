import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
import {SpaceliProperties, SpaceliPropertyValues} from 'enums/FileProps'
import Utils from 'utils/Utils'
import NotificationService from 'modules/notifications/NotificationService'
import {SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
import FileUtils from 'utils/FileUtils'
import {IFilesCommentsMap} from 'modules/tasksmentions/TasksMentionsService'
import {SpaceCorpora} from 'modules/permissions/PermissionHelpers'

const FILE_FIELDS = 'id,name,description,parents,appProperties,properties,mimeType,modifiedTime,permissions(*),viewedByMeTime,viewedByMe,webViewLink,trashed,lastModifyingUser,capabilities,createdTime,thumbnailLink,imageMediaMetadata/width,imageMediaMetadata/height,webContentLink,shortcutDetails,teamDriveId,driveId,starred,owners(*),ownedByMe'
const FILE_LIST_FIELDS = 'files(' + FILE_FIELDS + ')'

export default class DriveAPI {
	static getFiles(q: string, corpora = SpaceCorpora.USER): Promise<gapi.client.drive.File[]> {
		return new Promise((resolve, reject) => {
			gapi.client.drive.files.list({
				q: q,
				corpora: corpora,
				fields: 'nextPageToken, ' + FILE_LIST_FIELDS,
				pageSize: 1000,
				supportsAllDrives: true,
				includeItemsFromAllDrives: true
			}).execute((result: any) => {
				if (result.error) {
					reject(result.error)
				} else {
					resolve(result.files)
				}
			})
		})
	}

	static getPermissionsForFile(fileId: string): Promise<gapi.client.drive.Permission[]> {
		return new Promise((resolve, reject) => {
			gapi.client.drive.permissions.list({
				fileId: fileId,
				fields: 'permissions',
				supportsAllDrives: true
			}).execute((response: any) => {
				if (!response.error) {
					resolve(response.permissions)
				} else {
					reject()
				}
			})
		})
	}

	static markFolderAsRoot(id: string, topic?: string): Promise<gapi.client.drive.File> {
		return new Promise((resolve, reject) => {
			const resource: any = {
				properties: {
					[SpaceliProperties.SPACELI]: SpaceliPropertyValues.FOLDER
				}
			}
			if (topic) {
				resource.appProperties = {
					[SpaceliProperties.TOPIC]: topic
				}
			}
			DriveAPI.updateFileProps(id, resource).then(res => {
				resolve(res)
			})
		})
	}

	static createNewSpace(name: string, topic?: string, driveId?: string): Promise<gapi.client.drive.File> {
		return new Promise((resolve, reject) => {
			const resource: any = {
				name: name,
				mimeType: GoogleMimeTypes.FOLDER,
				properties: {
					[SpaceliProperties.SPACELI]: SpaceliPropertyValues.FOLDER
				}
			}
			if (topic) {
				resource.appProperties = {
					[SpaceliProperties.TOPIC]: topic
				}
			}
			if (driveId) {
				resource.parents = [driveId]
			}

			gapi.client.drive.files.create({
				resource: resource,
				supportsAllDrives: true
			}).execute(folder => {
				resolve(folder as gapi.client.drive.File)
			})
		})
	}

	static updateFileProps(fileId: string, resource: any): Promise<any> {
		return new Promise((resolve, reject) => {
			gapi.client.drive.files.update({
				fileId: fileId,
				supportsAllDrives: true,
				resource: resource
			}).execute((result: any) => {
				resolve(result)
			})
		})
	}

	static saveNewIndex(fileId: string, parentToAdd: string, parentToRemove: string, res: any): Promise<gapi.client.drive.File> {
		return new Promise(resolve => {
			gapi.client.drive.files.update({
				fileId: fileId,
				addParents: parentToAdd,
				removeParents: parentToRemove,
				resource: res,
				supportsAllDrives: true
			}).then((result: any) => {
				resolve(result)
			})
		})
	}

	static getFile(fileId: string): Promise<gapi.client.drive.File> {
		return new Promise(resolve => {
			gapi.client.drive.files.get({
				fileId: fileId,
				fields: FILE_FIELDS,
				supportsAllDrives: true
			}).execute((file: any) => {
				resolve(file)
			})
		})
	}

	static getComposedParentsString(folders: string[]) {
		return folders.reduce((previousValue, currentItem, index, array) => {
			let value = previousValue
			if (index > 0) {
				value += ' or '
			}
			value += '\'' + currentItem + '\' in parents'
			if (index === array.length - 1) {
				value += ')'
			}
			return value
		}, '(')
	}

	static getFilesRequestData(folders: string[], corpora = 'user', pageToken: string, driveId?: string) {
		const parents = DriveAPI.getComposedParentsString(folders)
		const data: any = {
			q: parents + ' and trashed = false',
			pageSize: 1000,
			fields: 'nextPageToken, ' + FILE_LIST_FIELDS,
			// orderBy: 'folder,name_natural',
			supportsAllDrives: true,
			includeItemsFromAllDrives: true,
			corpora: corpora,
		}
		if (driveId) {
			data.corpora = 'drive'
			data.driveId = driveId
		}
		if (pageToken) {
			data.pageToken = pageToken
		}
		return data
	}

	static getFilesForFoldersByBatch(folders: string[], corpora = 'user', driveId?: string): Promise<{files: gapi.client.drive.File[], errorsMap: any}> {
		return new Promise((resolve) => {
			let files: gapi.client.drive.File[] = []
			let errorsMap
			const processingAdditionalItems: string[] = []
			let batchEnded = false

			const batch = gapi.client.newBatch()

			const checkAllRequestsEnded = () => {
				if (!processingAdditionalItems.length && batchEnded) {
					resolve({files, errorsMap})
				}
			}

			const getFiles = (folderId, pageToken) => {
				const data = DriveAPI.getFilesRequestData([folderId], corpora, pageToken, driveId)
				gapi.client.drive.files.list(data).then(r => { //getData(folderId, pageToken)
					processItem(folderId, r.result)
				})
			}

			const handler = (response) => {
				processItem(response.id, response.result)
			}

			const processItem = (folderId, result) => {
				if (result.error) {
					if (!errorsMap) {
						errorsMap = {}
					}
					errorsMap[folderId] = result.error
				} else {
					files = files.concat(result.files)
					if (result.nextPageToken) {
						if (processingAdditionalItems.indexOf(folderId) === -1) {
							processingAdditionalItems.push(folderId)
						}
						getFiles(folderId, result.nextPageToken)
					} else {
						if (processingAdditionalItems.indexOf(folderId) !== -1) {
							processingAdditionalItems.splice(processingAdditionalItems.findIndex(f => f === folderId), 1)
						}
						checkAllRequestsEnded()
					}
				}
			}

			folders.forEach(folderId => {
				const data = DriveAPI.getFilesRequestData([folderId], corpora, null, driveId)
				batch.add(gapi.client.drive.files.list(data), {id: folderId, callback: handler} ) //getData(folderId)
			})
			batch.execute((result) => {
				batchEnded = true
				checkAllRequestsEnded()
			})
		})
	}

	static getFilesForFolders(folders: string[], corpora = 'user', driveId?: string): Promise<{files: gapi.client.drive.File[], errorsMap: any}> {
		return new Promise((resolve) => {
			let files: gapi.client.drive.File[] = []
			let errorsMap

			const getFiles = (pageToken = '') => {
				const data = DriveAPI.getFilesRequestData(folders, corpora, pageToken, driveId)
				gapi.client.drive.files.list(data).then((response: any) => {
					const result = response.result
					files = files.concat(result.files)
					if (result.nextPageToken) {
						getFiles(result.nextPageToken)
					} else {
						resolve({files, errorsMap})
					}
				}, error => {
					const result = error.result
					if (!errorsMap) {
						errorsMap = {}
					}
					folders.forEach(folderId => {
						errorsMap[folderId] = result.error
					})
					resolve({files, errorsMap})
				})
			}
			getFiles()
		})
	}

	static exportFileToHtml(fileId: string) {
		return new Promise(resolve => {
			gapi.client.drive.files.export({
				fileId: fileId,
				mimeType: 'text/html'
			}).then((result: any) => {
				resolve(result)
			})
		})
	}

	static processNestedCreation(parentFile: gapi.client.drive.File): Promise<gapi.client.drive.File> {
		return new Promise((resolve, reject) => {
			const folderData: any = {
				resource: {
					name: parentFile.name,
					mimeType: GoogleMimeTypes.FOLDER,
					properties: {
						[SpaceliProperties.SPACELI_PAGE_INDEX]: Utils.getItemIndex(parentFile)
					},
					parents: [parentFile.parents[0]]
				},
				fields: FILE_FIELDS,
				supportsAllDrives: true
			}
			gapi.client.drive.files.create(folderData).execute((newFolder: any) => {
				if (newFolder.error) {
					NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
					reject()
				} else {
					const res: any = {
						properties: {
							[SpaceliProperties.SPACELI_PAGE_INDEX]: null
						},
						appProperties: {
							[SpaceliProperties.DESCRIPTION_PAGE]: 'true'
						}
					}
					DriveAPI.moveFile(parentFile, newFolder.id, res).then(() => {
						resolve(newFolder)
					}, error => {
						const message = error?.message || SOMETHING_WENT_WRONG
						NotificationService.getInstance().showNotification(message)
						reject()
					})
				}
			})
		})
	}

	static createNewFile(
		title: string,
		mimeType: string,
		parentFile: gapi.client.drive.File,
		fileIndex: string,
		shortcutData?: {src: string, width: string, height: string, type: string}
	): Promise<{file: gapi.client.drive.File, newFolder: gapi.client.drive.File | undefined}> {
		return new Promise((resolve, reject) => {
			const id = FileUtils.isShortcutForFolder(parentFile) ? parentFile.shortcutDetails.targetId : parentFile.id
			const res: any = {
				name: title,
				mimeType: mimeType,
				properties: {
					[SpaceliProperties.SPACELI_PAGE_INDEX]: fileIndex
				},
				parents: [id]
			}

			if (mimeType === GoogleMimeTypes.EXTERNAL_SHORTCUT) {
				res.description = shortcutData.src
				res.properties.type = shortcutData.type
				if (shortcutData.width) {
					res.properties.width = shortcutData.width
				}
				if (shortcutData.height) {
					res.properties.height = shortcutData.height
				}
			}

			const data: any = {
				resource: res,
				fields: FILE_FIELDS,
				supportsAllDrives: true
			}

			if (FileUtils.isFolder(parentFile)) {
				gapi.client.drive.files.create(data).execute((file: any) => {
					resolve({file: file, newFolder: undefined})
				})
			} else {
				const response: any = {
					file: {
						error: 'File is not a folder',
						newFolder: undefined
					}
				}
				resolve(response)
				// DriveAPI.processNestedCreation(parentFile).then((newFolder: gapi.client.drive.File) => {
				// 	data.resource.parents = [newFolder.id]
				// 	gapi.client.drive.files.create(data).execute((file: any) => {
				// 		resolve({file: file, newFolder: newFolder})
				// 	})
				// }, () => {
				// 	//
				// })
			}
		})
	}

	static createFileWithResource(data: any): Promise<gapi.client.drive.File> {
		return new Promise((resolve, reject) => {
			data.fields = FILE_FIELDS
			data.supportsAllDrives = true
			gapi.client.drive.files.create(data).execute((result: any) => {
				if (result.error) {
					reject(result.error)
				} else {
					resolve(result)
				}
			})
		})
	}

	static deleteFile(file: gapi.client.drive.File) {
		return new Promise((resolve, reject) => {
			gapi.client.drive.files.update({
				fileId: file.id,
				supportsAllDrives: true,
				resource: {
					trashed: true
				}
			}).execute((result: any) => {
				resolve(result)
			})
		})
	}

	static hideFile(file: gapi.client.drive.File): Promise<any> {
		return new Promise((resolve, reject) => {
			let res
			if (file.properties && file.properties[SpaceliProperties.SPACELI]) {
				res = {
					properties: {
						[SpaceliProperties.SPACELI]: null
					}
				}
			} else {
				res = {
					appProperties: {
						[SpaceliProperties.HIDDEN]: 'true'
					}
				}
			}
			gapi.client.drive.files.update({
				fileId: file.id,
				supportsAllDrives: true,
				resource: res
			}).execute((result: any) => {
				resolve(result)
			})
		})
	}

	static search(query: string, folders: string[]) {
		return new Promise((resolve, reject) => {
			const parents = DriveAPI.getComposedParentsString(folders)
			gapi.client.drive.files.list({
				q: parents + ' and fullText contains \'' + query + '\' and trashed = false',
				fields: 'nextPageToken, ' + FILE_LIST_FIELDS,
				pageSize: 10,
				supportsAllDrives: true,
				corpora: 'allDrives',
				includeItemsFromAllDrives: true
			}).execute((result: any) => {
				if (result.error) {
					reject(result.error)
				}
				if (result.files) {
					resolve(result.files)
				}
			})
		})
	}

	// static addLink(file: gapi.client.drive.File, range: {indexStart: number, indexEnd: number}, url) {
	// 	return new Promise((resolve, reject) => {
	// 		gapi.client.docs.documents.batchUpdate({
	// 			documentId: file.id,
	// 			resource: {
	// 				requests: [{
	// 					updateTextStyle: {
	// 						range: {
	// 							startIndex: range.indexStart,
	// 							endIndex: range.indexEnd
	// 						},
	// 						textStyle: {
	// 							link: {
	// 								url: url
	// 							}
	// 						},
	// 						fields: 'link'
	// 					}
	// 				}]
	// 			}
	// 		}).then(response => {
	// 			resolve(response)
	// 		})
	// 	})
	// }

	static moveFile(file: gapi.client.drive.File, newParentId: string, resource?: any): Promise<gapi.client.drive.File> {
		return new Promise((resolve, reject) => {
			const previousParents = file.parents.join(',')
			const update: any = {
				fileId: file.id,
				fields: FILE_FIELDS,
				addParents: newParentId,
				removeParents: previousParents,
				supportsAllDrives: true
			}
			if (resource) {
				update.resource = resource
			}

			gapi.client.drive.files.update(update).execute((result: any) => {
				if (result.error) {
					reject(result.error)
				} else {
					resolve(result)
				}
			})
		})
	}

	static copyFile(file: gapi.client.drive.File, resource: any): Promise<gapi.client.drive.File> {
		return new Promise((resolve, reject) => {
			gapi.client.drive.files.copy({
				fileId: file.id,
				fields: FILE_FIELDS,
				resource: resource,
				supportsAllDrives: true
			}).execute((result: any) => {
				if (result.error) {
					reject(result.error)
				} else {
					resolve(result)
				}
			})
		})
	}

	static getAllActionItems(): Promise<gapi.client.drive.File[]> {
		return new Promise((resolve, reject) => {
			gapi.client.drive.files.list({
				q: "fullText contains 'followup:actionitems' and trashed = false",
				fields: 'nextPageToken, ' + FILE_LIST_FIELDS,
				pageSize: 1000,
				supportsAllDrives: true,
				includeItemsFromAllDrives: true
			}).execute((result: any) => {
				if (result.error) {
					reject(result.error)
				} else {
					resolve(result.files)
				}
			})
		})
	}

	static getCommentsForFiles(fileIds: string[], maxFiles?: number): Promise<IFilesCommentsMap> {
		return new Promise((resolve) => {
			const filesCommentsMap = {}
			const batch = gapi.client.newBatch()

			const handler = (response) => {
				processItem(response.id, response.result)
			}

			const processItem = (fileId, result) => {
				if (!result.error) {
					filesCommentsMap[fileId] = result.comments
				}
			}

			fileIds.forEach((fileId, i) => {
				if (maxFiles && i >= maxFiles) {
					return
				}
				batch.add(gapi.client.drive.comments.list({
					fileId: fileId,
					pageSize: 100,
					fields: '*',
				}), {id: fileId, callback: handler})
			})
			batch.execute((result) => {
				resolve(filesCommentsMap)
			})
		})
	}

	static getDrives(): Promise<gapi.client.drive.Drive[]> {
		return new Promise((resolve, reject) => {
			gapi.client.drive.drives.list({
				fields: 'drives(name,capabilities,id)'
			}).execute((response: any) => {
				if (response.error) {
					reject(response.error)
				} else {
					const drives = response.drives.filter(d => d.capabilities && d.capabilities.canEdit && d.capabilities.canAddChildren)
					resolve(drives)
				}
			})
		})
	}
}
