<template>
	<div class="file-menu">
		<div class="file-menu-item" @click="renameItem">
			<div class="file-menu-item-icon">
				<IconEdit/>
			</div>
			<div class="file-menu-item-text">Rename</div>
		</div>
		<div v-if="fileLink" class="file-menu-item" :data-clipboard-text="fileLink" ref="copyLinkButton">
			<div class="file-menu-item-icon">
				<IconLink/>
			</div>
			<div class="file-menu-item-text">Copy link</div>
		</div>
		<div class="file-menu-item" @click="deleteItem">
			<div class="file-menu-item-icon">
				<IconFileMinus/>
			</div>
			<div class="file-menu-item-text">Remove</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconEdit from 'components/icons/icons/IconEdit.vue'
	import IconFileMinus from 'components/icons/icons/IconFileMinus.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IconLink from 'components/icons/icons/IconLink.vue'
	import ClipboardJS from 'clipboard'
	import NotificationService from 'modules/notifications/NotificationService'

	@Component({
		components: {
			IconEdit,
			IconFileMinus,
			IconLink,
		}
	})

	export default class FileMenu extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@Prop()
		fileLink: string

		private clipboard: ClipboardJS

		mounted() {
			if (this.fileLink) {
				const button = this.$refs.copyLinkButton as HTMLElement
				this.clipboard = new ClipboardJS(button)
				this.clipboard.on('success', () => {
					NotificationService.getInstance().showNotification('Link copied! Share it with your team!')
				})
			}
		}

		beforeDestroy() {
			if (this.fileLink) {
				this.clipboard.destroy()
			}
		}

		private renameItem() {
			EventBus.$emit(CustomEventNames.OPEN_RENAME_FILE, this.file)
		}

		private deleteItem() {
			EventBus.$emit(CustomEventNames.OPEN_DELETE_FILE, this.file)
		}
	}
</script>

<style scoped>

</style>
