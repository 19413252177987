<template>
	<transition name="fade-page">
		<div class="space-loading" v-if="isLoading" :style="{'backgroundColor': bgColor}">
			<CircleLoader :width="4" :color="color"/>
		</div>
	</transition>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import CircleLoader from 'components/common/loaders/CircleLoader.vue'

	@Component({
		components: {
			CircleLoader,
		}
	})

	export default class Loading extends Vue {
		@Prop()
		isLoading: boolean

		@Prop({default: 'var(--white)'})
		bgColor: string

		@Prop({default: 'var(--db-20)'})
		color: string
	}
</script>

<style scoped>
	.space-loading {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--white);
		z-index: 10;
		box-sizing: border-box;
		border-radius: 16px;
	}

	.fade-page-leave-active {
		transition: opacity 0.1s ease-in;
	}

	.fade-page-leave {
		opacity: 1;
	}

	.fade-page-leave-to {
		opacity: 0;
	}
</style>
