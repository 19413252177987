import 'ClassComponentHooks'
import 'external/tooltip/Tooltip'
import 'modules/permissions/PermissionMixin'

import Vue from 'vue'
import App from './App.vue'
import {router} from 'router/Router'
import {store} from 'store/Store'

// import * as Sentry from "@sentry/browser"
// import { Integrations } from "@sentry/tracing"

import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents, {
	swipeTolerance: 15
})

// Sentry.init({
// 	dsn: 'https://29674f6dce2b4e929a38a087333d439e@o506258.ingest.sentry.io/5595738',
// 	integrations: [
// 		new Integrations.BrowserTracing(),
// 	],
//
// 	tracesSampleRate: 1.0,
// })

Vue.config.productionTip = false

new Vue({
	el: '#app',
	store,
	router,
	render: h => h(App)
})
