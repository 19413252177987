<template>
	<BaseModal ref="modal" @closed="onClosed">
		<div class="modal-content-container">
			<h2 class="modal-header">Delete topic</h2>
			<div class="modal-paragraph">You are about to delete <span style="font-weight: 600;">{{ topicName }}</span>. Spaces with this topic will not be deleted.</div>
			<div class="modal-buttons-container">
				<div class="rounded-button-medium db-50-button button-margin-4" @click="deleteTopic">Delete</div>
				<div class="rounded-button-medium db-50-text-button cancel-button" @click="close">Cancel</div>
			</div>
		</div>

		<Loading :is-loading="isLoading"/>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import DriveAPI from 'api/DriveAPI'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import Loading from 'components/common/Loading.vue'
	import NotificationService from 'modules/notifications/NotificationService'
	import {DELETE_NOT_PERMITTED, SOMETHING_WENT_WRONG, SUCCESS_DELETE_MESSAGE} from 'modules/notifications/NotificationMessages'
	import cloneDeep from 'lodash/cloneDeep'
	import {State} from 'vuex-class'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import {SpaceliProperties} from 'enums/FileProps'
	import Stat from 'modules/stat/Stat'
	import FileUtils from 'utils/FileUtils'
	import TopicsService, {ITopic} from 'components/topics/TopicsService'

	@Component({
		components: {
			Loading,
			BaseModal,
		}
	})

	export default class DeleteTopicModal extends Vue {
		private topicsService: TopicsService = TopicsService.getInstance()
		topic: ITopic
		isLoading: boolean = false

		topicName: string = ''

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_DELETE_TOPIC, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_DELETE_TOPIC, this.open)
		}

		open(topic: ITopic) {
			this.isLoading = false
			this.topic = topic
			this.topicName = this.topic.name
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		close() {
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		onClosed() {
			this.topic = undefined
			this.topicName = ''
		}

		deleteTopic() {
			this.isLoading = true

			this.topicsService.deleteTopic(this.topic.id).then(deletedTopics => {
				this.topicsService.getTopics().then(() => {
					EventBus.$emit(CustomEventNames.TOPIC_DELETED, deletedTopics)
					this.close()
				}, () => {
					this.handleError()
				})
			}, () => {
				this.handleError()
			})
		}

		private handleError() {
			NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
			this.close()
		}
	}
</script>

<style scoped>

</style>
