<template>
	<div class="file-menu">
		<div class="file-menu-item" @click="openRoadmap">
			<div class="file-menu-item-text">Roadmap</div>
		</div>

		<div class="file-menu-item" @click="openChat">
			<div class="file-menu-item-text">Give feedback</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'

	declare const $crisp: any

	const ROADMAP_URL = 'https://trello.com/b/WlG4aJJq/spaceli-roadmap'

	@Component({
		components: {
			//
		}
	})

	export default class HelpMenu extends Vue {
		@Prop()
		file: gapi.client.drive.File

		private openRoadmap() {
			window.open(ROADMAP_URL, '_blank')
		}

		private openChat() {
			$crisp.push(['do', 'chat:open'])
		}
	}
</script>

<style scoped>

</style>
