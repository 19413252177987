<template>
	<div class="circle-button-32">
		<IconQuestion/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconQuestion from 'components/icons/icons/IconQuestion.vue'

	@Component({
		components: {
			IconQuestion,
		}
	})

	export default class HelpButton extends Vue {
		@Prop()
		page: gapi.client.drive.File
	}
</script>

<style scoped>

</style>
