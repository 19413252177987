<template>
	<div class="sidebar-item">
		<div class="sidebar-item-title-container">

			<div
					class="indent-line"
					:class="{'indent-line-first': index === 0 && level - 1 === i}"
					:key="lvl"
					v-for="(lvl, i) in level">
			</div>

			<div
					class="sidebar-item-title"
					@click="topicClicked(topic.id)">
				{{ name }}
			</div>

			<IconChevron
					class="expand-icon"
					:class="{'expanded': expanded}"
					v-if="expandIconVisibility"
					@click.native="toggleExpandChildren"/>
		</div>

		<div class="children-container" v-show="expanded">
			<LightTopicItem
					v-for="(topic, i) in children"
					:index="i"
					:key="topic.id"
					:topic="topic"
					:level="level + 1"/>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import IconChevron from 'components/icons/icons/IconChevron.vue'
	import Icon3Dots from 'components/icons/icons/Icon3Dots.vue'
	import IconPlus from 'components/icons/icons/IconPlus.vue'
	import Utils from 'utils/Utils'
	import DNDService, {DraggingItemType} from 'modules/space/DNDService'
	import IconExternalLink from 'components/icons/icons/IconExternalLink.vue'
	import {ITopic} from 'components/topics/TopicsService'
	import TopicMenu from 'components/common/menus/TopicMenu.vue'
	import TopicsUtils from 'components/topics/TopicsUtils'

	@Component({
		components: {
			TopicMenu,
			IconChevron,
			Icon3Dots,
			IconPlus,
			IconExternalLink
		},
		name: 'LightTopicItem'
	})

	export default class LightTopicItem extends Vue {
		@Prop()
		topic: ITopic

		@Prop({default: 0})
		index: number

		@Prop({default: 0})
		level: number

		expanded: boolean = true

		get name(): string {
			return this.topic.name || ''
		}

		get expandIconVisibility(): boolean {
			return !!this.children.length
		}

		get children(): gapi.client.drive.File[] {
			return TopicsUtils.getTopicChildren(this.topic.id)
		}

		private topicClicked(topicId) {
			if (topicId) {
				EventBus.$emit(CustomEventNames.LIGHT_TOPIC_ITEM_SELECTED, topicId)
			}
		}

		toggleExpandChildren(e: Event) {
			e.stopPropagation()
			this.expanded = !this.expanded
		}
	}
</script>

<style scoped>
	.sidebar-item {
		color: var(--primary-color);
		cursor: pointer;
		flex: none;
		-webkit-tap-highlight-color: transparent;
	}

	.sidebar-item-title-container {
		padding: 0 8px 0 16px;
		line-height: 32px;
		color: var(--g-60);
		display: flex;
		position: relative;
		border-radius: 6px;
	}

	.sidebar-item-title-container:hover {
		color: var(--db-45);
	}

	.sidebar-item-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex-grow: 1;
		box-sizing: border-box;
		position: relative;
		padding-right: 8px;
		-webkit-tap-highlight-color: transparent;
	}

	.expand-icon svg {
		width: 20px;
		height: 20px;
	}

	.expand-icon {
		width: 20px;
		height: 20px;
		margin: 6px 0;
		flex: none;
		color: var(--db-50);
		border-radius: 4px;
		transform: rotate(-90deg);
		transition: transform 0.2s;
	}

	.expanded {
		transform: rotate(0);
	}

	.children-container {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.indent-line {
		margin: -8px 16px 4px 1px;
		border-left: 2px solid var(--g-20);
	}

	.transparent-line {
		border-left: 2px solid transparent;
	}

	.indent-line-first {
		margin-top: 4px;
	}
</style>
