import {PermissionRoles, PermissionTypes, SpaceAction, SpaceGlobalType, SpaceShareType} from 'modules/permissions/PermissionHelpers'
import {store} from 'store/Store'
import {IEmailsEditorInviteeInfo} from 'components/common/form/emaileditor/IEmailsEditorInviteeInfo'

export default class PermissionService {
	private static _instance: PermissionService

	static getInstance(): PermissionService {
		if (!PermissionService._instance) {
			PermissionService._instance = new PermissionService()
		}
		return PermissionService._instance
	}

	constructor() {}

	calcPermission(file: gapi.client.drive.File, action: SpaceAction): boolean {
		if (!file || !action || !file.capabilities) {
			return false
		}
		if (action == SpaceAction.EDIT_PAGE) {
			return file.capabilities.canEdit && !!store.state.googleUser
		} else if (action == SpaceAction.DELETE_PAGE) {
			return file.capabilities.canTrash && !!store.state.googleUser
		} else if (action == SpaceAction.CREATE_PAGE) {
			return file.capabilities.canEdit && !!store.state.googleUser
		} else if (action == SpaceAction.RENAME_PAGE) {
			return file.capabilities.canRename && !!store.state.googleUser
		} else if (action == SpaceAction.SHARE_SPACE) {
			return file.capabilities.canShare && !!store.state.googleUser
		} else if (action === SpaceAction.CAN_ADD_CHILD) {
			return file.capabilities.canAddChildren && !!store.state.googleUser
		} else if (action === SpaceAction.CAN_COMMENT) {
			return file.capabilities.canComment && !!store.state.googleUser
		}
		return false
	}

	calcSpaceShareType(permissions: gapi.client.drive.Permission[]): SpaceShareType {
		if (!permissions) {
			return SpaceShareType.PRIVATE
		}
		if (permissions.some(p => p.type == PermissionTypes.ANYONE)) {
			return SpaceShareType.ANYONE
		}
		if (permissions.some(p => p.type == PermissionTypes.DOMAIN)) {
			return SpaceShareType.DOMAIN
		}
		if (permissions.some(p => p.type == PermissionTypes.GROUP)) {
			return SpaceShareType.PEOPLE
		}
		if (permissions.some(p => p.type == PermissionTypes.USER && p.role !== 'owner')) {
			return SpaceShareType.PEOPLE
		}
		return SpaceShareType.PRIVATE
	}

	setSpaceGlobalPermission(spaceId: string, permission: SpaceGlobalType, currentPermissions: gapi.client.drive.Permission[]): Promise<void> {
		return new Promise((resolve, reject) => {
			const batch = gapi.client.newBatch()
			if (permission === SpaceGlobalType.PRIVATE) {
				currentPermissions.forEach(p => {
					if (p.type === PermissionTypes.DOMAIN || p.type === PermissionTypes.ANYONE) {
						batch.add(gapi.client.drive.permissions.delete({
							fileId: spaceId,
							permissionId: p.id,
							supportsAllDrives: true
						}))
					}
				})
			} else if (permission === SpaceGlobalType.DOMAIN) {
				currentPermissions.forEach(p => {
					if (p.type === PermissionTypes.ANYONE) {
						batch.add(gapi.client.drive.permissions.delete({
							fileId: spaceId,
							permissionId: p.id,
							supportsAllDrives: true
						}))
					}
				})
				batch.add(gapi.client.drive.permissions.create({
					fileId: spaceId,
					resource: {
						role: PermissionRoles.READER,
						type: PermissionTypes.DOMAIN,
						domain: store.state.googleUser.getHostedDomain(),
						allowFileDiscovery: true,
					},
					supportsAllDrives: true
				}))
			} else if (permission === SpaceGlobalType.ANYONE) {
				batch.add(gapi.client.drive.permissions.create({
					fileId: spaceId,
					resource: {
						role: PermissionRoles.READER,
						type: PermissionTypes.ANYONE,
						allowFileDiscovery: true,
					},
					supportsAllDrives: true
				}))
			}
			batch.execute(result => {
				result.error ? reject() : resolve()
			})
		})
	}

	shareSpaceWithUsers(spaceId: string, role: PermissionRoles, emails: IEmailsEditorInviteeInfo[], message: string = ''): Promise<gapi.client.drive.Permission[]> {
		return new Promise((resolve, reject) => {
			const batch = gapi.client.newBatch()
			emails.forEach(user => {
				batch.add(gapi.client.drive.permissions.create({
					fields: 'id,role,type,emailAddress,displayName,photoLink',
					fileId: spaceId,
					resource: {
						type: PermissionTypes.USER,
						role: role,
						emailAddress: user.email,
					},
					emailMessage: message,
					supportsAllDrives: true,
				}))
			})
			batch.execute((result: any) => {
				result.error ? reject() : resolve(result)
			})
		})
	}

	updateUserRole(spaceId: string, permissionId: string, newRole: PermissionRoles): Promise<gapi.client.drive.Permission> {
		return new Promise((resolve, reject) => {
			gapi.client.drive.permissions.update({
				fileId: spaceId,
				permissionId: permissionId,
				resource: {
					role: newRole
				},
				supportsAllDrives: true,
			}).execute((response: any) => {
				response.error ? reject() : resolve(response)
			})
		})
	}

	removePermission(spaceId: string, permissionId: string): Promise<void> {
		return new Promise((resolve, reject) => {
			gapi.client.drive.permissions.delete({
				fileId: spaceId,
				permissionId: permissionId,
				supportsAllDrives: true,
			}).execute((response: any) => {
				response.error ? reject() : resolve()
			})
		})
	}

	isMyFile(file: gapi.client.drive.File): boolean {
		return !file || !file.owners ? false : file.owners.some(owner => owner.me)
	}
}
