import { render, staticRenderFns } from "./ShareAddPeople.vue?vue&type=template&id=ac3d8d42&scoped=true"
import script from "./ShareAddPeople.vue?vue&type=script&lang=ts"
export * from "./ShareAddPeople.vue?vue&type=script&lang=ts"
import style0 from "./ShareAddPeople.vue?vue&type=style&index=0&id=ac3d8d42&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac3d8d42",
  null
  
)

export default component.exports