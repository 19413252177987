<template>
	<transition name="fade-slide-bottom">
		<div class="need-feedback-modal" v-if="opened">
			<div class="nf-text">
				Help us improve Spaceli by answering a few questions about your experience.
			</div>
			<div class="buttons-container">
				<div
						class="rounded-button-medium db-50-button button-margin-4"
						@click="openSurvey">
					Show questions
				</div>
				<div class="rounded-button-medium db-50-text-button" @click="skipSurvey">No thanks</div>
			</div>

			<IconCross class="icon-close" @click.native="hideSurvey"/>
		</div>
	</transition>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IconCross from 'components/icons/icons/IconCross.vue'
	import {Surveys} from 'modules/onboarding/Surveys'
	import SurveyService from 'modules/onboarding/SurveyService'
	import Stat from 'modules/stat/Stat'

	@Component({
		components: {
			IconCross
		}
	})

	export default class NeedFeedbackModal extends Vue {
		private opened: boolean = false

		private surveyUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSc3VOtGVcQV_fml_EgBbI2_L_Y-I__DkE_ZuvoYv7q8tExYPQ/viewform?usp=sf_link'

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_SURVEY_MODAL, this.openModal)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_SURVEY_MODAL, this.openModal)
		}

		private openModal(survey: string) {
			if (survey === Surveys.FIRST_SURVEY) {
				this.opened = true
			}
		}

		private openSurvey() {
			window.open(this.surveyUrl, '_blank')
			Stat.openSurvey()
			this.completeSurvey()
		}

		private skipSurvey() {
			Stat.skipSurvey()
			this.completeSurvey()
		}

		private completeSurvey() {
			SurveyService.getInstance().checkSurvey(Surveys.FIRST_SURVEY)
			this.hideSurvey()
		}

		private hideSurvey() {
			this.opened = false
		}
	}
</script>

<style scoped>
	.nf-text {
		font-size: 16px;
		max-width: 300px;
	}

	.need-feedback-modal {
		box-sizing: border-box;
		padding: 24px 28px;
		background-color: var(--white);
		max-width: 360px;
		border-radius: 16px;
		box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.2);
		z-index: var(--modal-z-index);
	}

	.buttons-container {
		margin-top: 20px;
		display: flex;
	}

	.icon-close {
		position: absolute;
		top: 16px;
		right: 16px;
		width: 20px;
		height: 20px;
		color: var(--g-60);
		cursor: pointer;
	}
</style>
