import { render, staticRenderFns } from "./CreateTopicModal.vue?vue&type=template&id=74949f01&scoped=true"
import script from "./CreateTopicModal.vue?vue&type=script&lang=ts"
export * from "./CreateTopicModal.vue?vue&type=script&lang=ts"
import style0 from "./CreateTopicModal.vue?vue&type=style&index=0&id=74949f01&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74949f01",
  null
  
)

export default component.exports