import {store} from 'store/Store'
import {ITopic} from 'components/topics/TopicsService'
import OrderGenerator from 'utils/OrderGenerator'
import FileUtils from 'utils/FileUtils'

export default class TopicsUtils {
	static getTopicChildren(topicId: string) {
		const topics = store.state.topics
		const filteredTopics = topics.filter(t => t.parent === topicId)
		return TopicsUtils.sortTopicsArray(filteredTopics)
	}

	static getTopicById(topicId: string) {
		const topics = store.state.topics
		return topics.find(t => t.id === topicId)
	}

	static isItemChildOf(topicId: string, parentId: string): boolean {
		const children = TopicsUtils.getTopicChildren(parentId)
		return !!children.find(child => child.id === topicId)
	}

	static sortTopicsArray(topics: ITopic[]) {
		return topics.sort((a, b) => {
			const aIndex = OrderGenerator.fromRLE(a.index)
			const bIndex = OrderGenerator.fromRLE(b.index)
			if (aIndex < bIndex) {
				return -1
			}
			if (aIndex > bIndex) {
				return 1
			}
			return 0
		})
	}

	static canEditTopic(file: gapi.client.drive.File): boolean {
		const user = store.state.googleUser
		if (!user) {
			return false
		}
		const domain = user.getHostedDomain()
		if (!domain) {
			return false
		}

		let canEditTopic = false

		if ((file.teamDriveId || file.driveId) && file.capabilities.canShare) {
			canEditTopic = true
		}

		if (!file.teamDriveId && !file.driveId) {
			if (file.ownedByMe) {
				canEditTopic = true
			} else {
				file.owners.forEach(owner => {
					if (!owner.emailAddress) {
						return
					}
					const ownerDomain = owner.emailAddress.split('@')[1]
					if (ownerDomain === domain) {
						canEditTopic = true
					}
				})
			}
		}

		return canEditTopic
	}
}
