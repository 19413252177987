import Keyboard from 'enums/Keyboard'

export function isChar(keyCode) {
	return (keyCode > 47 && keyCode < 58) || // number keys
		keyCode == 32 ||
		keyCode == 13 || // spacebar & return key(s) (if you want to allow carriage returns)
		(keyCode > 64 && keyCode < 91) || // letter keys
		(keyCode > 95 && keyCode < 112) || // numpad keys
		(keyCode > 185 && keyCode < 193) || // ;=,-./` (in order)
		(keyCode > 218 && keyCode < 223)
}

export function isArrow(keyCode: number) {
	return isUpOrDownArrow(keyCode) || isLeftOrRightArrow(keyCode)
}

export function isUpOrDownArrow(keyCode: number) {
	return keyCode == Keyboard.UP_ARROW || keyCode == Keyboard.DOWN_ARROW
}

export function isLeftOrRightArrow(keyCode: number) {
	return keyCode == Keyboard.LEFT_ARROW || keyCode == Keyboard.RIGHT_ARROW
}

export function isEnter(keyCode: number) {
	return keyCode == Keyboard.ENTER || keyCode == Keyboard.NUMPAD_ENTER
}

export function isEnterWithoutMeta(event) {
	return isEnter(event.keyCode) && !(event.ctrlKey || event.altKey || event.shiftKey || event.metaKey)
}

export function isNumber(keyCode) {
	return (
		(keyCode >= Keyboard.NUMBER_0 && keyCode <= Keyboard.NUMBER_9) ||
		(keyCode >= Keyboard.NUMPAD_0 && keyCode <= Keyboard.NUMPAD_9)
	)
}

export function isComma(e: KeyboardEvent) {
	return checkCharAs(e, [','])
}

export function isDash(e: KeyboardEvent) {
	return checkCharAs(e, ['-'])
}

export function isPeriod(e: KeyboardEvent) {
	return checkCharAs(e, ['.'])
}

export function isSingleQuote(e: KeyboardEvent) {
	return checkCharAs(e, ["'"])
}

export function isSemicolon(e: KeyboardEvent) {
	return checkCharAs(e, [';'])
}

export function isCharAs(e: KeyboardEvent, template: string | string[]): boolean {
	if (typeof template === 'string') {
		return checkCharAs(e, [template])
	} else {
		return checkCharAs(e, template)
	}
}

// COMMA = 188, // , // Warning: this keyCode also results in '<', 'б' and 'Б' chars
// DASH = 189, // -
// PERIOD = 190, // . // Warning: this keyCode also results in '>', 'ю' and 'Ю' chars
// SINGLE_QUOTE = 222, // '
//
// SEMICOLON = 186, // ;
// EQUAL = 187,
// MINUS = 189,
// SLASH = 191,
// BACKQUOTE = 192, // `
// LEFTBRACKET = 219, // [
// BACKSLASH = 220, // \
// RIGHTBRACKET = 221, // ]

function checkCharAs(e: KeyboardEvent, template: string[]): boolean {
	return template.some(char => {
		return !!e.key && e.key.toLowerCase() === char.toLowerCase()
	})
}
