<template>
	<v-popover :container="'.popover-backdrop'" :offset="4">
		<div class="user-avatar tooltip-target">
			<img class="user-avatar" @load="imgLoaded = true" :src="photoUrl" v-show="imgLoaded" referrerPolicy="no-referrer">
			<div class="initials" v-show="!imgLoaded">{{ initials }}</div>
		</div>

		<UserMenu slot="popover"/>
	</v-popover>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import UserMenu from 'components/common/menus/UserMenu.vue'
	import {State} from 'vuex-class'
	import Utils from 'utils/Utils'
  import firebase from 'firebase/compat/app'

	@Component({
		components: {UserMenu}
	})

	export default class CurrentUserAvatar extends Vue {
		@State
		firebaseUser: firebase.User

		imgLoaded: boolean = false

		get photoUrl() {
			return this.firebaseUser ? this.firebaseUser.photoURL : ''
		}

		get initials() {
			return Utils.getUserInitials(this.firebaseUser)
		}
	}
</script>

<style scoped>
	.user-avatar {
		width: 36px;
		height: 36px;
		border-radius: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--ds-35);
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
	}

	.initials {
		font-weight: 600;
	}
</style>
