<template>
	<div class="sidebar-container"
		@click="closeSidebarClickHandler"
		:class="{'container-opened-sidebar': sidebarOpened}"
		:style="{width: `${sidebarWidth}px`, 'margin-left': `${-sidebarWidth}px`}"
		v-touch:swipe.left="closeSidebarClickHandler">

		<div class="space-sidebar" :class="{'opened-sidebar': sidebarOpened}">
			<div class="space-title">
				<h2 class="flex-grow-1 text-ellipsis">{{ space.name }}</h2>
				<ShareAnimatedButton class="share-button" :file="space" v-if="calcPermission(space, SpaceAction.SHARE_SPACE)"/>
			</div>

			<SearchField class="search-field"/>

			<div class="pages-container" id="sidebarItemsContainer">
				<SpaceActions :file="space"/>

				<SidebarItem
						class="overview-item"
						:custom-name="'Overview'"
						:allow-d-n-d="false"
						:show-children="false"
						:key="space.id"
						:file="space"
						:show-buttons="false"
						:permanent-show-add="true"
						:level="0"/>

				<div class="all-pages">
					<SidebarItem
							:class="{'root-folder': isFolder(file), 'm-top-24': prevIsPage(file, index), 'm-bottom-24': nextIsPage(file, index)}"
							class="file-item"
							v-for="(file, index) in children"
							:key="file.id"
							:file="file"
							:bold-title="isFolder(file)"
							:default-expanded="isFolder(file)"
							:level="0"/>
				</div>
			</div>

			<router-link to="/dashboard" class="sidebar-bottom-action" v-if="googleUser">
				<IconArrowLeft class="icon-16 icon-new"/>
				<div class="create-new-page-title">Back to spaces</div>
			</router-link>

			<a href="https://spaceli.io" class="sidebar-bottom-action powered-by" v-if="!googleUser">
				<div class="powered-by-title">powered by</div>
				<div class="powered-by-logo">
					<LogoTextGradient class="powered-by-logo"/>
				</div>
			</a>

			<Loading :is-loading="!isLoaded" class="z-index-1"/>

			<div
					class="resizer"
					v-if="sidebarOpened"
					@mousedown="startResize"
					:class="{'resizing-sidebar': sidebarResizing}">
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue, Prop} from 'vue-property-decorator'
	import SidebarItem from 'components/space/SidebarItem.vue'
	import ShareAnimatedButton from 'components/share/ShareAnimatedButton.vue'
	import {State} from 'vuex-class'
	import IconPlus from 'components/icons/icons/IconPlus.vue'
	import IconArrowLeft from 'components/icons/icons/IconArrowLeft.vue'
	import Loading from 'components/common/Loading.vue'
	import LogoTextGradient from 'components/icons/logo/LogoTextGradient.vue'
	import IconMenu from 'components/icons/icons/IconMenu.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import FileUtils from 'utils/FileUtils'
	import SearchField from 'components/space/elements/SearchField.vue'
	import DeviceUtils from 'utils/DeviceUtils'
	import SpaceActions from 'components/space/SpaceActions.vue'
	import {TABLET_WIDTH} from 'utils/Constants'

	const MIN_WIDTH = 250
	const DEFAULT_WIDTH = 324
	const MAX_WIDTH = 500
	const LOCAL_STORAGE_ITEM = 'sidebarWidth'

	@Component({
		components: {
			SpaceActions,
			SearchField,
			Loading,
			SidebarItem,
			ShareAnimatedButton,
			IconPlus,
			IconArrowLeft,
			LogoTextGradient,
			IconMenu,
		}
	})

	export default class SpaceSidebar extends Vue {
		@State
		space: gapi.client.drive.File

		@State(state => state.spaceLoadedPartially)
		spaceLoadedPartially: boolean

		@State(state => state.spaceLoadedFully)
		spaceLoadedFully: boolean

		@State
		googleUser: gapi.auth2.GoogleUser

		private sidebarOpened: boolean = true
		private sidebarWidth: number = DEFAULT_WIDTH
		private sidebarResizing: boolean = false

		private mouseMoveWrapper = this.onMouseMove.bind(this)
		private mouseUpWrapper = this.onMouseUp.bind(this)

		private setSidebarOpened() {
			this.$store.commit('setSidebarOpened', this.sidebarOpened)
		}

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_SIDEBAR, this.openSidebar)
			EventBus.$on(CustomEventNames.TOGGLE_SIDEBAR, this.toggleSidebar)
			if (DeviceUtils.isMobile()) {
				this.sidebarOpened = false
				this.setSidebarOpened()
			} else {
				this.restoreSidebarWidth()
			}
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_SIDEBAR, this.openSidebar)
			EventBus.$off(CustomEventNames.TOGGLE_SIDEBAR, this.toggleSidebar)
		}

		get isLoaded(): boolean {
			return this.spaceLoadedFully || this.spaceLoadedPartially
		}

		get children() {
			return FileUtils.getChildrenForFolder(this.space)
		}

		private isFolder(file: gapi.client.drive.File) {
			return FileUtils.isFolder(file)
		}

		private prevIsPage(file: gapi.client.drive.File, index: number) {
			return FileUtils.isFolder(file) && index > 0 && !FileUtils.isFolder(this.children[index - 1])
		}

		private nextIsPage(file: gapi.client.drive.File, index: number) {
			return FileUtils.isFolder(file) && index < this.children.length - 2 && !FileUtils.isFolder(this.children[index + 1])
		}

		toggleSidebar() {
			this.sidebarOpened = !this.sidebarOpened
			this.setSidebarOpened()
		}

		openSidebar(e?: MouseEvent) {
			if (e) {
				e.stopPropagation()
			}
			this.sidebarOpened = true
			this.setSidebarOpened()
		}

		closeSidebarClickHandler() {
			if (document.body.clientWidth <= TABLET_WIDTH) {
				this.sidebarOpened = false
				this.setSidebarOpened()
			}
		}

		private startResize(e: MouseEvent) {
			this.sidebarResizing = true
			document.addEventListener('mousemove', this.mouseMoveWrapper)
			document.addEventListener('mouseup', this.mouseUpWrapper)
		}

		private onMouseMove(e: MouseEvent) {
			const width = e.clientX
			if (width >= MIN_WIDTH && width <= MAX_WIDTH) {
				this.sidebarWidth = e.clientX
			}
		}

		private onMouseUp(e: MouseEvent) {
			this.sidebarResizing = false
			document.removeEventListener('mousemove', this.mouseMoveWrapper)
			document.removeEventListener('mouseup', this.mouseUpWrapper)
			this.saveSidebarWidth()
		}

		private saveSidebarWidth() {
			localStorage.setItem(LOCAL_STORAGE_ITEM, this.sidebarWidth.toString())
		}

		private restoreSidebarWidth() {
			const item = localStorage.getItem(LOCAL_STORAGE_ITEM)
			if (item) {
				this.sidebarWidth = parseInt(item, 10)
			}
		}
	}
</script>

<style scoped>
	.sidebar-container {
		flex: none;
		width: 324px;
		height: 100%;
		position: relative;
		margin-left: -324px;
	}

	.space-sidebar {
		flex: none;
		width: 100%;
		height: 100%;
		background: var(--white);
		color: var(--primary-color);
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		user-select: none;
		position: relative;
		transition: all 0.25s;
	}

	.space-title {
		padding: 24px 0;
		margin: 0 36px;
		display: flex;
		align-items: center;
		flex: none;
	}

	.search-field {
		margin: 0px 28px 8px 28px;
		flex: none;
	}

	.overview-item {
		margin-bottom: 12px;
		font-weight: 600;
	}

	.space-button-icon svg {
		width: 18px;
		height: 18px;
	}

	.pages-container {
		display: flex;
		flex-direction: column;
		position: relative;
		overflow-y: auto;
		padding-top: 16px;
		padding-bottom: 24px;
		flex-grow: 1;
		scrollbar-width: none;
	}

	.all-pages {
		display: flex;
		flex-direction: column;
	}

	.pages-container::-webkit-scrollbar {
		width: 0;
	}

	.root-folder {
		padding-top: 12px;
		padding-bottom: 12px;
		cursor: pointer;
	}

	.sidebar-bottom-action {
		flex: none;
		display: flex;
		align-items: center;
		margin: 0 36px;
		padding: 23px 0 24px 0;
		line-height: 24px;
		color: var(--primary-color);
		border-top: 1px solid var(--g-20);
		cursor: pointer;
		font-weight: 600;
		font-size: 12px;
		box-sizing: border-box;
		transition: all 0.1s;
		text-decoration: none;
		-webkit-tap-highlight-color: transparent;
	}

	.sidebar-bottom-action:hover {
		color: var(--db-45);
	}

	.sidebar-bottom-action:hover .powered-by-logo{
		filter: grayscale(0);
		opacity: 1;
	}

	.powered-by {
		justify-content: center;
		color: var(--g-60);
	}

	.powered-by-title {
		margin-right: 4px;
		font-size: 12px;
	}

	.powered-by-logo {
		flex: none;
		width: 52px;
		height: 17px;
		filter: grayscale(1);
		opacity: 0.6;
		transition: all 0.1s;
	}

	.icon-new {
		margin-right: 8px;
	}

	.z-index-1 {
		z-index: 1;
	}

	.file-item:first-child {
		padding-top: 12px;
	}

	.m-top-24 {
		padding-top: 24px;
	}

	.m-bottom-24 {
		padding-bottom: 24px;
	}

	.container-opened-sidebar {
		margin-left: 0 !important;
	}

	.resizer {
		width: 8px;
		position: absolute;
		top: 0;
		right: -4px;
		height: 100%;
		z-index: var(--elements-z-index);
		cursor: col-resize;
	}

	.resizer:hover:after,
	.resizing-sidebar:after {
		background-color: var(--db-40);
	}

	.resizer::after {
		content: '';
		width: 2px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 4px;
		transition: background-color .15s .15s;
	}

	@media only screen and (max-width: 768px) {
		.sidebar-container {
			width: 100% !important;
			height: 100% !important;
			position: absolute;
			pointer-events: none;
			z-index: 1;
			transition: all 0.25s;
			margin-left: 0 !important;
		}

		.space-sidebar {
			width: 90%;
			max-width: 324px;
			position: absolute;
			top: 0;
			left: 0;
			transform: translateX(-100%);
		}

		.opened-sidebar {
			transform: translateX(0) !important;
		}

		.container-opened-sidebar {
			transform: translateX(0);
			pointer-events: auto !important;
			background-color: rgba(33, 38, 42, 0.92);
		}
	}

	@media only screen and (max-width: 480px) {
		.share-button {
			display: none;
		}
	}
</style>
