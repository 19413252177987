<template>
	<div class="component-container">
		<div class="document-view-content" ref="documentContent" id="documentContent" v-html="html" v-show="!emptyDoc"></div>

		<div class="empty-doc-container" v-if="emptyDoc && !calcPermission(file, SpaceAction.EDIT_PAGE)">
			<ImgEmpty class="img-empty"/>
			<div class="paragraph-text empty-text">Looks like the page is empty.</div>
		</div>

		<div class="empty-doc-container" v-if="emptyDoc && calcPermission(file, SpaceAction.EDIT_PAGE)">
			<ImgEmpty class="img-empty"/>
			<div class="paragraph-text empty-text">Looks like the page is empty. Open editor and share your knowledge!</div>
			<div class="modal-buttons-container">
				<div class="rounded-button-medium db-50-button button-margin-4" @click="openEditor">Open editor</div>
			</div>
		</div>

		<PageFooter :file="file" v-if="file.lastModifyingUser && !emptyDoc" :show-last-update="showLastUpdate"/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import DriveAPI from 'api/DriveAPI'
	import PageFooter from 'components/space/elements/PageFooter.vue'
	import EditByEditor from 'components/space/buttons/EditByEditor.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import RoutingUtils from 'utils/RoutingUtils'
	import TableOfContents from 'components/space/elements/TableOfContents.vue'
	import ImgEmpty from 'components/icons/icons/ImgEmpty.vue'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import Utils from 'utils/Utils'
  import {ErrorsCodes, ErrorMessages} from "../../../../utils/ErrorHandlingUtils";

	@Component({
		components: {
			TableOfContents,
			PageFooter,
			EditByEditor,
			ImgEmpty,
		}
	})
	export default class DocumentComponent extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@Prop({default: true})
		showLastUpdate: boolean

		html: string = ''
		emptyDoc: boolean = false

		private clickWrapper = this.onClick.bind(this)

		update(): void {
			if (this.file && this.file.id) {
				this.getFileHtml()
			}
		}

		loaded(): void {
			EventBus.$emit(CustomEventNames.PAGE_LOADED)
		}

		mounted() {
			this.getFileHtml()
			const doc = this.$refs.documentContent as HTMLElement
			doc.addEventListener('click', this.clickWrapper)
		}

		beforeDestroy() {
			const doc = this.$refs.documentContent as HTMLElement
			doc.removeEventListener('click', this.clickWrapper)
		}

		@Watch('file')
		onFileChanged(val: string, oldVal: string) {
			if (val !== oldVal) {
				this.getFileHtml()
			}
		}

		private getFileHtml() {
			this.emptyDoc = false
			if (this.file.mimeType === GoogleMimeTypes.DOCS || this.file.mimeType === GoogleMimeTypes.DOCS_OLD) {
				this.getHtmlForGoogleDocs()
			} else {
				this.getHtmlForOtherFile()
			}
		}

		private getHtmlForGoogleDocs() {
			DriveAPI.exportFileToHtml(this.file.id).then((result: any) => {
				if (result.error) {
					if (result.error.code === ErrorsCodes.NOT_FOUND) {
						EventBus.$emit(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, ErrorMessages.NOT_FOUND)
					} else {
						EventBus.$emit(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, ErrorMessages.SOMETHING_WENT_WRONG, true)
					}
					return
				}
				this.processContent(result.body)
			})
		}

		private processContent(html: string) {
			this.html = this.processHtml(html)
			this.checkForEmptyDoc()
			this.loaded()
			setTimeout(() => {
				this.$emit('content-updated')
				// this.refreshToc()
			})
		}

		private getHtmlForOtherFile() {
			Utils.getHtmlForOtherFile(this.file.id).then(html => {
				// console.log(html)
				// const iframe = this.$refs.iframe as HTMLIFrameElement
				// const doc = iframe.contentWindow.document
				// doc.open()
				// doc.write(html)
				// doc.close()
				// inlineCss(html, {url: ''}).then((ihtml => {
				// 	this.processContent(ihtml)
				// }))
				// iframe.onload = () => {
				// 	iframe.style.height = doc.body.scrollHeight + 'px';
				// }
			}, () => {
				//todo
			})
		}

		private checkForEmptyDoc() {
			const tempDoc = document.createElement('div')
			tempDoc.innerHTML = this.html
			if (!tempDoc.innerText) {
				this.emptyDoc = true
			}
      console.log('checkForEmptyDoc', this.emptyDoc)
		}

		private refreshToc() {
			const toc = this.$refs.toc as any
			if (toc) {
				toc.refresh()
			}
		}

		private processHtml(html) {
			html = html.replace(/(line-height:)(\d+\.?\d*|\.\d+)/g, function (match, p1, p2) {
				return p1 + (p2 * 1.2).toFixed(2)
			}).replace(/(<li[\s\S]+?)(margin-left:)(\d+\.?\d*|\.\d+)(\w+)/g, function (match, p1, p2, p3, p4) {
				return p1 + p2 + (p3 - 18) + p4
			}).replace(/href="mailto:/, 'class="google-user-mention" href="mailto:')
			return html
		}

		private openEditor() {
			EventBus.$emit(CustomEventNames.OPEN_FILE_EDITOR, this.file)
		}

		private onClick(e: MouseEvent) {
			const target = e.target as HTMLElement
			if (!target) {
				return
			}
			const closestLink = target.closest('a')
			if (!closestLink) {
				return
			}
			const href = closestLink.href || ''

			if (href) {
				e.preventDefault()
				const originalUrl = href.replace(/https:\/\/www.google.com\/url\?q=/g, '')
				const url = new URL(originalUrl)
				if (url.host === 'docs.google.com' || url.host === 'drive.google.com') {
					const fileId = this.getDocId(originalUrl)
					if (fileId) {
						RoutingUtils.openPage(fileId)
					} else {
						window.open(href, '_blank')
					}
				} else {
					window.open(href, '_blank')
				}
			}
		}

		getDocId(url): string | null {
			url = url.replace(/https:\/\/docs\.google\.com\/[^"]+\/d\//g, '')
				.replace(/https:\/\/drive\.google\.com\/drive\/folders\//g, '')
				.replace(/https:\/\/drive\.google\.com\/open\?id%3D/g, '')
				.replace(/https:\/\/drive\.google\.com\/file\/d\//g, '')

			const res = url.match(/^[^/&]+/)
			return res ? res[0] : null
		}
	}
</script>

<style scoped>
	.component-container {
		width: 100%;
	}

	.document-view-content {
		width: 100%;
		max-width: 800px;
		user-select: text;
		margin-bottom: 32px;
		flex-shrink: 0;
	}

	.empty-doc-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
		margin-bottom: 128px;
	}

	.img-empty {
		color: var(--g-60);
		margin-bottom: 24px;
	}

	.empty-text {
		max-width: 210px;
		text-align: center;
	}

	@media only screen and (max-width: 480px) {
		.modal-buttons-container {
			display: none;
		}
	}
</style>
