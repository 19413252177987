import DriveAPI from 'api/DriveAPI'
import {store} from 'store/Store'
import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'

export interface IFilesCommentsMap {
	[key: string]: gapi.client.drive.Comment[]
}

const MAX_FILES = 10

export default class TasksMentionsService {
	private static _instance: TasksMentionsService

	private spaceActionFiles: gapi.client.drive.File[]
	private docsActionItemsMap: IFilesCommentsMap

	isInitForCurrentSpace: boolean = false
	onGetActionItemFilesForSpace = new signals.Signal<() => void>()

	static getInstance(): TasksMentionsService {
		if (!TasksMentionsService._instance) {
			TasksMentionsService._instance = new TasksMentionsService()
		}
		return TasksMentionsService._instance
	}

	constructor() {}

	getActionItemFilesForSpace() {
		this.dropTasksState()
		DriveAPI.getAllActionItems().then(resp => {
			const filteredItems = this.filterActionItemsWithSpaceFiles(resp)
			this.setSpaceActionFilesState(filteredItems)
			this.spaceActionFiles = filteredItems
			this.isInitForCurrentSpace = true
			this.onGetActionItemFilesForSpace.dispatch()
		}, error => {
			console.error(error)
		})
	}

	dropTasksState() {
		this.setSpaceActionFilesState([])
		this.spaceActionFiles = undefined
		this.docsActionItemsMap = undefined
	}

	getActionFilesCommentsMap(): Promise<IFilesCommentsMap> {
		return new Promise<IFilesCommentsMap | undefined>((resolve) => {
			if (!this.spaceActionFiles || !this.spaceActionFiles.length) {
				resolve(undefined)
				return
			}
			if (this.docsActionItemsMap) {
				resolve(this.docsActionItemsMap)
			} else {
				const fileIds = store.state.spaceActionFiles.map(file => file.id)
				DriveAPI.getCommentsForFiles(fileIds, MAX_FILES).then((result: IFilesCommentsMap) => {
					this.docsActionItemsMap = result
					resolve(this.docsActionItemsMap)
				})
			}
		})
	}

	private filterActionItemsWithSpaceFiles(actionItems: gapi.client.drive.File[]): gapi.client.drive.File[] {
		const spaceFiles = store.state.spaceFiles
		const spaceFilesIds: string[] = Object.keys(spaceFiles).map(id => {
			if (spaceFiles[id].mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT && spaceFiles[id].shortcutDetails.targetMimeType !== GoogleMimeTypes.FOLDER) {
				return spaceFiles[id].shortcutDetails.targetId
			} else {
				return id
			}
		})
		return actionItems.filter(item => spaceFilesIds.includes(item.id))
	}

	private setSpaceActionFilesState(files: gapi.client.drive.File[]) {
		store.commit('setSpaceActionFiles', files)
	}
}
