import AppService from 'modules/app/AppService'
import DriveAPI from 'api/DriveAPI'
import GoogleApiClient from 'modules/GoogleApiClient'
import AuthService from 'modules/app/AuthService'
import {store} from 'store/Store'
import {SpaceliProperties, SpaceliPropertyValues} from 'enums/FileProps'
import {SpaceCorpora} from 'modules/permissions/PermissionHelpers'

export default class DashboardService {
	private static _instance: DashboardService
	private appService: AppService = AppService.getInstance()
	private authService: AuthService = AuthService.getInstance()

	spaces: gapi.client.drive.File[] = []

	static getInstance(): DashboardService {
		if (!DashboardService._instance) {
			DashboardService._instance = new DashboardService()
		}
		return DashboardService._instance
	}

	private setSpacesState() {
		store.commit('setSpaces', this.spaces)
	}

	constructor() {
		//
	}

	getSpaces(force = true): Promise<void> {
		return new Promise((resolve, reject) => {
			this.spaces = []
			this.setSpacesState()

			if (!this.appService.isInit || !this.authService.googleUser) {
				reject()
			}

			if (!force && this.spaces.length) {
				resolve()
			}

			const q = 'properties has {key=\'' + SpaceliProperties.SPACELI + '\' and value=\'' + SpaceliPropertyValues.FOLDER + '\'} and trashed = false'

			const handler = (files: gapi.client.drive.File[]) => {
				files.forEach(f => {
					if (!this.spaces.find(s => s.id === f.id)) {
						this.spaces.push(f)
					}
				})
				this.setSpacesState()
				resolve()
			}

			DriveAPI.getFiles(q).then(handler, error => {
				reject(error)
			})

			if (this.authService.googleUser.getHostedDomain()) {
				DriveAPI.getFiles(q, SpaceCorpora.DOMAIN).then(handler, error => {
					reject(error)
				})
			}
		})
	}

	showFolderPicker(callback) {
		const picker = GoogleApiClient.getInstance().buildPicker('application/vnd.google-apps.folder')
		picker.setCallback(callback)
		picker.setVisible(true)
	}

	markFolderAsRoot(folderId, topic?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			DriveAPI.markFolderAsRoot(folderId, topic).then(file => {
				if (file && file.id) {
					resolve(folderId)
				} else {
					reject()
				}
			})
		})
	}

	createNewSpace(name: string, topic?: string, driveId?: string): Promise<string> {
		return new Promise((resolve, reject) => {
			DriveAPI.createNewSpace(name, topic, driveId).then(file => {
				if (file && file.id) {
					resolve(file.id)
				} else {
					reject()
				}
			})
		})
	}

	updateSpace(space: gapi.client.drive.File) {
		const index = this.spaces.findIndex(s => s.id === space.id)
		if (index !== -1) {
			this.spaces[index] = space
			this.setSpacesState()
		}
	}
}
