<template>
	<div class="file-view">
		<div class="file-content-view">
			<div class="file-header-container">
				<h1 class="file-header">{{ file.name }}</h1>
				<div class="header-buttons-container">
					<AddPageButton :page="file"/>
				</div>
			</div>
			<div class="folder-description">{{ file.description }}</div>

<!--			<div v-for="page in descriptionPages" :key="page.id">-->
<!--				<DocumentComponent :file="page" :show-last-update="false"/>-->
<!--			</div>-->

			<div class="overview-content" v-if="currentChildren.length && !isError">
				<div class="toc-container">
					<div class="items-container">
						<div class="contents-items-container" v-if="rootFiles.length">
							<FileRow class="content-item" :file="file" v-for="file in rootFiles" :key="file.id" :show-last-update="false" @click="selectItem"/>
						</div>

						<div
								class="contents-items-container"
								v-for="folder in rootFolders"
								:key="folder.id">
							<h4 class="folder-title" @click="selectItem(folder)">{{ folder.name }}</h4>
							<div class="small-folder-description">{{ folder.description }}</div>
							<FileRow
									class="content-item"
									:file="file"
									v-for="file in getChildrenForFolder(folder)"
									:key="file.id"
									:show-last-update="false"
									@click="selectItem"
							/>
							<div class="empty-child-folder" v-if="!getChildrenForFolder(folder).length">
								Looks like it's empty. Or data is not loaded yet.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="empty-container" v-if="!currentChildren.length && !isError && calcPermission(file, SpaceAction.EDIT_PAGE)">
				<h3 class="empty-space-header">No pages here...</h3>
				<div class="paragraph-text empty-space-desc">Looks like it's empty. Get started with a page.</div>

				<div class="elements-container">
					<div class="add-element" @click="importByMove">
						<IconMoveFrom48 class="add-element-icon"/>
						<div class="add-element-text-container">
							<div class="add-element-title">Import by move</div>
							<div class="add-element-desc">Move a file from other folder</div>
						</div>
					</div>

					<div class="add-element" @click="importByCopy">
						<IconCopy48 class="add-element-icon"/>
						<div class="add-element-text-container">
							<div class="add-element-title">Import by copy</div>
							<div class="add-element-desc">Copy a file from other folder</div>
						</div>
					</div>

					<div class="add-element" @click="importAsShortcut">
						<IconShortcut48 class="add-element-icon"/>
						<div class="add-element-text-container">
							<div class="add-element-title">Import as shortcut</div>
							<div class="add-element-desc">Create a shortcut to a file or folder</div>
						</div>
					</div>
				</div>

				<div class="elements-container">
					<div
							class="add-element"
							:key="element.title"
							v-for="element in googleFiles"
							@click="createItem(element)">
						<img class="add-element-icon" :src="element.icon">
						<div class="add-element-text-container">
							<div class="add-element-title">{{ element.title }}</div>
							<div class="add-element-desc">{{ element.desc }}</div>
						</div>
					</div>
				</div>

				<div class="elements-container">
					<div
							class="add-element"
							:key="element.title"
							v-for="element in externalFiles"
							@click="createItem(element)">
						<img class="add-element-icon" :src="element.icon">
						<div class="add-element-text-container">
							<div class="add-element-title">{{ element.title }}</div>
							<div class="add-element-desc">{{ element.desc }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="empty-doc-container" v-if="!currentChildren.length && !isError && !calcPermission(file, SpaceAction.EDIT_PAGE)">
				<ImgEmpty class="img-empty"/>
				<div class="paragraph-text empty-text">Looks like it's empty.</div>
			</div>

			<div class="empty-doc-container" v-if="isError">
				<ImgEmpty class="img-empty"/>
				<div class="paragraph-text empty-text">{{ errorMessage }}</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import {ISpacePage} from 'components/space/pages/ISpacePage'
	import PageFooter from 'components/space/elements/PageFooter.vue'
	import EditByEditor from 'components/space/buttons/EditByEditor.vue'
	import ViewInDrive from 'components/space/buttons/ViewInDrive.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import Utils from 'utils/Utils'
	import FileRow from 'components/common/elements/FileRow.vue'
	import RoutingUtils from 'utils/RoutingUtils'
	import {ExternalFileDefs, GoogleFileDefs, IFileDef} from 'filedefs/FileDefs'
	import ImgEmpty from 'components/icons/icons/ImgEmpty.vue'
	import IconMoveFrom48 from 'components/icons/icons/IconMoveFrom48.vue'
	import IconCopy48 from 'components/icons/icons/IconCopy48.vue'
	import FileUtils from 'utils/FileUtils'
	import DocumentComponent from 'components/space/pages/components/DocumentComponent.vue'
	import AddPageButton from 'components/space/buttons/AddPageButton.vue'
	import IconShortcut48 from 'components/icons/icons/IconShortcut48.vue'
	import SpaceService, {IErrorsMap} from 'modules/space/SpaceService'
	import {SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'

	const moment = require('moment')

	@Component({
		components: {
			AddPageButton,
			DocumentComponent,
			FileRow,
			PageFooter,
			EditByEditor,
			ViewInDrive,
			ImgEmpty,
			IconMoveFrom48,
			IconCopy48,
			IconShortcut48,
		}
	})

	export default class HomePage extends Vue implements ISpacePage {
		@Prop()
		file: gapi.client.drive.File

		@State(state => state.errorsMap)
		errorsMap: IErrorsMap

		@State(state => state.spaceLoadedPartially)
		spaceLoadedPartially: boolean

		@State(state => state.spaceLoadedFully)
		spaceLoadedFully: boolean

		@State
		googleUser: gapi.auth2.GoogleUser

		private googleFiles = GoogleFileDefs
		private externalFiles = ExternalFileDefs

		update(): void {
			//
		}

		loaded(): void {
			this.sendPageLoaded()
		}

		mounted() {
			this.checkForLoaded()
		}

		@Watch('file')
		onFileChanged(val: gapi.client.drive.File, oldVal: gapi.client.drive.File) {
			if (val !== oldVal) {
				this.checkForLoaded()
			}
		}

		@Watch('spaceLoadedPartially')
		onSpaceLoadedPartially() {
			this.checkForLoaded()
		}

		@Watch('spaceLoadedFully')
		onSpaceLoadedFully() {
			this.checkForLoaded()
		}

		private isEmptyFolder() {
			return !this.currentChildren.length
		}

		private checkForLoaded() {
			if (this.isEmptyFolder()) {
				if (this.spaceLoadedFully) {
					this.fetchFolderFiles()
				}
			} else {
				this.loaded()
			}
		}

		private fetchFolderFiles() {
			SpaceService.getInstance().updateSpaceFilesForFolder(this.file, 2).then(() => {
				this.loaded()
			})
		}

		// get descriptionPages(): gapi.client.drive.File[] {
		// 	return FileUtils.getDescriptionPages(this.file)
		// }

		get isError(): boolean {
			return !!this.errorsMap[this.file.id]
		}

		get errorMessage(): string {
			const error = this.errorsMap[this.file.id]
			return error.message || SOMETHING_WENT_WRONG
		}

		get rootFolders(): gapi.client.drive.File[] {
			return FileUtils.getChildrenForFolder(this.file).filter(f => FileUtils.isFolder(f))
		}

		get rootFiles(): gapi.client.drive.File[] {
			return FileUtils.getChildrenForFolder(this.file).filter(f => !FileUtils.isFolder(f))
		}

		get currentChildren(): gapi.client.drive.File[] {
			return FileUtils.getChildrenForFolder(this.file)
		}

		private getChildrenForFolder(parent: gapi.client.drive.File): gapi.client.drive.File[] {
			return FileUtils.getChildrenForFolder(parent)
		}

		private openCreateFile() {
			EventBus.$emit(CustomEventNames.OPEN_CREATE_FILE, this.file)
		}

		private selectItem(file: gapi.client.drive.File) {
			RoutingUtils.openPage(file.id)
		}

		private createItem(item: IFileDef) {
			EventBus.$emit(CustomEventNames.OPEN_CREATE_FILE, this.file, item)
		}

		private itemSelected(file: gapi.client.drive.File) {
			RoutingUtils.openPage(file.id)
		}

		private sendPageLoaded() {
			EventBus.$emit(CustomEventNames.PAGE_LOADED)
		}

		private importByMove() {
			Utils.importFileByMove(this.file, (finished: boolean) => {
				if (!finished) {
					this.$emit('show-loading')
				} else {
					this.sendPageLoaded()
				}
			})
		}

		private importByCopy() {
			Utils.importFileByCopy(this.file, (finished: boolean) => {
				if (!finished) {
					this.$emit('show-loading')
				} else {
					this.sendPageLoaded()
				}
			})
		}

		private importAsShortcut() {
			Utils.importAsShortcut(this.file, (finished: boolean) => {
				if (!finished) {
					this.$emit('show-loading')
				} else {
					this.sendPageLoaded()
				}
			})
		}
	}
</script>

<style scoped>
	.file-content-view {
		padding: 0 72px 64px 72px;
	}

	.overview-content {
		display: flex;
	}

	.content-element-container-header {
		margin-bottom: 12px;
	}

	.folder-item {
		margin-left: -8px;
	}

	.file-header-container {
		margin-bottom: 16px;
	}

	.folder-description {
		color: var(--g-60);
		margin-bottom: 20px;
		white-space: pre-line;
	}

	.empty-container {
		display: flex;
		flex-direction: column;
		user-select: none;
	}

	.elements-container {
		margin: 0px;
		display: grid;
		padding: 0 0 48px 0;
		gap: 16px;
		grid-template-columns: repeat(3, 1fr);
		position: relative;
	}

	.add-element {
		background-color: var(--g-10);
		border-radius: 16px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 8px 10px;
		border: 2px solid var(--g-10);
		transition: border-color 0.1s;
		cursor: pointer;
	}

	.add-element:hover {
		border-color: var(--db-50);
	}

	.add-element-icon {
		width: 48px;
		height: 48px;
		margin-right: 8px;
	}

	.add-element-text-container {
		display: flex;
		flex-direction: column;
	}

	.add-element-title {
		font-weight: 600;
		color: var(--primary-color);
	}

	.add-element-desc {
		color: var(--g-60);
	}

	.empty-space-header {
		margin-bottom: 8px;
	}

	.empty-space-desc {
		margin-bottom: 32px;
	}

	.empty-doc-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
		margin-bottom: 128px;
	}

	.img-empty {
		color: var(--g-60);
		margin-bottom: 24px;
	}

	.empty-text {
		max-width: 210px;
		text-align: center;
	}

	.items-container {
		margin: 0px;
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(auto-fill, 250px);
		position: relative;
	}

	.contents-items-container {
		padding-bottom: 32px;
	}

	.content-item {
		margin-left: -12px;
	}

	.content-item:hover {
		background-color: var(--g-20);
	}

	.folder-title {
		cursor: pointer;
	}

	.folder-title:hover {
		text-decoration: underline;
	}

	.small-folder-description {
		margin-top: 4px;
		margin-bottom: 8px;
		color: var(--g-60);
		white-space: pre-line;
	}

	.toc-container {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.empty-child-folder {
		max-width: 200px;
		color: var(--g-60);
	}

	@media only screen and (max-width: 768px) {
		.elements-container {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media only screen and (max-width: 480px) {
		.header-buttons-container {
			display: none;
		}

		.file-content-view {
			padding: 0 32px;
		}

		.elements-container {
			display: none;
		}
	}
</style>
