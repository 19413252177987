<template>
	<div class="share-component">
		<EmailEditor ref="emailEditor" @add-users="addUsers" :loading="sendingInvites"/>

		<div class="users-container">
			<div
					class="user"
					:key="user.id"
					v-for="user in users">
				<UserAvatar :user="user" class="user-avatar" :size="36"/>
				<div class="user-info-container">
					<div class="user-name">{{ user.displayName }}</div>
					<div class="user-email">{{ user.emailAddress }}</div>
				</div>

				<v-popover :container="'.popover-backdrop'" :disabled="isOwner(user)">
					<div class="tooltip-target user-role">
						<div
								class="user-role-title"
								:class="{'owner': isOwner(user)}">
							{{ getUserRoleTitle(user) }}
						</div>
						<IconChevron v-if="!isOwner(user)" class="icon-chevron"/>
					</div>

					<ShareMenu slot="popover" @action="updateUserRole($event, user)"/>
				</v-popover>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {IInviteePermission, PermissionRoles, PermissionTypes} from 'modules/permissions/PermissionHelpers'
	import UserAvatar from 'components/common/elements/UserAvatar.vue'
	import ShareMenu from 'components/share/ShareMenu.vue'
	import IconChevron from 'components/icons/icons/IconChevron.vue'
	import EmailEditor from 'components/common/form/emaileditor/EmailEditor.vue'
	import PermissionService from 'modules/permissions/PermissionService'
	import NotificationService from 'modules/notifications/NotificationService'
	import {SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {IEmailsEditorInviteeInfo} from 'components/common/form/emaileditor/IEmailsEditorInviteeInfo'
	import Utils from 'utils/Utils'
	import Stat from 'modules/stat/Stat'

	@Component({
		components: {
			EmailEditor,
			ShareMenu,
			UserAvatar,
			IconChevron,
		}
	})

	export default class ShareAddPeople extends Vue {
		@Prop()
		fileId: string

		@Prop()
		permissions: gapi.client.drive.Permission[]

		sendingInvites: boolean = false

		focusInput() {
			const emailEditor = this.$refs.emailEditor as any
			emailEditor.focusInput()
		}

		get users() {
			if (!this.permissions) {
				return []
			}
			return this.permissions.filter(p => p.type == PermissionTypes.USER)
		}

		private isOwner(user: gapi.client.drive.Permission) {
			return user.role === PermissionRoles.OWNER
		}

		private getUserRoleTitle(user: gapi.client.drive.Permission) {
			if (user.role === PermissionRoles.OWNER) {
				return 'Owner'
			} else if (
				user.role === PermissionRoles.ORGANIZER ||
				user.role === PermissionRoles.FILE_ORGANIZER ||
				user.role === PermissionRoles.WRITER
			) {
				return 'Can organize'
			} else if (
				user.role === PermissionRoles.COMMENTER ||
				user.role === PermissionRoles.READER
			) {
				return 'Can read'
			}
		}

		private updateUserRole(newRole: IInviteePermission, user: gapi.client.drive.Permission) {
			if (newRole.value && !user.deleted) {
				if (newRole.value !== 'remove') {
					const backupRole = user.role
					user.role = newRole.value
					PermissionService.getInstance().updateUserRole(this.fileId, user.id, newRole.value).then((response) => {
						NotificationService.getInstance().showNotification('Permissions updated')
					}, () => {
						user.role = backupRole
						NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
					})
				} else {
					const permissionIndex = this.permissions.findIndex(p => p.id == user.id)
					this.permissions.splice(permissionIndex, 1)
					PermissionService.getInstance().removePermission(this.fileId, user.id).then(() => {
						NotificationService.getInstance().showNotification('Permissions updated')
						EventBus.$emit(CustomEventNames.FILE_PERMISSIONS_UPDATED, this.fileId)
					}, () => {
						NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
					})
				}
			}
		}

		private addUsers(emails: IEmailsEditorInviteeInfo[], permission: IInviteePermission) {
			this.sendingInvites = true
			const message: string = 'I have created a knowledge base in Spaceli. Please join me via link: ' + Utils.getSpaceUrl(this.fileId)
			PermissionService.getInstance().shareSpaceWithUsers(this.fileId, permission.value as PermissionRoles, emails, message).then(resp => {
				Object.keys(resp).forEach(key => {
					if (!resp[key].error) {
						const invite = resp[key].result
						if (!this.permissions.find(p => p.emailAddress === invite.emailAddress)) {
							this.permissions.unshift(invite)
						}
					}
				})
				NotificationService.getInstance().showNotification('Invitations sent')
				EventBus.$emit(CustomEventNames.FILE_PERMISSIONS_UPDATED, this.fileId)
				this.sendingInvites = false
				Stat.inviteUser(emails.length)
			}, () => {
				this.sendingInvites = false
				NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
			})
		}
	}
</script>

<style scoped>
	.share-component {
		min-width: 100%;
		display: flex;
		flex-direction: column;
		user-select: text;
	}

	.users-container {
		display: flex;
		flex-direction: column;
		margin-top: 24px;
	}

	.user {
		display: flex;
		margin-bottom: 12px;
		align-items: center;
	}

	.user-avatar {
		margin-right: 12px;
	}

	.user-info-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-grow: 1;
	}

	.user-email {
		color: var(--g-60);
	}

	.user-role {
		display: flex;
		align-items: center;
		color: var(--db-45);
		cursor: pointer;
		user-select: none;
	}

	.owner {
		color: var(--g-60);
		margin-right: 4px;
		cursor: default;
	}

	.icon-chevron,
	.icon-chevron svg {
		width: 16px;
		height: 16px;
		margin-top: 3px;
		margin-left: 2px;
	}
</style>
