import {GoogleScopes} from 'enums/GoogleScopes'

export const GOOGLE_CONFIG = {
	apiKey: "AIzaSyA6V3p7sj8w7El_qB1H18MwZVtpiGQGwq8",
	authDomain: "spaceli.io",
	databaseURL: "https://spaceli.firebaseio.com",
	projectId: "spaceli",
	storageBucket: "spaceli.appspot.com",
	messagingSenderId: "618650423333",
	appId: "1:618650423333:web:550c6cd701aa5f770fefcc",
	measurementId: "G-LZ130S71GM",

	clientId: "618650423333-4hjpdjd6h01gfnnnf7dnaprjr8uk26to.apps.googleusercontent.com",
	scopes: [
		"email",
		"profile",
		GoogleScopes.DRIVE,
	],
	discoveryDocs: [
		'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
		// 'https://docs.googleapis.com/$discovery/rest?version=v1',
		// 'https://www.googleapis.com/discovery/v1/apis/driveactivity/v2/rest',
		// 'https://www.googleapis.com/discovery/v1/apis/people/v1/rest',
		'https://people.googleapis.com/$discovery/rest?version=v1',
	]
}
