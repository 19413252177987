<template>
	<div class="input-container">
		<div class="field-title">{{ fieldTitle }}</div>
		<textarea
			ref="inputfield"
			:value="value"
			v-on:input="$emit('input', $event.target.value)"
			:style="{'height': height + 'px'}"
			:placeholder="placeholder">
		</textarea>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'

	@Component({})

	export default class TextareaWhite extends Vue {
		@Prop()
		value: string

		@Prop()
		placeholder: string

		@Prop()
		fieldTitle: string

		@Prop({default: 58})
		height: number

		focusInput() {
			const input = this.$refs.inputfield as any
			input.focus()
		}
	}
</script>

<style scoped>
	.input-container {
		width: 100%;
		position: relative;
		padding: 0px 20px;
		box-sizing: border-box;
		border-radius: 16px;
		border: 1px solid var(--g-20);
		display: flex;
		align-items: center;
	}

	.field-title {
		position: absolute;
		top: -8px;
		left: 20px;
		font-size: 14px;
		line-height: 16px;
		color: var(--g-60);
		padding: 0 12px 0 8px;
		background-color: var(--white);
	}

	textarea {
		font-family: 'Jost', Helvetica, Arial, sans-serif;
		height: 100%;
		width: 100%;
		border: none;
		font-size: 14px;
		outline: none;
		box-sizing: border-box;
		background-color: transparent;
		resize: none;
		padding: 18px 0;
	}

	textarea::placeholder {
		color: var(--g-70);
	}
</style>
