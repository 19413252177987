<template>
	<div class="login-view">
		<a href="https://spaceli.io" class="logo">
			<LogoTextGradient/>
		</a>
		<h1 class="color-white login-header">Sign in</h1>

		<div class="sign-in-button" @click="signIn()">
			<SignInButton class="sign-in-button-icon"></SignInButton>
			<div class="sign-in-button-text">Sign in with Google</div>
		</div>

		<div class="login-description">
			<div class="login-description-item">
				<IconCheck class="check-icon"/>
				<div class="login-description-text">Verified and approved by Google</div>
			</div>
			<div class="login-description-item">
				<IconCheck class="check-icon"/>
				<div class="login-description-text">Spaceli server cannot access your files</div>
			</div>

			<div class="login-description-item permission-item">
				<IconLocked class="check-icon lock-icon"/>
				<a href="https://spaceli.io/permissions" class="permission-text">Read more about permissions</a>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import AuthService from 'modules/app/AuthService'
	import IconCheck from 'components/icons/icons/IconCheck.vue'
	import IconLocked from 'components/icons/icons/IconLocked.vue'
	import SignInButton from 'components/icons/SignInButton.vue'
	import RoutingUtils from 'utils/RoutingUtils'
	import LogoTextGradient from 'components/icons/logo/LogoTextGradient.vue'

	@Component({
		components: {
			SignInButton,
			IconCheck,
			LogoTextGradient,
			IconLocked,
		},
	})

	export default class Login extends Vue {
		mounted() {
      AuthService.getInstance().firebaseUser ? RoutingUtils.goToDashboard() : AuthService.getInstance().authStateChanged.add(this.onAuthStateChanged, this)
		}

		beforeDestroy() {
      AuthService.getInstance().authStateChanged.remove(this.onAuthStateChanged, this)
		}

		onAuthStateChanged(isSignedIn: boolean) {
			if (isSignedIn) {
				RoutingUtils.goToDashboard()
			}
		}

    signIn(): void {
      AuthService.getInstance().signIn()
    }
	}
</script>

<style scoped>
	.login-view {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: var(--ds-50);
		user-select: none;
	}

	.logo {
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--white);
		margin-left: 28px;
		position: absolute;
		top: 0;
		left: 0;
		cursor: pointer;
	}

	.login-header {
		margin-bottom: 36px;
	}

	.sign-in-button {
		width: 190px;
		height: 58px;
		border-radius: 8px;
		background: var(--g-10);
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
		margin-bottom: 40px;
	}

	.sign-in-button-icon {
		margin: 0 4px;
	}

	.sign-in-button-text {
		color: var(--primary-color);
		font-weight: 600;
		line-height: 42px;
	}

	.login-description {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		align-items: center;
	}

	.login-description-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 4px;
	}

	.check-icon {
		margin-right: 8px;
		color: var(--lb-50);
	}

	.login-description-text {
		color: var(--white-50);
		line-height: 24px;
	}

	.permission-item {
		margin: 20px 0;
	}

	.lock-icon {
		width: 20px;
		height: 20px;
		color: var(--white-50);
	}

	.permission-text {
		font-weight: 600;
		color: var(--white-50);
	}
</style>
