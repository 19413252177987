<template>
	<div class="share-component">
		<div class="global-p-container">
			<div
					class="global-p"
					@click="setGlobalPermission('private')"
					:class="{'global-p-active': currentPermission == 'private'}">
				<div class="global-p-title-container">
					<div class="radio-button"></div>
					<IconLock class="global-p-icon"/>
					<div class="global-p-title-text">Private</div>
				</div>
				<div class="global-p-desc">Only people added can access this space</div>
			</div>

			<div
					class="global-p"
					@click="setGlobalPermission('anyone')"
					:class="{'global-p-active': currentPermission == 'anyone'}">
				<div class="global-p-title-container">
					<div class="radio-button"></div>
					<IconAnyone class="global-p-icon"/>
					<div class="global-p-title-text">Public</div>
				</div>
				<div class="global-p-desc">Anyone on the internet with the link can view</div>
			</div>

			<div
					class="global-p"
					v-if="showDomain || currentPermission == 'domain'"
					@click="setGlobalPermission('domain')"
					:class="{'global-p-active': currentPermission == 'domain'}">
				<div class="global-p-title-container">
					<div class="radio-button"></div>
					<IconDomain class="global-p-icon"/>
					<div class="global-p-title-text">Domain</div>
				</div>
				<div class="global-p-desc">Anyone in your domain with the link can view</div>
			</div>
		</div>

		<div class="modal-buttons-container">
			<button
					class="rounded-button-medium db-50-button button-margin-4"
					id="spaceLinkButton"
					:data-clipboard-text="spaceLink"
					@click="copyLink"><IconLink/> Copy space link</button>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import IconAnyone from 'components/icons/share/IconAnyone.vue'
	import IconUsers from 'components/icons/share/IconUsers.vue'
	import IconDomain from 'components/icons/share/IconDomain.vue'
	import IconLock from 'components/icons/share/IconLock.vue'
	import InputWhite from 'components/common/form/InputWhite.vue'
	import UserAvatar from 'components/common/elements/UserAvatar.vue'
	import IconLink from 'components/icons/icons/IconLink.vue'
	import {State} from 'vuex-class'
	import PermissionService from 'modules/permissions/PermissionService'
	import {SpaceGlobalType, SpaceShareType} from 'modules/permissions/PermissionHelpers'
	import NotificationService from 'modules/notifications/NotificationService'
	import {SOMETHING_WENT_WRONG, SPACE_DOMAIN, SPACE_PRIVATE, SPACE_PUBLIC} from 'modules/notifications/NotificationMessages'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import ClipboardJS from 'clipboard'
	import Utils from 'utils/Utils'
	import Stat from 'modules/stat/Stat'

	@Component({
		components: {
			UserAvatar,
			InputWhite,
			IconLock,
			IconDomain,
			IconUsers,
			IconAnyone,
			IconLink,
		}
	})

	export default class ShareGlobalPermission extends Vue {
		@Prop()
		fileId: string

		@Prop()
		permissions: gapi.client.drive.Permission[]

		@State
		googleUser: gapi.auth2.GoogleUser

		private permissionService: PermissionService = PermissionService.getInstance()
		private currentPermission: SpaceGlobalType = SpaceGlobalType.PRIVATE
		private clipboard: ClipboardJS

		mounted() {
			this.currentPermission = this.calcPermission(this.permissions)
			const button = document.getElementById('spaceLinkButton')
			this.clipboard = new ClipboardJS(button)
			this.clipboard.on('success', () => {
				NotificationService.getInstance().showNotification('Link copied! Share it with your team!')
			})
		}

		beforeDestroy() {
			this.clipboard.destroy()
		}

		@Watch('permissions')
		onFileChanged(newFile) {
			this.currentPermission = this.calcPermission(newFile)
		}

		get spaceLink() {
			return Utils.getSpaceUrl(this.fileId)
		}

		calcPermission(permissions: gapi.client.drive.Permission[]): SpaceGlobalType {
			const shareType: SpaceShareType = this.permissionService.calcSpaceShareType(permissions)
			if (shareType == SpaceShareType.PRIVATE || shareType == SpaceShareType.PEOPLE) {
				return SpaceGlobalType.PRIVATE
			} else if (shareType == SpaceShareType.ANYONE) {
				return SpaceGlobalType.ANYONE
			} else if (shareType == SpaceShareType.DOMAIN) {
				return SpaceGlobalType.DOMAIN
			}
			return SpaceGlobalType.PRIVATE
		}

		setGlobalPermission(option: SpaceGlobalType) {
			this.currentPermission = option
			this.permissionService.setSpaceGlobalPermission(this.fileId, option, this.permissions).then(() => {
				const message = option === SpaceGlobalType.PRIVATE ? SPACE_PRIVATE :  option === SpaceGlobalType.DOMAIN ? SPACE_DOMAIN : SPACE_PUBLIC
				NotificationService.getInstance().showNotification(message)
				EventBus.$emit(CustomEventNames.FILE_PERMISSIONS_UPDATED, this.fileId)
				Stat.setSpaceVisibility(option)
			}, () => {
				NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
			})
		}

		get showDomain(): boolean {
			return !!this.googleUser.getHostedDomain()
		}

		copyLink() {
			//
		}
	}
</script>

<style scoped>
	.share-component {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.global-p-container {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.global-p {
		display: flex;
		cursor: pointer;
		padding: 12px 16px;
		border-radius: 16px;
		margin-bottom: 8px;
		border: 2px solid transparent;
		-webkit-tap-highlight-color: transparent;
	}

	.radio-button {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: #e8e6ea;
		margin-right: 12px;
		position: relative;
		flex: none;
	}

	.radio-button::after {
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		top: 4px;
		left: 4px;
		background-color: #e8e6ea;
	}

	.global-p-active {
		border-color: var(--db-50);
		color: var(--db-50);
	}

	.global-p-active .global-p-desc {
		color: var(--db-40)
	}

	.global-p-active .radio-button::after {
		background-color: var(--db-50);
	}

	.global-p:last-child {
		margin-bottom: 0;
	}

	.global-p-title-container {
		display: flex;
		align-items: center;
		font-weight: 600;
		width: 120px;
	}

	.global-p-icon,
	.global-p-icon svg {
		width: 20px;
		height: 20px;
	}

	.global-p-icon {
		margin-right: 8px;
	}

	.global-p-desc {
		color: var(--g-60);
		word-break: break-word;
	}

	.modal-buttons-container {
		margin-top: 24px;
	}
</style>
