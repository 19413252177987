import { render, staticRenderFns } from "./TopicItem.vue?vue&type=template&id=02743b96&scoped=true"
import script from "./TopicItem.vue?vue&type=script&lang=ts"
export * from "./TopicItem.vue?vue&type=script&lang=ts"
import style0 from "./TopicItem.vue?vue&type=style&index=0&id=02743b96&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02743b96",
  null
  
)

export default component.exports