import { render, staticRenderFns } from "./RenameModal.vue?vue&type=template&id=57b75810&scoped=true"
import script from "./RenameModal.vue?vue&type=script&lang=ts"
export * from "./RenameModal.vue?vue&type=script&lang=ts"
import style0 from "./RenameModal.vue?vue&type=style&index=0&id=57b75810&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57b75810",
  null
  
)

export default component.exports