<template>
	<div class="last-updated">
		<UserAvatar class="last-edit-info-avatar" :user="file.lastModifyingUser" v-if="!simpleView"/>
		<div class="last-edit-info-text" v-if="!simpleView">
			Last updated {{ date }}
		</div>

		<div class="last-edit-info-text" v-if="simpleView">{{ date }}</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import UserAvatar from 'components/common/elements/UserAvatar.vue'

	const moment = require('moment')

	@Component({
		components: {
			UserAvatar,
		}
	})

	export default class LastUpdated extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@Prop({default: false})
		simpleView: boolean

		get date() {
			return this.file && this.file.modifiedTime ? moment(this.file.modifiedTime).fromNow() : ''
		}
	}
</script>

<style scoped>
	.last-updated {
		display: flex;
		align-self: flex-start;
		align-items: center;
		color: var(--g-60);
	}

	.last-edit-info-avatar {
		margin-right: 6px;
	}
</style>
