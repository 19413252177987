<template>
	<BaseModal ref="modal" @closed="onClosed" @ready="onReady" :fixed-position="true">
		<PageSelector ref="pageSelector" @item-selected="itemSelected" class="switcher"/>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import RoutingUtils from 'utils/RoutingUtils'
	import PageSelector from 'components/common/PageSelector.vue'
	import {State} from 'vuex-class'

	@Component({
		components: {
			PageSelector,
			BaseModal,
		}
	})

	export default class QuickSwitcher extends Vue {
		@State
		currentPageId: string

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_SEARCH, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_SEARCH, this.open)
		}

		open() {
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		close() {
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		onClosed() {
			//
		}

		onReady() {
			const pageSelector = this.$refs.pageSelector as any
			pageSelector.focusInput()
		}

		private itemSelected(file: gapi.client.drive.File) {
			if (this.currentPageId !== file.id) {
				RoutingUtils.openPage(file.id)
			}
			this.close()
		}
	}
</script>

<style scoped>
	.switcher {
		width: 604px;
		max-width: 100%;
		padding: 20px 20px 16px 20px;
	}
</style>
