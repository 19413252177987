const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'g', 'i', 'j']

export default class OrderGenerator {
	static getStringIndexFromTimestamp(timestamp: number): string {
		const str = timestamp.toString()
		let index = ''
		for (const char of str) {
			index += letters[parseInt(char, 10)]
		}
		return index
	}

	static toRLE(value: string): string {
		let curr = [value[0]]
		let res = ''
		let ful = ''

		for (let i = 1, l = value.length; i < l; i++) {
			if (curr[0] === value[i]) {
				curr[curr.length] = value[i]
				ful += value[i]
			} else {
				res += (curr.length > 2 ? curr.length : ful) + curr[0]
				curr = [value[i]]
				ful = ''
			}
		}

		res += (curr.length > 2 ? curr.length : ful) + curr[0]

		return res
	}

	static fromRLE(value: string): string {
		let lex = ''
		let res = ''
		let cnt = 0

		for (let i = 0, l = value.length; i < l; i++) {
			if (!numbers.includes(value[i])) {
				if (lex) {
					cnt = parseInt(lex, 10)
				}

				res += new Array(cnt || 1).fill(value[i]).join('')
				lex = ''
				cnt = 0

				continue
			}

			lex += value[i]
		}

		return res
	}

	static generateBetween(left: string = '', right: string = '') {
		left = OrderGenerator.fromRLE(left)
		right = OrderGenerator.fromRLE(right)

		if (left !== '' || right !== '') {
			if (left === '' && right.length === 1) {
				if (right.charAt(0) !== 'b') {
					left = String.fromCharCode(right.charCodeAt(0) - 1)
					return left
				} else {
					left = 'a'
				}
			}

			if (right === '' && left.length === 1) {
				if (left.charAt(0) !== 'y') {
					right = String.fromCharCode(left.charCodeAt(0) + 1)
					return right
				} else {
					right = 'z'
				}
			}

			if (left.length === right.length) {
				let equalParts = ''
				while (left[0] === right[0]) {
					equalParts += left[0]

					left = left.slice(1)
					right = right.slice(1)

					if (left === '' && right === '') {
						break
					}
				}

				return OrderGenerator.toRLE(equalParts + left + right)
			} else if (left.length < right.length) {
				let v = right
				v = v.slice(0, -1) + String.fromCharCode(right.charCodeAt(right.length - 1) - 1)
				v += right[right.length - 1]

				return OrderGenerator.toRLE(v)
			} else if (left.length > right.length) {
				let s = left[left.length - 1]
				if (s != 'z') {
					s = String.fromCharCode(s.charCodeAt(0) + 1)
				} else {
					s += 'b'
				}
				left = left.slice(0, -1) + s

				return OrderGenerator.toRLE(left)
			}
		}

		return 'b'
	}
}
