<template>
	<div
			class="search-item"
			@click="onClick">
		<img :src="fileSmallIcon" class="search-item-icon">
		<img :src="shortcutIcon" class="shortcut-icon" v-if="isShortcut">
		<div class="search-item-title">{{ file.name }}</div>

		<LastUpdated :file="file" :simple-view="true" v-if="showLastUpdate" class="last-update"/>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {getShortcutIcon, getSmallIconByFile} from 'filedefs/FileDefs'
	import LastUpdated from 'components/space/elements/LastUpdated.vue'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import FileUtils from 'utils/FileUtils'

	@Component({
		components: {
			LastUpdated,
		}
	})

	export default class FileRow extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@Prop({default: true})
		showLastUpdate: boolean

		private shortcutIcon: string = getShortcutIcon()

		private onClick() {
			if (FileUtils.isExternalLink(this.file)) {
				window.open(this.file.description, '_blank')
				return
			}
			this.$emit('click', this.file)
		}

		get fileSmallIcon(): string {
			return getSmallIconByFile(this.file)
		}

		get isShortcut(): boolean {
			return this.file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT
		}
	}
</script>

<style scoped>
	.search-item {
		padding: 8px 12px;
		border-radius: 8px;
		display: flex;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		position: relative;
	}

	.search-item-icon {
		width: 18px;
		height: 20px;
		margin-right: 8px;
	}

	.search-item-title {
		flex-grow: 1;
		word-break: break-word;
	}

	.last-update {
		margin-left: 12px;
		flex: none;
	}

	.shortcut-icon {
		position: absolute;
		top: 18px;
		left: 8px;
		width: 12px;
		height: 12px;;
	}
</style>
