export enum SpaceliProperties {
	SPACELI = 'spaceli',
	HIDDEN = 'sh', // true | false
	SPACELI_PAGE_INDEX = 'si', //string
	DESCRIPTION_PAGE = 'dp', // true | false
	TOPIC = 'st' //string
}

export enum SpaceliPropertyValues {
	FOLDER = 'folder',
}
