export enum SpaceAction {
	CREATE_PAGE = 'create_page',
	DELETE_PAGE = 'delete_page',
	RENAME_PAGE = 'rename_page',
	EDIT_PAGE = 'edit_page',
	CAN_COMMENT = 'comment_page',
	SHARE_SPACE = 'share_space',
	CAN_ADD_CHILD = 'can_add_child',
}

//internal
export enum SpaceShareType {
	PRIVATE = 'private',
	PEOPLE = 'people',
	DOMAIN = 'domain',
	ANYONE = 'anyone',
}

//internal
export enum SpaceGlobalType {
	PRIVATE = 'private',
	DOMAIN = 'domain',
	ANYONE = 'anyone',
}

export enum PermissionTypes {
	USER = 'user',
	GROUP = 'group',
	DOMAIN = 'domain',
	ANYONE = 'anyone'
}

export enum PermissionRoles {
	OWNER = 'owner',
	ORGANIZER = 'organizer',
	FILE_ORGANIZER = 'fileOrganizer',
	WRITER = 'writer',
	COMMENTER = 'commenter',
	READER = 'reader',
}

export interface IInviteePermission {
	text: string
	value: PermissionRoles | 'remove'
}

export const INVITEE_PERMISSIONS: IInviteePermission[] = [
	{
		text: 'Can organize',
		value: PermissionRoles.WRITER
	},
	{
		text: 'Can read',
		value: PermissionRoles.READER
	},
]

export enum SpaceCorpora {
	USER = 'user',
	DOMAIN = 'domain',
	DRIVE = 'drive',
	ALL_DRIVES = 'allDrives',
	DEFAULT = 'default'
}

