<template>
	<BaseModal ref="modal" @closed="onClosed" @ready="onReady">
		<div class="modal-content-container">
			<h2 class="modal-header">Give feedback</h2>

			<div class="modal-paragraph">We welcome your questions, bug reports, feature requests and general thoughts. Let us know how we can improve Spaceli.</div>

			<TextareaWhite ref="inputfield" v-model="feedbackStr" :field-title="'Message'" :height="174" class="rename-margin"/>

			<div class="modal-buttons-container">
				<div class="rounded-button-medium db-50-button button-margin-4" @click="saveData">Send</div>
				<div class="rounded-button-medium db-50-text-button cancel-button" @click="close">Cancel</div>
			</div>
		</div>

		<Loading :is-loading="isLoading"/>
	</BaseModal>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import BaseModal from 'components/common/modals/BaseModal.vue'
	import Loading from 'components/common/Loading.vue'
	import TextareaWhite from 'components/common/form/TextareaWhite.vue'
	import NotificationService from 'modules/notifications/NotificationService'
	import {SAVED, SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'

	@Component({
		components: {TextareaWhite, Loading, BaseModal}
	})

	export default class FeedbackModal extends Vue {
		isLoading: boolean = false

		feedbackStr: string = ''

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_FEEDBACK, this.open)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_FEEDBACK, this.open)
		}

		open() {
			this.isLoading = false
			const modal = this.$refs.modal as any
			modal.openModal()
		}

		close() {
			const modal = this.$refs.modal as any
			modal.closeModal()
		}

		onClosed() {
			//
		}

		onReady() {
			const input = this.$refs.inputfield as any
			input.focusInput()
		}

		saveData() {
			this.isLoading = true

			this.close()
			//success notification

			//feedbackStr = '' on send
		}
	}
</script>

<style scoped>
	.rename-margin {
		margin-top: 32px;
	}
</style>
