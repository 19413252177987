import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
import {store} from 'store/Store'
import Utils from 'utils/Utils'
import {SpaceliProperties} from 'enums/FileProps'
import {FileTypes} from 'filedefs/FileDefs'
import {ISpaceFiles} from 'modules/space/SpaceService'

export default class FileUtils {
	static getFileById(fileId: string): gapi.client.drive.File | undefined {
		const spaceFiles: ISpaceFiles = store.state.spaceFiles
		return spaceFiles[fileId]
	}

	static getFileOrShortcutForFileById(fileId: string): gapi.client.drive.File | undefined {
		const spaceFiles: ISpaceFiles = store.state.spaceFiles
		if (spaceFiles[fileId]) {
			return spaceFiles[fileId]
		} else {
			let file = undefined
			Object.keys(spaceFiles).forEach(key => {
				if (
					spaceFiles[key].mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT &&
					spaceFiles[key].shortcutDetails &&
					spaceFiles[key].shortcutDetails.targetId === fileId
				) {
					file = spaceFiles[key]
				}
			})
			return file
		}
	}

	static isFolder(file: gapi.client.drive.File): boolean {
		return file.mimeType === GoogleMimeTypes.FOLDER || (file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT && file.shortcutDetails.targetMimeType === GoogleMimeTypes.FOLDER)
	}

	static isExternalLink(file: gapi.client.drive.File): boolean {
		return file.mimeType === GoogleMimeTypes.SPACELI_SHORTCUT && file.properties.type === FileTypes.LINK
	}

	static isShortcutForFolder(file: gapi.client.drive.File): boolean {
		return file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT && file.shortcutDetails.targetMimeType === GoogleMimeTypes.FOLDER
	}

	static getChildrenForFolder(file: gapi.client.drive.File): gapi.client.drive.File[] {
		const id = FileUtils.isShortcutForFolder(file) ? file.shortcutDetails.targetId : file.id
		return FileUtils.getChildrenByFileId(id)
	}

	static getChildrenByFileId(fileId: string): gapi.client.drive.File[] {
		const childrenMap = store.state.childrenMap
		const spaceFiles = store.state.spaceFiles
		const fileIds = childrenMap[fileId] || []
		const files = fileIds.map(id => spaceFiles[id])
		const filteredFiles = files.filter((potentialChild: gapi.client.drive.File) => !FileUtils.hiddenFile(potentialChild))
		return FileUtils.sortFilesArray(filteredFiles)
	}

	static isFileExistInSpace(fileId: string) {
		const spaceFiles: ISpaceFiles = store.state.spaceFiles
		return !!spaceFiles[fileId]
	}

	// static getDescriptionPages(file: gapi.client.drive.File, spaceFiles: gapi.client.drive.File[] = store.state.spaceFiles) {
	// 	const id = FileUtils.isShortcutForFolder(file) ? file.shortcutDetails.targetId : file.id
	// 	return spaceFiles.filter(f => f.parents[0] === id && (f.appProperties && f.appProperties[SpaceliProperties.DESCRIPTION_PAGE]))
	// }

	static isDescriptionPage(file: gapi.client.drive.File) {
		if (!file.appProperties) {
			return false
		}
		return !!file.appProperties[SpaceliProperties.DESCRIPTION_PAGE]
	}

	static hiddenFile(file: gapi.client.drive.File): boolean {
		if (!file.appProperties) {
			return false
		}
		return !!file.appProperties[SpaceliProperties.HIDDEN]
	}

	static sortFilesArray(files: gapi.client.drive.File[]) {
		// return files.sort((a, b) => {
		// 	if (a.name < b.name) {
		// 		return -1
		// 	}
		// 	if (a.name > b.name) {
		// 		return 1
		// 	}
		// 	return 0
		// })
		return files.sort((a, b) => (Utils.getItemIndex(a) > Utils.getItemIndex(b)) ? 1 : ((Utils.getItemIndex(b) > Utils.getItemIndex(a)) ? -1 : 0))
	}

	static getAllFoldersInSpace(): string[] {
		//todo shortcuts
		const spaceFiles: ISpaceFiles = store.state.spaceFiles
		return Object.keys(spaceFiles).filter(key => spaceFiles[key].mimeType === GoogleMimeTypes.FOLDER)
	}

	static getFileMimeType(file: gapi.client.drive.File): string {
		if (file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT) {
			return file.shortcutDetails.targetMimeType
		} else {
			return file.mimeType
		}
	}
}
