<template>
	<div class="share-animated-button" :style="{'color': color, 'borderColor': borderColor}" @click="openShare">
		<component :is="icon"></component>
		<div :class="{'share-animated-button-text': !fullView, 'share-animated-button-text-full-view': fullView}">Share</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import IconAnyone from 'components/icons/share/IconAnyone.vue'
	import IconUsers from 'components/icons/share/IconUsers.vue'
	import IconDomain from 'components/icons/share/IconDomain.vue'
	import IconLock from 'components/icons/share/IconLock.vue'
	import PermissionService from 'modules/permissions/PermissionService'
	import {SpaceShareType} from 'modules/permissions/PermissionHelpers'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import DriveAPI from 'api/DriveAPI'
	import Icon3Dots from 'components/icons/icons/Icon3Dots.vue'

	@Component({
		components: {
			IconAnyone,
			IconUsers,
			IconLock,
			IconDomain,
			Icon3Dots,
		}
	})

	export default class ShareAnimatedButton extends Vue {
		private permissionService: PermissionService = PermissionService.getInstance()
		private permissions: gapi.client.drive.Permission[]
		private icon: string = 'Icon3Dots'

		@Prop({default: 'var(--primary-color)'})
		color: string

		@Prop({default: 'var(--primary-color)'})
		iconColor: string

		@Prop({default: 'var(--primary-color)'})
		borderColor: string

		@Prop({default: false})
		fullView: boolean

		@Prop()

		@Prop()
		file: gapi.client.drive.File

		private openShare() {
			EventBus.$emit(CustomEventNames.OPEN_SHARE, this.file.id, this.permissions)
		}

		mounted() {
			this.getPermissions(this.file)
			EventBus.$on(CustomEventNames.FILE_PERMISSIONS_UPDATED, this.onPermissionsUpdated)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.FILE_PERMISSIONS_UPDATED, this.onPermissionsUpdated)
		}

		private onPermissionsUpdated(fileId: string) {
			if (fileId === this.file.id) {
				this.getPermissions(this.file, true)
			}
		}

		@Watch('fileId')
		onFileChanged(newFileId) {
			this.getPermissions(newFileId)
		}

		private getIcon() {
			switch (this.permissionService.calcSpaceShareType(this.permissions)) {
				case SpaceShareType.PRIVATE:
					return 'IconLock'
				case SpaceShareType.PEOPLE:
					return 'IconUsers'
				case SpaceShareType.DOMAIN:
					return 'IconDomain'
				case SpaceShareType.ANYONE:
					return 'IconAnyone'
				default:
					return 'IconLock'
			}
		}

		private getPermissions(file: gapi.client.drive.File, force = false) {
			if (file.permissions && !force) {
				this.permissions = file.permissions
				this.icon = this.getIcon()
			} else {
				DriveAPI.getPermissionsForFile(file.id).then((permissions: any) => {
					this.permissions = permissions
					this.icon = this.getIcon()
				}, () => {
					console.error()
				})
			}
		}
	}
</script>

<style scoped>
	.share-animated-button {
		flex: none;
		height: 28px;
		border-radius: 20px;
		line-height: 28px;
		padding: 0 9px;
		font-weight: 600;
		cursor: pointer;
		user-select: none;
		font-size: 12px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		border: 1px solid;
		overflow: hidden;
		transition: all 0.2s;
		-webkit-tap-highlight-color: transparent;
	}

	.share-animated-button svg {
		width: 16px;
		height: 16px;
		padding-right: 0px;
		transition: all 0.2s;
	}

	.share-animated-button-text {
		box-sizing: border-box;
		width: 0;
		transition: all 0.2s;
		opacity: 0;
	}

	.share-animated-button-text-full-view {
		box-sizing: border-box;
		padding-left: 6px;
	}

	.share-animated-button:hover .share-animated-button-text {
		width: 38px;
		opacity: 1;
		padding-left: 6px;
	}
</style>
