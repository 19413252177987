<template>
	<div class="file-menu">
		<div class="share-container" v-if="calcPermission(file, SpaceAction.SHARE_SPACE)">
			<ShareAnimatedButton class="share" :file="file" :border-color="'none'" :color="'var(--g-90)'" :full-view="true"/>
		</div>

		<div class="file-menu-item" @click="setTopic" v-if="topicsAvailable">
			<div class="file-menu-item-icon">
				<IconHash/>
			</div>
			<div class="file-menu-item-text">Set a topic</div>
		</div>

		<div class="file-menu-item" @click="renameItem" v-if="calcPermission(file, SpaceAction.RENAME_PAGE)">
			<div class="file-menu-item-icon">
				<IconEdit/>
			</div>
			<div class="file-menu-item-text">Rename</div>
		</div>

		<div v-if="fileLink" class="file-menu-item" :data-clipboard-text="fileLink" ref="copyLinkButton">
			<div class="file-menu-item-icon">
				<IconLink/>
			</div>
			<div class="file-menu-item-text">Copy link</div>
		</div>

		<div class="file-menu-item" @click="deleteItem" v-if="calcPermission(file, SpaceAction.EDIT_PAGE)">
			<div class="file-menu-item-icon">
				<IconFileMinus/>
			</div>
			<div class="file-menu-item-text">Remove</div>
		</div>

	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import IconEdit from 'components/icons/icons/IconEdit.vue'
	import IconFileMinus from 'components/icons/icons/IconFileMinus.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import IconLink from 'components/icons/icons/IconLink.vue'
	import IconHash from 'components/icons/icons/IconHash.vue'
	import ClipboardJS from 'clipboard'
	import NotificationService from 'modules/notifications/NotificationService'
	import TopicsService from 'components/topics/TopicsService'
	import IconUsers from 'components/icons/share/IconUsers.vue'
	import ShareAnimatedButton from 'components/share/ShareAnimatedButton.vue'
	import TopicsUtils from 'components/topics/TopicsUtils'

	@Component({
		components: {
			ShareAnimatedButton,
			IconEdit,
			IconFileMinus,
			IconLink,
			IconHash,
			IconUsers,
		}
	})

	export default class SpaceMenu extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@Prop()
		fileLink: string

		private clipboard: ClipboardJS

		mounted() {
			if (this.fileLink) {
				const button = this.$refs.copyLinkButton as HTMLElement
				this.clipboard = new ClipboardJS(button)
				this.clipboard.on('success', () => {
					NotificationService.getInstance().showNotification('Link copied! Share it with your team!')
				})
			}
		}

		beforeDestroy() {
			if (this.fileLink) {
				this.clipboard.destroy()
			}
		}

		get topicsAvailable() {
			return TopicsService.getInstance().topicsAvailable && TopicsUtils.canEditTopic(this.file)
		}

		private renameItem() {
			EventBus.$emit(CustomEventNames.OPEN_RENAME_FILE, this.file)
		}

		private deleteItem() {
			EventBus.$emit(CustomEventNames.OPEN_DELETE_FILE, this.file)
		}

		private setTopic() {
			EventBus.$emit(CustomEventNames.OPEN_SET_TOPIC, this.file)
		}
	}
</script>

<style scoped>
	.share-container {
		padding: 16px 12px;
		border-bottom: 1px solid var(--white-10);
		display: flex;
	}
	.share {
		width: 100%;
		justify-content: center;
		background-color: var(--lb-50);
		border: none;
	}
</style>
