import {store} from 'store/Store'
import Utils from 'utils/Utils'
import {EventBus} from 'EventBus'
import {CustomEventNames} from 'enums/CustomEventNames'
import OrderGenerator from 'utils/OrderGenerator'
import SpaceService from 'modules/space/SpaceService'
import FileUtils from 'utils/FileUtils'
import TopicsUtils from 'components/topics/TopicsUtils'
import TopicsService from 'components/topics/TopicsService'

const SIDEBAR_ITEMS_CONTAINER_ID = 'sidebarItemsContainer'
const TOPICS_ITEMS_CONTAINER_ID = 'topicsItemsContainer'
const TOLERANCE = 10

export enum DraggingItemType {
	TOPIC = 'topic',
	FILE = 'file'
}

export default class DNDService {
	private static _instance: DNDService

	static getInstance(): DNDService {
		if (!DNDService._instance) {
			DNDService._instance = new DNDService()
		}
		return DNDService._instance
	}

	private mouseMoveWrapper = this.onMouseMove.bind(this)
	private onMouseUpWrapper = this.onMouseUp.bind(this)
	private draggingItem: Element

	private processingItem: HTMLElement
	private direction: 'above' | 'below' | 'asChild'

	private container: HTMLElement
	private dropLineElement: HTMLElement

	private dragStarted: boolean = false
	private startClientY: number

	private itemType: DraggingItemType

	constructor() {}

	private isDndPermitted(): boolean {
		const space = store.state.space
		if (!space || !space.capabilities) {
			return false
		}
		return store.state.space.capabilities.canEdit
	}

	onMouseDown(e: MouseEvent, item: Element, type = DraggingItemType.FILE) {
		if (type === DraggingItemType.FILE && (!item || !item.id || !this.isDndPermitted())) {
			return
		}
		this.itemType = type
		if (!this.dropLineElement) {
			this.createDropLine()
		}
		e.stopPropagation()
		e.preventDefault()
		this.addListeners()
		this.draggingItem = item
		this.startClientY = e.clientY
	}

	private addListeners() {
		document.addEventListener('mousemove', this.mouseMoveWrapper)
		document.addEventListener('mouseup', this.onMouseUpWrapper)
	}

	private removeListeners() {
		document.removeEventListener('mousemove', this.mouseMoveWrapper)
		document.removeEventListener('mouseup', this.onMouseUpWrapper)
	}

	private dragStart() {
		if (!this.dragStarted) {
			EventBus.$emit(CustomEventNames.DRAG_STARTED, this.draggingItem.id)
			if (this.itemType === DraggingItemType.FILE) {
				this.draggingItem.classList.add('dragging-item')
			} else {
				this.draggingItem.classList.add('dragging-topic')
			}
			document.body.classList.add('disable-hovers')
			this.dragStarted = true
		}
	}

	private dragEnd() {
		this.draggingItem.classList.remove('dragging-item')
		this.draggingItem.classList.remove('dragging-topic')
		document.body.classList.remove('disable-hovers')
		this.dragStarted = false
	}

	private isCurrentItemChildOfDragging(itemId, parentId) {
		if (this.itemType === DraggingItemType.FILE) {
			return Utils.isItemChildOf(itemId, parentId)
		} else {
			return TopicsUtils.isItemChildOf(itemId, parentId)
		}
	}

	private onMouseMove(e: MouseEvent) {
		if (!this.dragStarted) {
			this.dragStart()
			return
		}
		if (Math.abs(e.clientY - this.startClientY) < TOLERANCE) {
			this.dropProps()
			this.hideDropLine()
			return
		}
		const target = e.target as HTMLElement
		const item = target.closest('.sidebar-item') as HTMLElement

		if (item && item.id && !item.dataset.dndDisabled) {
			const isCurrentItemChildOfDragging = this.isCurrentItemChildOfDragging(item.id, this.draggingItem.id)
			if (!isCurrentItemChildOfDragging) {
				this.processCurrentItem(item, e)
			} else {
				this.dropProps()
				this.hideDropLine()
			}
		} else {
			this.dropProps()
			this.hideDropLine()
		}
	}

	private onMouseUp(e: MouseEvent) {
		this.removeListeners()
		this.hideDropLine()
		this.saveNewPosition()
		this.dropProps()
		this.dragEnd()
		this.draggingItem = undefined
		this.itemType = undefined
		this.destroyDropLine()
	}

	private getItemObject(itemId) {
		if (this.itemType === DraggingItemType.FILE) {
			return FileUtils.getFileById(itemId)
		} else {
			return TopicsUtils.getTopicById(itemId)
		}
	}

	private calcParentId(processingItemObject: any) {
		if (this.itemType === DraggingItemType.FILE) {
			return this.direction === 'asChild' ? FileUtils.isShortcutForFolder(processingItemObject) ? processingItemObject.shortcutDetails.targetId : processingItemObject.id : processingItemObject.parents[0]
		} else {
			return this.direction === 'asChild' ? processingItemObject.id : processingItemObject.parent
		}
	}

	private saveNewPosition() {

		if (
			!this.processingItem ||
			!this.processingItem.id ||
			this.draggingItem == this.processingItem
		) {
			return
		}

		const itemId = this.processingItem.id
		const processingItemObject: any = this.getItemObject(itemId)

		if (!processingItemObject) {
			return
		}

		const parentId = this.calcParentId(processingItemObject)
		const children = this.getItemChildren(parentId)//FileUtils.getChildrenByFileId(parentId)

		const itemIndex = children.findIndex(child => child.id === itemId)

		if (itemIndex === -1 && this.direction !== 'asChild') {
			return
		}

		let leftIndex, rightIndex
		if (this.direction === 'above') {
			leftIndex = itemIndex ? this.getItemIndex(children[itemIndex - 1]) : ''
			rightIndex = this.getItemIndex(processingItemObject)
		} else if (this.direction === 'below') {
			leftIndex = this.getItemIndex(processingItemObject)
			rightIndex = itemIndex < children.length - 1 ? this.getItemIndex(children[itemIndex + 1]) : this.getItemIndex(processingItemObject) + 'b'
		} else if (this.direction === 'asChild') {
			leftIndex = rightIndex = ''
		}

		const newIndex = OrderGenerator.generateBetween(leftIndex, rightIndex)

		if (this.itemType === DraggingItemType.FILE) {
			SpaceService.getInstance().setPageIndex(this.draggingItem.id, OrderGenerator.fromRLE(newIndex), parentId)
		} else {
			TopicsService.getInstance().setTopicIndex(this.draggingItem.id, OrderGenerator.fromRLE(newIndex), parentId)
		}
	}

	private getItemIndex(item: any): string {
		return this.itemType === DraggingItemType.FILE ? Utils.getItemIndex(item) : item.index
	}

	private processCurrentItem(item: HTMLElement, e: MouseEvent) {
		const {top: itemTop, bottom: itemBottom, height: itemHeight} = item.getElementsByClassName('sidebar-item-title')[0].getBoundingClientRect()

		//below
		if (e.clientY > itemTop + itemHeight / 2) {
			if (item.dataset.expanded && e.clientY > itemTop + itemHeight / 2 && e.clientY <= itemBottom) {
				const children = this.getItemChildren(item.id)
				if (children.length) {
					item = document.getElementById(children[0].id)
					this.direction = 'above'
				} else {
					this.direction = 'asChild'
				}
			} else {
				this.direction = 'below'
			}
		} else {
			this.direction = 'above'
		}

		this.processingItem = item
		this.showDropLine()
	}

	private getItemChildren(itemId) {
		if (this.itemType === DraggingItemType.FILE) {
			const itemFile = FileUtils.getFileById(itemId)
			return FileUtils.getChildrenForFolder(itemFile)
		} else {
			return TopicsUtils.getTopicChildren(itemId)
		}
	}

	private dropProps() {
		this.processingItem = undefined
		this.direction = undefined
	}

	private getContainer() {
		if (this.itemType === DraggingItemType.FILE) {
			return document.getElementById(SIDEBAR_ITEMS_CONTAINER_ID)
		} else {
			return document.getElementById(TOPICS_ITEMS_CONTAINER_ID)
		}
	}

	private createDropLine() {
		this.dropLineElement = document.createElement('div')
		this.dropLineElement.classList.add('drop-line')
		const container = this.getContainer()
		if (!container) {
			return
		}
		this.container = container
		container.appendChild(this.dropLineElement)
		this.hideDropLine()
	}

	private destroyDropLine() {
		if (!this.container) {
			return
		}
		this.container.removeChild(this.dropLineElement)
		this.dropLineElement = undefined
	}


	private showDropLine() {
		const {right: parentRight} = this.processingItem.getBoundingClientRect()

		let itemBounds: DOMRect

		if (this.direction === 'asChild') {
			itemBounds = this.processingItem.getElementsByClassName('no-pages')[0].getElementsByClassName('sidebar-item-title')[0].getBoundingClientRect()
		} else {
			itemBounds = this.processingItem.getElementsByClassName('sidebar-item-title')[0].getBoundingClientRect()
		}

		const {left: containerLeft, top: containerTop} = this.container.getBoundingClientRect()

		let top
		if (this.direction === 'above') {
			top = itemBounds.top
			const prevItem = this.processingItem.previousSibling as HTMLElement
			if (prevItem) {
				top -= (itemBounds.top - this.getItemBottom(prevItem)) / 2
			}
		} else if (this.direction === 'below') {
			top = this.getItemBottom(this.processingItem)
			let nextItem = this.processingItem.nextSibling as HTMLElement
			if (nextItem && !nextItem.getBoundingClientRect) {
				// means comment tag
				nextItem = nextItem.nextSibling as HTMLElement
			}
			if (nextItem) {
				const nextItemBounds = nextItem.getElementsByClassName('sidebar-item-title')[0].getBoundingClientRect()
				top += (nextItemBounds.top - top) / 2
			}
		} else if (this.direction === 'asChild') {
			top = itemBounds.top
		}

		this.dropLineElement.style.top = `${top - containerTop + this.container.scrollTop}px`
		this.dropLineElement.style.left = `${itemBounds.left - containerLeft - 2}px`
		this.dropLineElement.style.right = `${parentRight}px`
		this.dropLineElement.style.width = `${parentRight - itemBounds.left - 24}px`

		this.dropLineElement.classList.remove('hidden-drop-line')
	}

	private getItemBottom(item: HTMLElement): number {
		const bounds = item.getBoundingClientRect()
		const style = window.getComputedStyle(item)
		return bounds.bottom - parseInt(style.paddingBottom)
	}

	private hideDropLine() {
		this.dropLineElement.classList.add('hidden-drop-line')
	}
}
