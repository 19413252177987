<template>
	<div class="file-view">
		<div class="file-content-view frame-file-container-padding">
			<div class="frame-container" :class="getContainerClass">
				<iframe
						ref="frame"
						:width="width"
						:height="height"
						:src="iframeUrl"
						:class="getFrameClass"
						frameBorder="0"
						scrolling="no"
						@load="loaded"
						@error="loaded"
						allowtransparency="true"
						allowFullScreen>
				</iframe>
			</div>

			<PageFooter :file="file" v-if="file.lastModifyingUser"/>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import {ISpacePage} from 'components/space/pages/ISpacePage'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import PageFooter from 'components/space/elements/PageFooter.vue'

	@Component({
		components: {
			PageFooter,
		}
	})

	export default class IframePage extends Vue implements ISpacePage {
		@Prop()
		file: gapi.client.drive.File

		update(): void {
			//
		}

		loaded(): void {
			EventBus.$emit(CustomEventNames.PAGE_LOADED)
		}

		get iframeUrl() {
			const url = this.file.description
			const frame = this.$refs.frame as HTMLIFrameElement
			if (frame && frame.src === url) {
				frame.src += ''
			}
			return url
		}

		get width() {
			if (this.file.properties && this.file.properties.width) {
				return this.file.properties.width
			}
			return '100%'
		}

		get height() {
			if (this.file.properties && this.file.properties.height) {
				return this.file.properties.height
			}
			return '100%'
		}

		get getFrameClass() {
			if (this.file.properties && this.file.properties.type) {
				return this.file.properties.type + '-frame'
			}
			return ''
		}

		get getContainerClass() {
			if (this.file.properties && this.file.properties.type) {
				return this.file.properties.type + '-container'
			}
			return ''
		}
	}
</script>

<style scoped>
	.file-content-view {
		padding: 0 72px;
	}

	.frame-container {
		display: flex;
	}

	.invision-container {
		justify-content: center;
	}

	.figma-container,
	.marvel-container,
	.youtube-container,
	.vimeo-container,
	.miro-container,
	.gmaps-container,
	.embed-container,
	.whimsical-container,
	.loom-container,
	.codepen-container,
	.airtable-container {
		width: 100%;
		height: var(--frame-height);
		flex: none;
	}

	.figma-frame,
	.youtube-frame,
	.vimeo-frame,
	.embed-frame,
	.airtable-frame {
		width: 100%;
		height: 100%;
		border: 1px solid var(--g-20);
		border-bottom: none;
	}

	.marvel-frame,
	.miro-frame,
	.gmaps-frame,
	.whimsical-frame,
	.loom-frame,
	.codepen-frame {
		width: 100%;
		height: 100%;
	}

	@media only screen and (max-width: 480px) {
		.file-content-view {
			padding: 0 32px;
		}
	}
</style>
