<template>
	<div class="space-card" @click="selectItem($event)" :style="{'backgroundColor': bgColor}">
		<div class="space-title-container">
			<div class="space-title">{{ space.name }}</div>
			<div class="star-icon" @click="toggleStarSpace($event)">
				<IconStarEmpty v-if="!starred"/>
				<IconStarFull class="full-star" v-if="starred"/>
			</div>
		</div>
		<div class="space-description">{{ space.description }}</div>
		<div class="bottom-container">

			<v-popover :container="'.popover-backdrop'">
				<div class="tooltip-target button">
					<Icon3Dots/>
				</div>

				<SpaceMenu slot="popover" :file="space" :file-link="fileLink"/>
			</v-popover>

			<div class="middle">
<!--				<div class="topic-badge" v-if="topic" v-show="dashboardFilter !== topic.id" @click="openTopic($event)">{{ topic.name }}</div>-->
				<div class="topic-badge" v-if="hasTopic" v-show="dashboardFilter !== topic.id" @click="openTopic($event)">{{ topic.name }}</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
	import Icon3Dots from 'components/icons/icons/Icon3Dots.vue'
	import IconStarFull from 'components/icons/icons/IconStarFull.vue'
	import IconStarEmpty from 'components/icons/icons/IconStarEmpty.vue'
	import Utils from 'utils/Utils'
	import SpaceMenu from 'components/common/menus/SpaceMenu.vue'
	import TopicsService, {ITopic} from 'components/topics/TopicsService'
	import {SpaceliProperties} from 'enums/FileProps'
	import TopicsUtils from 'components/topics/TopicsUtils'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import FileActions from 'modules/fileactions/FileActions'
	import DashboardService from 'modules/dashboard/DashboardService'

	@Component({
		components: {
			SpaceMenu,
			Icon3Dots,
			IconStarFull,
			IconStarEmpty,
		}
	})

	export default class SpaceCard extends Vue {
		@Prop()
		space: gapi.client.drive.File

		@State(state => state.dashboardFilter)
		dashboardFilter: string

		@State(state => state.topics)
		topics: ITopic[]

		private hasTopic: boolean = false
		private topic: ITopic

		private starred: boolean = false

		private topicId: string

		@Watch('space', {deep: true})
		onFileChanged(val) {
			this.checkForTopic()
		}

		mounted() {
			this.checkForTopic()
			this.starred = !!this.space.starred
			EventBus.$on(CustomEventNames.TOPIC_UPDATED, this.onTopicUpdated)
			EventBus.$on(CustomEventNames.TOPIC_DELETED, this.onTopicDeleted)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.TOPIC_UPDATED, this.onTopicUpdated)
			EventBus.$off(CustomEventNames.TOPIC_DELETED, this.onTopicDeleted)
		}

		private onTopicUpdated(topicId) {
			if (topicId === this.topicId) {
				this.getTopic()
			}
		}

		private onTopicDeleted(deletedTopics: string[]) {
			if (this.hasTopic) {
				deletedTopics.forEach(t => {
					if (this.topicId === t) {
						this.hasTopic = false
						this.topic = undefined
					}
				})
			}
		}

		private checkForTopic() {
			if (this.space.appProperties && this.space.appProperties[SpaceliProperties.TOPIC]) {
				this.topicId = this.space.appProperties[SpaceliProperties.TOPIC]
				TopicsService.getInstance().isInit ? this.getTopic() : TopicsService.getInstance().topicsInited.addOnce(this.getTopic, this)
			} else {
				if (this.hasTopic) {
					this.hasTopic = false
					this.topic = undefined
				}
			}
		}

		private getTopic() {
			this.hasTopic = false
			this.topic = undefined
			const topic = TopicsUtils.getTopicById(this.topicId)
			if (topic) {
				this.topic = topic
				this.hasTopic = true
			}
		}

		get fileLink(): string {
			return Utils.getSpaceUrl(this.space.id)
		}

		get bgColor(): string {
			return 'var(--ds-60)'
			// return this.space.folderColorRgb ? this.space.folderColorRgb : 'var(--ds-60)'
		}

		private selectItem(e: MouseEvent) {
			if (e.target instanceof HTMLElement && e.target.classList.contains('tooltip-target')) {
				return
			}
			this.$emit('select', this.space.id)
		}

		private openTopic(e?: MouseEvent) {
			if (e) {
				e.stopPropagation()
			}
			EventBus.$emit(CustomEventNames.DASHBOARD_FILTER_SELECTED, this.topic.id)
		}

		private toggleStarSpace(e: MouseEvent) {
			if (e) {
				e.stopPropagation()
			}
			FileActions.toggleStarFile(this.space)
			this.space.starred = !this.starred
			this.starred = !this.starred
			DashboardService.getInstance().updateSpace(this.space)
		}
	}
</script>

<style scoped>
	.space-card {
		width: 100%;
		height: 100%;
		border-radius: 16px;
		padding: 18px 22px;
		color: var(--white);
		box-sizing: border-box;
		cursor: pointer;
		user-select: none;
		border: 2px solid transparent;
		transition: border-color 0.1s;
		display: flex;
		flex-direction: column;
		-webkit-tap-highlight-color: transparent;
	}

	.space-card:hover {
		border-color: var(--lb-70);
	}

	.space-title-container {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 8px;
		display: flex;
		line-height: 24px;
	}

	.space-title {
		flex-grow: 1;
	}

	.star-icon {
		width: 24px;
		height: 24px;
		margin-left: 4px;
		color: var(--white-50);
		cursor: pointer;
	}

	.full-star {
		color: var(--white);
	}

	.star-icon:hover,
	.full-star:hover {
		color: var(--lb-50);
	}

	.star-icon svg {
		width: 24px;
		height: 24px;
	}

	.space-description {
		color: var(--white-50);
		min-height: 20px;
		flex-grow: 1;
	}

	.bottom-container {
		margin-top: 16px;
		display: flex;
		flex-direction: row-reverse;
	}

	.button {
		height: 28px;
		width: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--white);
	}

	.button svg {
		pointer-events: none;
	}

	.button:hover {
		color: var(--lb-50);
	}

	.middle {
		flex-grow: 1;
		display: flex;
	}

	.topic-badge {
		border: 1px solid var(--white-30);
		box-sizing: border-box;
		color: var(--white-80);
		height: 28px;
		line-height: 26px;
		font-size: 12px;
		font-weight: 600;
		padding: 0px 10px;
		border-radius: 16px;
		transition: 0.1s all;
	}

	.topic-badge:hover {
		color: var(--lb-50);
		border-color: var(--lb-70);
	}

	@media only screen and (max-width: 480px) {

	}
</style>
