import Keyboard from 'enums/Keyboard'
import {EventBus} from 'EventBus'
import {CustomEventNames} from 'enums/CustomEventNames'

export default class KeyboardService {
	private static _instance: KeyboardService

	static getInstance():KeyboardService {
		if (!KeyboardService._instance) {
			KeyboardService._instance = new KeyboardService()
		}
		return KeyboardService._instance
	}

	constructor() {
		document.addEventListener('keydown', this.onKeyDown.bind(this))
	}

	private onKeyDown(e: KeyboardEvent) {
		switch (e.keyCode) {
			case Keyboard.ESCAPE:
				EventBus.$emit(CustomEventNames.ESCAPE_PRESSED)
				break
			case Keyboard.K:
				if (this.isCtrlKey(e)) {
					EventBus.$emit(CustomEventNames.OPEN_SEARCH)
				}
				break
		}
	}

	isCtrlKey(e: KeyboardEvent): boolean {
		return !!(e.metaKey || e.ctrlKey)
	}
}
