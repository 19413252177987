<template>
	<div class="file-view">
		<div class="file-content-view frame-file-container-padding">

			<iframe
				ref="contentFrame"
				class="preview-frame"
				:src="iframeUrl"
				@load="loaded"
				@error="loaded"
				frameBorder="0"
				scrolling="no"
				allowFullScreen>
			</iframe>

			<PageFooter :file="file" v-if="file.lastModifyingUser" :show-top-line="false"/>
		</div>
	</div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator'
import {ISpacePage} from 'components/space/pages/ISpacePage'
import PageFooter from 'components/space/elements/PageFooter.vue'
import EditByEditor from 'components/space/buttons/EditByEditor.vue'
import ViewInDrive from 'components/space/buttons/ViewInDrive.vue'
import {EventBus} from 'EventBus'
import {CustomEventNames} from 'enums/CustomEventNames'

@Component({
	components: {
		PageFooter,
		EditByEditor,
		ViewInDrive,
	}
})

export default class PreviewPage extends Vue implements ISpacePage {
	@Prop()
	file: gapi.client.drive.File

	update(): void {
		const frame = this.$refs.contentFrame as any
		frame.src += ''
	}

	loaded(): void {
		EventBus.$emit(CustomEventNames.PAGE_LOADED)
	}

	get iframeUrl(): string {
		return this.file.webViewLink.replace('view?usp=drivesdk', 'preview')
	}
}
</script>

<style scoped>
.file-content-view {
	padding: 0 72px;
}

.preview-frame {
	width: 100%;
	height: var(--frame-height);
	flex: none;
	border: 1px solid var(--g-20);
	box-sizing: border-box;
	border-radius: 4px;
}

@media only screen and (max-width: 480px) {
	.file-content-view {
		padding: 0 32px;
	}
}
</style>
