<template>
	<div class="search-button" @click="openSearch">
		<IconSearch class="search-icon"/>
		<div class="search-text">Find anything</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import IconSearch from 'components/icons/icons/IconSearch.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'

	@Component({
		components: {
			IconSearch,
		}
	})

	export default class SearchField extends Vue {
		private openSearch() {
			EventBus.$emit(CustomEventNames.OPEN_SEARCH)
		}
	}
</script>

<style scoped>
	.search-button {
		position: relative;
		display: flex;
		align-items: center;
		padding: 8px 12px;
		box-sizing: border-box;
		background-color: var(--g-20);
		border-radius: 36px;
		cursor: pointer;
	}

	.search-icon {
		color: var(--g-60);
		width: 18px;
		height: 18px;
		margin-right: 8px;
	}

	.search-text {
		color: var(--g-60);
	}
</style>
