<template>
	<div class="file-view">
		<div class="file-content-view">
			<div class="sad-container">
				<SadDoc class="img-empty"/>
				<div class="paragraph-text empty-text">Sorry, this file is not supported yet. We are working on supporting all files, but you can write to us so that we speed up the work on this format.</div>
				<div class="empty-text file-mimetype">Type: {{ file.mimeType }}</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import {ISpacePage} from 'components/space/pages/ISpacePage'
	import SadDoc from 'components/icons/icons/SadDoc.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'

	@Component({
		components: {
			SadDoc,
		}
	})

	export default class DefaultPage extends Vue implements ISpacePage{
		@Prop()
		file: gapi.client.drive.File

		@Watch('file')
		onFileChanged(val: string, oldVal: string) {
			this.loaded()
		}

		mounted() {
			this.loaded()
		}

		update(): void {
			//
		}

		loaded(): void {
			EventBus.$emit(CustomEventNames.PAGE_LOADED)
		}
	}
</script>

<style scoped>
	.file-content-view {
		padding: 0 72px;
	}

	.sad-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
		margin-bottom: 128px;
		text-align: center;
	}

	.img-empty {
		color: var(--g-60);
		margin-bottom: 24px;
	}

	.empty-text {
		max-width: 100%;
		width: 340px;
	}

	.file-mimetype {
		margin-top: 12px;
		font-weight: bold;
	}

	@media only screen and (max-width: 480px) {
		.file-content-view {
			padding: 0 32px;
		}
	}
</style>
