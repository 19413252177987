//people/id

export interface UserSuggestion {
	emailAddress: string
	displayName?: string
	photoLink?: string
}

export default class PeopleAPI {
	// static getUserProfile(userId: string) {
	// 	gapi.client.people.people.get({
	// 		resourceName: userId,
	// 		personFields: 'names,emailAddresses',
	// 	}).then(result => {
	// 		console.log('getUserProfile', result)
	// 	})
	// }

	static getConnectionsList(): Promise<UserSuggestion[]> {
		return new Promise((resolve, reject) => {
			gapi.client.people.people.connections.list({
				// access_token: gapi.auth.getToken().access_token,
				resourceName: 'people/me',
				alt: 'json',
				pageSize: 1000,
				personFields: 'names,emailAddresses,photos',
			}).execute((result: any) => {
				if (!result.error && result.connections) {
					result.connections.map(contact => {
						return {
							displayName: contact.names[0].displayName,
							emailAddress: contact.emailAddresses[0].value,
							photoLink: contact.photos[0].url,
						}
					})
					resolve([])
				} else {
					reject()
				}
			})
		})
	}

	static searchDomainContacts(query: string): Promise<UserSuggestion[]> {
		return new Promise((resolve, reject) => {
			gapi.client.people.people.searchDirectoryPeople({
				query: query,
				readMask: 'names,emailAddresses,photos',
				sources: 'DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE',
			}).execute((result: any) => {
				if (!result.error && result.people) {
					const domainUsers = []
					result.people.forEach(user => {
						if (!user.emailAddresses) {
							return
						}
						const email = user.emailAddresses.find(e => e.metadata.primary)
						const domainUser: UserSuggestion = {
							emailAddress: email.value
						}
						if (user.names) {
							const name = user.names.find(e => e.metadata.primary)
							domainUser.displayName = name.displayName
						}
						if (user.photos) {
							const photo = user.photos.find(e => e.metadata.primary)
							domainUser.photoLink = photo.url
						}
						domainUsers.push(domainUser)
					})
					resolve(domainUsers)
				} else {
					reject()
				}
			})
		})
	}
}
