import GoogleUtils from "./GoogleUtils";
import Stat from "../modules/stat/Stat";
import {EventBus} from "../EventBus";
import {CustomEventNames} from "../enums/CustomEventNames";
import RoutingUtils from "./RoutingUtils";
import AuthService from "../modules/app/AuthService";
import {SOMETHING_WENT_WRONG} from "../modules/notifications/NotificationMessages";

export enum ErrorsCodes {
    NOT_FOLDER = 0,
    BROKEN_SHORTCUT = 1,

    UNAUTHENTICATED = 401,
    NOT_PERMITTED = 403,
    NOT_FOUND = 404,
}

export enum ErrorMessages {
    NOT_FOLDER = 'Sorry, the file that you are trying to access is not a folder. Please check the URL for typos or other errors.',
    NOT_FOUND = 'Sorry, you don\'t have read access to this file, or the file does not exist. It may have been deleted, or never existed in the first place. Please check the URL for typos or other errors.',
    SOMETHING_WENT_WRONG = 'Sorry, looks like something went wrong — please try to reload the page. If the issue persists, send us a bug report.',
    NOT_PERMITTED = 'Sorry, you don\'t have sufficient permissions for this file. Please authorize.',

    NO_SCOPES = 'Sorry, you have not granted Spaceli permission to access spaces and files. Please grant the necessary permissions.',
    PAGE_NOT_FOUND = 'Sorry, you don\'t have read access to this file, or the file does not exist. It may have been deleted, or never existed in the first place.',
}

export class ErrorHandlingUtils {
    static handleRequestDataErrorAndBlockApp(error: any, authService: AuthService, place: string) {
        if (GoogleUtils.isScopesGrantedError(error)) {
            Stat.insufficientPermissionsError()
            EventBus.$emit(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, ErrorMessages.NO_SCOPES, false, true)
        } else if (error.code === ErrorsCodes.NOT_FOUND) {
            if (authService.firebaseUser) {
                Stat.spaceDashboardDataError(place, error.code ?? 0, error.message ?? '', true)
                EventBus.$emit(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, ErrorMessages.NOT_FOUND)
            } else {
                RoutingUtils.saveRouteAndGoToLogin()
            }
        } else if (error.code === ErrorsCodes.NOT_FOLDER) {
            Stat.spaceDashboardDataError(place, error.code ?? 0, error.message ?? '', !!authService.firebaseUser)
            EventBus.$emit(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, ErrorMessages.NOT_FOLDER)
        } else if (error.code === ErrorsCodes.UNAUTHENTICATED) {
            if (authService.firebaseUser) {
                authService.signOut()
            } else {
                RoutingUtils.saveRouteAndGoToLogin()
            }
        } else {
            Stat.spaceDashboardDataError(place, error.code ?? 0, error.message ?? '', true)
            EventBus.$emit(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR,  error.message ?? ErrorMessages.SOMETHING_WENT_WRONG)
        }
    }

    static getCorrectMessageForSignInError(error: string): string {
        if (error === 'idpiframe_initialization_failed') {
            return 'Failed to initialize a required iframe from Google'
        } else if (error === 'popup_closed_by_user') {
            return 'You closed the popup before finishing the sign in flow'
        } else if (error === 'access_denied') {
            return 'You denied the permission to the scopes required'
        } else if (error === 'immediate_failed') {
            return 'No user could be automatically selected without prompting the consent flow'
        } else {
            return SOMETHING_WENT_WRONG
        }
    }
}