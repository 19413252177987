import {PermissionTypes, SpaceCorpora} from 'modules/permissions/PermissionHelpers'
import {store} from 'store/Store'
import cloneDeep from 'lodash/cloneDeep'
import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
import FileUtils from 'utils/FileUtils'
import DriveAPI from 'api/DriveAPI'
import {IChildrenMap, IErrorsMap, ISpaceFiles} from 'modules/space/SpaceService'

enum GrabFilesType {
	BATCH = 'batch',
	MULTIPLE_FOLDERS = 'multiple_folders'
}

export class SpaceHelpers {
	static getFilesDeep(
		rootFile: gapi.client.drive.File,
		deepLevel: number = 0,
		callback: (
			fullLoad: boolean,
			fileSet: ISpaceFiles,
			childrenMap: IChildrenMap,
			errorsMap: IErrorsMap,
		) => void
	) {
		SpaceHelpers.calcGrabFilesType(rootFile).then(grabType => {
			const fileSet: ISpaceFiles = {}
			const childrenMap: IChildrenMap = {}
			const errorsMap: IErrorsMap = {}

			fileSet[rootFile.id] = rootFile

			let folders: string[] = [rootFile.id]
			const processedFolders: string[] = [rootFile.id]
			let processedLevel: number = 0
			let shortcutParents: string[] = []

			const sendAnswer = (fullLoad: boolean) => {
				callback(fullLoad, fileSet, childrenMap, errorsMap)
			}

			const getFiles = () => {
				let levelFolders

				if (deepLevel && processedLevel === deepLevel) {
					sendAnswer(true)
					return
				}

				if (!folders.length) {
					if (shortcutParents.length) {
						levelFolders = cloneDeep(shortcutParents)
						shortcutParents = []
					} else {
						sendAnswer(true)
						return
					}
				} else {
					levelFolders = cloneDeep(folders)
					folders = []
				}

				if (processedLevel == 2) {
					sendAnswer(false)
				}

				const handler = (resp: {files: gapi.client.drive.File[], errorsMap: any}) => {
					processedLevel += 1
					const files = resp.files
					if (resp.errorsMap) {
						Object.keys(resp.errorsMap).forEach(key => {
							errorsMap[key] = resp.errorsMap[key]
						})
					}
					files.forEach(file => {
						fileSet[file.id] = file

						file.parents.forEach(parentId => {
							if (!childrenMap[parentId]) {
								childrenMap[parentId] = []
							}
							childrenMap[parentId].push(file.id)
						})

						if (
							file.mimeType === GoogleMimeTypes.FOLDER &&
							!FileUtils.hiddenFile(file) &&
							folders.indexOf(file.id) === -1 &&
							processedFolders.indexOf(file.id) === -1
						) {
							folders.push(file.id)
							processedFolders.push(file.id)
						} else if (
							FileUtils.isShortcutForFolder(file) &&
							!FileUtils.hiddenFile(file) &&
							folders.indexOf(file.shortcutDetails.targetId) === -1 &&
							processedFolders.indexOf(file.shortcutDetails.targetId) === -1 &&
							shortcutParents.indexOf(file.shortcutDetails.targetId) === -1
						) {
							shortcutParents.push(file.shortcutDetails.targetId)
							processedFolders.push(file.shortcutDetails.targetId)
						}
					})
					getFiles()
				}
				//, SpaceHelpers.getDriveId(rootFile)
				if (grabType === GrabFilesType.MULTIPLE_FOLDERS) {
					DriveAPI.getFilesForFolders(levelFolders, SpaceHelpers.calcCorpora(rootFile)).then(handler)
				} else {
					DriveAPI.getFilesForFoldersByBatch(levelFolders, SpaceHelpers.calcCorpora(rootFile)).then(handler)
				}
			}
			getFiles()
		})
	}

	static getDriveId(file: gapi.client.drive.File): string|undefined {
		return file.driveId || file.teamDriveId
	}

	static calcCorpora(file: gapi.client.drive.File): SpaceCorpora {
		return SpaceCorpora.USER
		//order is matter
		// if (file.driveId) {
			// return SpaceCorpora.DRIVE
			// return SpaceCorpora.ALL_DRIVES
		// }
		// const user = store.state.googleUser
		// const permissions = file.permissions
		// if (!permissions || !user) {
		// 	return SpaceCorpora.USER
		// }
		// if (permissions.some(p => p.type === PermissionTypes.USER && p.emailAddress === user.getBasicProfile().getEmail())) {
		// 	return SpaceCorpora.USER
		// }
		// if (permissions.some(p => p.type === PermissionTypes.DOMAIN && p.domain === user.getHostedDomain())) {
		// 	return SpaceCorpora.DOMAIN
		// }
		// return SpaceCorpora.USER
	}

	static calcGrabFilesType(file: gapi.client.drive.File): Promise<GrabFilesType> {
		return new Promise((resolve) => {
			const getType = (permissions?: gapi.client.drive.Permission[]) => {
				const user = store.state.googleUser
				if (!permissions || !user) {
					resolve(GrabFilesType.BATCH)
					return
				}
				const type: GrabFilesType = permissions.some(
					p => (p.type === PermissionTypes.USER && p.emailAddress === user.getBasicProfile().getEmail()) || (p.type === PermissionTypes.DOMAIN && p.domain
						=== user.getHostedDomain())) ? GrabFilesType.MULTIPLE_FOLDERS : GrabFilesType.BATCH
				resolve(type)
			}
			if (!file.permissions) {
				DriveAPI.getPermissionsForFile(file.id).then((permissions: gapi.client.drive.Permission[]) => {
					getType(permissions)
				}, () => {
					getType()
				})
			} else {
				getType(file.permissions)
			}
		})
	}
}
