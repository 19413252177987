<template>
	<div class="space-header">
		<div class="header-main-space">
			<div class="button-sidebar" @click="toggleSidebar" :class="{'revert-arrow': !sidebarOpened}">
				<IconMenuArrow/>
			</div>
			<h3 class="text-ellipsis">{{ file.name }}</h3>
		</div>
		<div class="header-buttons-container">
			<component
					v-for="button in options"
					:key="button"
					:is="button"
					class="header-button"
					:page="file">
			</component>
		</div>

		<div class="separator"></div>

		<v-popover :container="'.popover-backdrop'">
			<HelpButton class="tooltip-target question"/>

			<HelpMenu :file="file" slot="popover"/>
		</v-popover>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import EditByEditor from 'components/space/buttons/EditByEditor.vue'
	import ViewInDrive from 'components/space/buttons/ViewInDrive.vue'
	import EditByLink from 'components/space/buttons/EditByLink.vue'
	import HelpButton from 'components/space/buttons/HelpButton.vue'
	import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'
	import {isImageFile, isPreviewFile} from 'filedefs/SupportedFileTypes'
	import IconMenuArrow from 'components/icons/icons/IconMenuArrow.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import HelpMenu from 'components/common/menus/HelpMenu.vue'
	import {State} from 'vuex-class'

	@Component({
		components: {
			HelpMenu,
			HelpButton,
			EditByLink,
			ViewInDrive,
			EditByEditor,
			IconMenuArrow,
		}
	})

	export default class SpaceHeader extends Vue {
		@Prop()
		file: gapi.client.drive.File

		@State(state => state.sidebarOpened)
		sidebarOpened: boolean

		private options: string[] = []

		mounted() {
			if (this.file) {
				this.updatePageOptions()
			}
		}

		@Watch('file')
		onFileChanged(val: string, oldVal: string) {
			if (val !== oldVal) {
				this.updatePageOptions()
			}
		}

		private updatePageOptions() {
			this.options = this.getPageOptions(this.file.mimeType)
		}

		private toggleSidebar() {
			EventBus.$emit(CustomEventNames.TOGGLE_SIDEBAR)
		}

		private getPageOptions(mimeType: string) {
			if (!mimeType) {
				return []
			}

			if (mimeType === GoogleMimeTypes.DOCS ||
				mimeType === GoogleMimeTypes.DOCS_OLD ||
				mimeType === GoogleMimeTypes.SHEETS ||
				mimeType === GoogleMimeTypes.EXCEL_XLSX ||
				mimeType === GoogleMimeTypes.EXCEL_XLS ||
				mimeType === GoogleMimeTypes.SLIDES
			) {
				return ['EditByEditor', 'ViewInDrive']
			} else if (mimeType === GoogleMimeTypes.FORMS) {
				return ['EditByLink', 'ViewInDrive']
			} else if (mimeType === GoogleMimeTypes.FOLDER) {
				return []
			} else if (mimeType === GoogleMimeTypes.SPACELI_SHORTCUT) {
				return []
			} else if (isImageFile(mimeType)) {
				return ['ViewInDrive']
			} else if (isPreviewFile(mimeType)) {
				return ['ViewInDrive']
			} else {
				return []
			}
		}
	}
</script>

<style scoped>
	.space-header {
		flex: none;
		width: 100%;
		box-sizing: border-box;
		padding: 16px 24px;
		display: flex;
		border-bottom: 1px solid var(--g-20);
	}

	.separator {
		width: 1px;
		height: 24px;
		background-color: var(--g-20);
		margin: 4px 12px;
	}

	.header-main-space {
		flex-grow: 1;
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	.header-buttons-container {
		display: flex;
		flex: none;
	}

	.header-button {
		margin-right: 4px;
		-webkit-tap-highlight-color: transparent;
	}

	.header-button:first-child {
		margin-left: 12px;
	}

	.header-button:last-child {
		margin-right: 0;
	}

	.question {
		/*margin-left: 4px;*/
	}

	.button-sidebar {
		width: 32px;
		height: 32px;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 16px;
		-webkit-tap-highlight-color: transparent;
		flex: none;
	}

	.button-sidebar svg {
		width: 24px;
		height: 24px;
	}

	.revert-arrow {
		transform: rotate(180deg);
	}

	@media only screen and (max-width: 768px) {
		.header-buttons-container {
			display: none;
		}
	}
</style>
