<template>
	<transition name="full-screen-slide-bottom">
		<div class="file-editor" v-if="isOpened">
			<div class="file-editor-header">
				<div class="icon-back" @click="closeEditor">
					<IconArrowLeftShort/>
				</div>
				<h3 class="flex-grow-1">{{ file.name }}</h3>
				<div
						class="rounded-button-medium db-50-button-no-shadow"
						@click="closeEditor">
					Done
				</div>
			</div>
			<div class="file-container">
				<iframe :src="fileEmbedUrl()" frameborder="0" class="file-editor-frame"></iframe>
			</div>
		</div>
	</transition>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import GoogleUtils from 'utils/GoogleUtils'
	import Stat from 'modules/stat/Stat'
	import IconArrowLeftShort from 'components/icons/icons/IconArrowLeftShort.vue'

	@Component({
		components: {
			IconArrowLeftShort,
		}
	})

	export default class FileEditor extends Vue {
		isOpened: boolean = false

		file: gapi.client.drive.File = {}
		url: string

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_FILE_EDITOR, this.openEditor)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_FILE_EDITOR, this.openEditor)
		}

		private openEditor(file: gapi.client.drive.File, url?: string) {
			if (file) {
				this.file = file
				this.url = url
				this.isOpened = true
				Stat.openEditor(this.file.mimeType)
			}
		}

		private closeEditor() {
			this.isOpened = false
			this.file = {}
			this.url = undefined
			EventBus.$emit(CustomEventNames.FILE_EDITOR_CLOSED)
		}

		fileEmbedUrl(): string {
			return this.url ? this.url : GoogleUtils.getEditUrl(this.file)
		}
	}
</script>

<style scoped>
	.file-editor {
		width: 100%;
		height: 100%;
		position: absolute;
		box-sizing: content-box;
		display: flex;
		flex-direction: column;
		top: -1px;
		left: 0;
		background-color: var(--white);
		z-index: var(--modal-z-index);
		border-top: 1px solid var(--g-20);
	}

	.file-container,
	.file-editor-frame {
		display: flex;
		width: 100%;
		height: 100%;
	}

	.file-editor-header {
		height: 64px;
		padding: 16px 24px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--g-20);
		user-select: none;
	}

	.icon-back {
		margin-right: 12px;
		cursor: pointer;
		transition: all 0.1s;
		height: 24px;
		width: 24px;
	}

	.icon-back:hover {
		color: var(--db-45);
	}
</style>
