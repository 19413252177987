export enum ActionPages {
	MENTIONS = 'mentions',
	ACTIVITY = 'activity',
}

export function isActionPage(page: string): boolean {
	return page === ActionPages.ACTIVITY || page === ActionPages.MENTIONS
}

export function getActionPageFileData(page: string) {
	if (page === ActionPages.ACTIVITY) {
		return {
			name: 'Activity feed'
		}
	} else if (page === ActionPages.MENTIONS) {
		return {
			name: 'Tasks & Mentions'
		}
	} else {
		return {}
	}
}
