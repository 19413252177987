<template>
	<div class="topics-list">
		<div class="topics-header">
			<div class="topics-header-text">
				Topics
				<div
						class="tooltip-icon"
						@click="openTopicHint">
					<IconQuestion/>
				</div>
			</div>
			<div class="create-button" @click="createTopic">
				<IconPlus class="icon-20"/>
			</div>
		</div>

		<div class="topics-container" id="topicsItemsContainer" v-if="rootTopics.length">
			<div class="all-topics">
				<TopicItem v-for="topic in rootTopics" :key="topic.id" :topic="topic"/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import IconPlus from 'components/icons/icons/IconPlus.vue'
	import IconQuestion from 'components/icons/icons/IconQuestion.vue'
	import {ITopic} from 'components/topics/TopicsService'
	import TopicItem from 'components/topics/TopicItem.vue'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import TopicsUtils from 'components/topics/TopicsUtils'

	@Component({
		components: {
			TopicItem,
			IconPlus,
			IconQuestion,
		}
	})

	export default class TopicsList extends Vue {
		private createTopic() {
			EventBus.$emit(CustomEventNames.OPEN_CREATE_TOPIC, 'root')
		}

		get rootTopics(): ITopic[] {
			return TopicsUtils.getTopicChildren('root')
		}

		private openTopicHint() {
			EventBus.$emit(CustomEventNames.OPEN_TOPIC_HINT)
		}
	}
</script>


<style scoped>
	.topics-list {
		display: flex;
		flex-direction: column;
	}

	.topics-header {
		font-size: 16px;
		font-weight: 600;
		display: flex;
		align-items: center;
		line-height: 24px;
		margin: 0 6px 0 16px;
	}

	.topics-header-text {
		flex-grow: 1;
		display: flex;
		align-items: center;
	}

	.create-button {
		width: 24px;
		height: 24px;
		margin-left: 8px;
		cursor: pointer;
		padding: 2px;
		box-sizing: border-box;
	}

	.create-button:hover {
		color: var(--lb-50);
	}

	.topics-container {
		padding-top: 12px;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.all-topics {
		display: flex;
		flex-direction: column;
	}

	.tooltip-icon {
		width: 16px;
		height: 16px;
		color: var(--lb-70);
		margin-left: 12px;
		cursor: pointer;
	}

	.tooltip-icon svg {
		width: 16px;
		height: 16px;
	}

	@media only screen and (max-width: 768px) {
		.tooltip-icon {
			display: none;
		}
	}
</style>
