export enum GoogleMimeTypes {
	FOLDER = 'application/vnd.google-apps.folder',
	DOCS = 'application/vnd.google-apps.document',
	DOCS_OLD = 'application/vnd.google-apps.kix',
	SHEETS = 'application/vnd.google-apps.spreadsheet',
	FORMS = 'application/vnd.google-apps.form',
	SLIDES = 'application/vnd.google-apps.presentation',
	EXTERNAL_SHORTCUT = 'application/vnd.google-apps.drive-sdk',
	SPACELI_SHORTCUT = 'application/vnd.google-apps.drive-sdk.618650423333',
	GOOGLE_SHORTCUT = 'application/vnd.google-apps.shortcut',
	// VIDEO = 'application/vnd.google-apps.video',
	// AUDIO = 'application/vnd.google-apps.audio',

	PNG = 'image/png',
	JPG = 'image/jpeg',
	SVG = 'image/svg+xml',

	TXT = 'text/plain',
	PDF = 'application/pdf',
	WORD_DOC = 'application/msword',
	WORD_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	EXCEL_XLS = 'application/vnd.ms-excel',
	EXCEL_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	POWERPOINT = 'application/vnd.ms-powerpoint',
	POWERPOINT_1 = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	// JAMBOARD = 'application/vnd.google-apps.jam'
}
