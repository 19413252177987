import AppService from 'modules/app/AppService'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import {store} from 'store/Store'
import DataSnapshot = firebase.database.DataSnapshot
import {EventBus} from 'EventBus'
import {CustomEventNames} from 'enums/CustomEventNames'
import {Surveys} from 'modules/onboarding/Surveys'

const moment = require('moment')
const DAY = 60 * 60 * 24 //seconds because of unix
const MIN_TIME = DAY * 1
const SURVEY_DELAY = 1000 * 2

export default class SurveyService {
	private static _instance: SurveyService
	private appService: AppService

	private surveysRef: firebase.database.Reference

	static getInstance(): SurveyService {
		if (!SurveyService._instance) {
			SurveyService._instance = new SurveyService()
		}
		return SurveyService._instance
	}

	constructor() {
		this.appService = AppService.getInstance()
		this.init()
	}

	private init() {
		this.appService.isInit ? this.checkForSurvey() : this.appService.appInitialized.addOnce(this.checkForSurvey, this)
	}

	private checkForSurvey() {
		const user = store.state.firebaseUser
		if (!user) {
			return
		}
		const creationTime = moment(user.metadata.creationTime).unix()
		const now = moment().unix()
		if (!(now - creationTime > MIN_TIME)) {
			return
		}
		this.surveysRef = firebase.database().ref('/surveys/' + user.uid)
		this.surveysRef.once('value').then((snapshot: DataSnapshot) => {
			const data = snapshot.val()
			if (!data || !data[Surveys.FIRST_SURVEY]) {
				this.openSurvey(Surveys.FIRST_SURVEY)
			}
		})
	}

	private openSurvey(survey: string) {
		setTimeout(() => {
			EventBus.$emit(CustomEventNames.OPEN_SURVEY_MODAL, survey)
		}, SURVEY_DELAY)
	}

	checkSurvey(survey: string) {
		this.surveysRef.update({
			[survey]: true,
		}).then(() => {
			//
		})
	}
}
