import AppService from 'modules/app/AppService'
import {store} from 'store/Store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import TopicsUtils from 'components/topics/TopicsUtils'
import OrderGenerator from 'utils/OrderGenerator'
import DriveAPI from 'api/DriveAPI'
import {SpaceliProperties} from 'enums/FileProps'
import Stat from 'modules/stat/Stat'

export interface ITopic {
	name: string
	parent?: string
	id?: string
	index: string
}

const TOPICS_PATH = 'topics'

export default class TopicsService {
	private static _instance: TopicsService
	private appService: AppService = AppService.getInstance()

	private topicsRef: firebase.firestore.CollectionReference

	isInit: boolean = false
	topicsInited = new signals.Signal<() => void>()

	topicsAvailable: boolean = false

	static getInstance(): TopicsService {
		if (!TopicsService._instance) {
			TopicsService._instance = new TopicsService()
		}
		return TopicsService._instance
	}

	constructor() {
		this.appService.isInit ? this.init() : this.appService.appInitialized.addOnce(this.init, this)
	}

	private init() {
		const user = store.state.googleUser
		this.topicsAvailable = !!user && !!user.getHostedDomain()
		if (this.topicsAvailable) {
			const domain = user.getHostedDomain()
			this.topicsRef = firebase.firestore().collection(TOPICS_PATH).doc(domain).collection(TOPICS_PATH)
			this.getTopics()
		} else {
			this.isInit = true
			this.topicsInited.dispatch()
		}
	}

	getTopics() {
		return new Promise<void>((resolve, reject) => {
			const topics = []
			this.topicsRef.get().then(querySnapshot => {
				querySnapshot.forEach(topic => {
					const t = topic.data()
					t.id = topic.id
					topics.push(t)
				})
				this.setTopicState(topics)

				this.isInit = true
				this.topicsInited.dispatch()

				resolve()
			}).catch(() => {
				reject()
			})
		})
	}

	createTopic(name: string, parentId: string): Promise<string> {
		return new Promise((resolve, reject) => {
			if (!this.topicsRef) {
				reject()
			}
			const index = this.getNewIndex(parentId)

			const topic: ITopic = {
				name: name,
				parent: parentId || 'root',
				index: index,
			}
			this.topicsRef.add(topic).then(topicRef => {
				Stat.createTopic()
				resolve(topicRef.id)
			}).catch((error) => {
				console.error('Error adding document: ', error)
				reject()
			})
		})
	}

	renameTopic(topicId: string, name: string) {
		return new Promise<void>((resolve, reject) => {
			this.topicsRef.doc(topicId).update({
				name: name
			}).then(() => {
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	}

	deleteTopic(topicId: string): Promise<string[]> {
		return new Promise((resolve, reject) => {
			const topics = [topicId]

			const getChildren = (topicId) => {
				const children = TopicsUtils.getTopicChildren(topicId)
				children.forEach(child => {
					getChildren(child.id)
					topics.push(child.id)
				})
			}

			getChildren(topicId)

			const batch = firebase.firestore().batch()

			topics.forEach(id => {
				const topicRef = this.topicsRef.doc(id)
				batch.delete(topicRef)
			})

			batch.commit().then(() => {
				Stat.deleteTopic()
				resolve(topics)
			}).catch(error => {
				reject(error)
			})
		})
	}

	setTopicIndex(topicId: string, newIndex: string, newParentId: string) {
		if (!topicId) {
			return
		}
		//local update
		const topics = store.state.topics
		const index = topics.findIndex(t => t.id === topicId)
		if (index === -1) {
			return
		}
		topics[index].parent = newParentId
		topics[index].index = newIndex
		this.setTopicState(topics)

		//update on server
		return new Promise<void>((resolve, reject) => {
			this.topicsRef.doc(topicId).update({
				index: newIndex,
				parent: newParentId
			}).then(() => {
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	}

	setTopicToSpace(space: gapi.client.drive.File, topicId: string | null) {
		return new Promise<void>((resolve, reject) => {
			const res = {
				appProperties: {
					[SpaceliProperties.TOPIC]: topicId
				}
			}
			DriveAPI.updateFileProps(space.id, res).then(response => {
				if (response.error) {
					reject()
					return
				}
				resolve()
			})
		})
	}

	private getNewIndex(parentTopicId: string): string {
		const leftIndex = ''
		let rightIndex = ''
		const children = TopicsUtils.getTopicChildren(parentTopicId)
		if (children[0]) {
			rightIndex = children[0].index
		}
		return OrderGenerator.generateBetween(leftIndex, rightIndex)
	}

	private setTopicState(topics: ITopic[]) {
		store.commit('setTopics', topics)
	}
}
