import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
import {RootState} from 'store/StoreTypes'

Vue.use(Vuex)

export const storeOptions: StoreOptions<RootState> = {
	state: {
		account: undefined,

		dashboardFilter: '',
		topics: [],

		sidebarOpened: true,

		currentPageId: '',
		currentSpaceId: '',

		spaces: [],
		space: {},
		spaceFiles: {},
		childrenMap: {},
		errorsMap: {},
		spaceActionFiles: [],

		spaceLoadedPartially: false,
		spaceLoadedFully: false,

		remainedRoute: '',

		googleUser: undefined,
		firebaseUser: undefined,
	},

	mutations: {
		setAccount(state, data) {
			state.account = data
		},

		setGoogleUser(state, user) {
			state.googleUser = user
		},
		setFirebaseUser(state, user) {
			state.firebaseUser = user
		},

		//////////////////////////

		setDashboardFilter(state, value) {
			state.dashboardFilter = value
		},

		setSpaces(state, spaces: gapi.client.drive.File[]) {
			state.spaces = spaces
		},

		setTopics(state, topics) {
			state.topics = topics
		},

		///////////////////////////

		setSidebarOpened(state, value) {
			state.sidebarOpened = value
		},

		setCurrentPageId(state, id) {
			state.currentPageId = id
		},
		setCurrentSpaceId(state, id) {
			state.currentSpaceId = id
		},

		setRemainedRoute(state, route) {
			state.remainedRoute = route
		},


		///////////      SPACE and SPACE FILES       //////////////

		setSpace(state, space: gapi.client.drive.File) {
			state.space = space
		},
		setSpaceFiles(state, files) {
			state.spaceFiles = files
		},
		dropChildrenMap(state) {
			state.childrenMap = {}
		},
		setChildrenMap(state, map) {
			Object.keys(map).forEach(key => {
				Vue.set(state.childrenMap, key, map[key])
			})
		},
		setErrorsMap(state, map) {
			state.errorsMap = {}
			Object.keys(map).forEach(key => {
				Vue.set(state.errorsMap, key, map[key])
			})
		},
		setSpaceActionFiles(state, files) {
			state.spaceActionFiles = files
		},


		//////////// SPACE loaded STATE ////////////
		dropSpaceLoadedState(state) {
			state.spaceLoadedPartially = false
			state.spaceLoadedFully = false
		},
		setSpaceLoadedPartially(state) {
			state.spaceLoadedPartially = true
		},
		setSpaceLoadedFully(state) {
			state.spaceLoadedFully = true
		},
	}
}

export const store = new Vuex.Store<RootState>(storeOptions)
