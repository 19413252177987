<template>
	<div class="file-view" v-if="errorCode">
		<div class="file-content-view">
			<div class="sad-container"  v-if="!brokenShortcut">
				<SadDoc class="img-empty"/>
				<div class="paragraph-text empty-text">{{ errorMessage }}</div>
			</div>

			<div class="sad-container" v-if="brokenShortcut">
				<SadDoc class="img-empty"/>
				<h3 class="margin-b-16">Broken shortcut</h3>
				<div class="paragraph-text empty-text">
					<p>Sorry, this shortcut is broken. A shortcut will break if:</p>
					<ul>
						<li>You don’t have permission to open the original file.</li>
						<li>The original file is in the trash.</li>
						<li>The original file is deleted.</li>
					</ul>
					<p>To fix the broken shortcut, try to restore the original file, or ask the owner for permission to open the file.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import SadDoc from 'components/icons/icons/SadDoc.vue'
  import {ErrorsCodes, ErrorMessages} from "../../../utils/ErrorHandlingUtils";

	@Component({
		components: {
			SadDoc
		}
	})

	export default class ErrorPage extends Vue {
		@Prop()
		errorCode: ErrorsCodes | null

    @Prop()
    message: string | null

		get brokenShortcut(): boolean {
			return this.errorCode === ErrorsCodes.BROKEN_SHORTCUT
		}

		get errorMessage() {
      if (this.message) {
        return this.message
      } else {
        if (this.errorCode === ErrorsCodes.NOT_FOUND) {
          return ErrorMessages.PAGE_NOT_FOUND
        } else {
          return ErrorMessages.SOMETHING_WENT_WRONG
        }
      }
		}
	}
</script>

<style scoped>
	.file-content-view {
		padding: 0 72px;
	}

	.sad-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
		margin-bottom: 128px;
	}

	.img-empty {
		color: var(--g-60);
		margin-bottom: 24px;
	}

	.empty-text {
		max-width: 100%;
		width: 340px;
    text-align: center;
	}

	.file-mimetype {
		margin-top: 12px;
		font-weight: bold;
	}

	.sad-container ul {
		padding-left: 32px;
		margin: 8px 0;
    text-align: left;
	}

	.sad-container li {
		list-style-type: none;
		position: relative;
	}

	.sad-container li::before {
		content: '–';
		position: absolute;
		left: -16px;
		top: -1px;
	}

	@media only screen and (max-width: 480px) {
		.file-content-view {
			padding: 0 32px;
		}
	}
</style>
