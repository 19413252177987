export enum CustomEventNames {
	DASHBOARD_FILTER_SELECTED = 'dashboard-filter-selected',
	SIDEBAR_ITEM_SELECTED = 'sidebar-item-selected',

	OPEN_CREATE_SPACE = 'open-create-space',

	OPEN_SET_TOPIC = 'open-set-topic',
	LIGHT_TOPIC_ITEM_SELECTED = 'light-topic-item-selected',
	OPEN_TOPIC_HINT = 'open-topic-hint',

	TOPIC_DELETED = 'topic-deleted',
	TOPIC_UPDATED = 'topic-updated',

	OPEN_INVITE_TEAM = 'open-invite-team',

	OPEN_CREATE_TOPIC = 'open-create-topic',
	OPEN_RENAME_TOPIC = 'open-rename-topic',
	OPEN_DELETE_TOPIC = 'open-delete-topic',

	OPEN_FILE_EDITOR = 'open-editor',
	FILE_EDITOR_CLOSED = 'file-editor-closed',

	OPEN_SEARCH = 'open-search',
	OPEN_SHARE = 'open-share',
	OPEN_FEEDBACK = 'open-feedback',
	OPEN_MEET_SPACELI_2 = 'open-meet-spaceli-2',
	OPEN_CREATE_FILE = 'open-create-file',
	OPEN_DELETE_FILE = 'open-delete-file',
	OPEN_RENAME_FILE = 'open-rename-file',
	OPEN_SPACE_OR_PAGE_ERROR = 'open-space-page-error',
	OPEN_SURVEY_MODAL = 'open-survey-modal',

	OPEN_SIDEBAR = 'open-sidebar',
	TOGGLE_SIDEBAR = 'toggle-sidebar',

	OPEN_DASHBOARD_SIDEBAR = 'open-dashboard-sidebar',

	OPEN_RENAME_TEAM = 'open-rename-team',

	PAGE_LOADED = 'page-loaded',

	FILE_CREATED = 'file-created',
	FILE_DELETED = 'file-deleted',
	FILE_UPDATED = 'file-updated',
	FILE_PERMISSIONS_UPDATED = 'file-permissions-updated',

	UPDATE_SPACE_FILES = 'update-space-files',

	ESCAPE_PRESSED = 'escape-pressed',
	DRAG_STARTED = 'drag-started',

	SCOPES_GRANTED = 'scopes-granted',

	BASIC_SCOPES_GRANTED = 'BASIC_SCOPES_GRANTED'
}
