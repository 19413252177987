<template>
	<svg v-bind:style="styles" class="spinner" viewBox="0 0 50 50">
		<circle class="path" cx="25" cy="25" r="20" fill="none" :stroke-width="width" :stroke="color"></circle>
	</svg>
</template>
<script>
  export default {
    props: {
      size: {
        default: '40px'
      },
      color: {
        default: 'var(--db-20)'
      },
      width: {
        default: '5'
      }
    },
    computed: {
      styles () {
        return {
          width: this.size,
          height: this.size
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
	.spinner {
		animation: rotate 2s linear infinite;

		& .path {
			stroke-linecap: round;
			animation: dash 1.5s ease-in-out infinite
			/*circle-colors 3s ease-in-out infinite;*/
		}
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes circle-colors {
		0% {
			stroke: var(--db-50);
		}
		50% {
			stroke: var(--red);
		}
		100% {
			stroke: var(--db-50);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
</style>
