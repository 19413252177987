<template>
    <transition name="message">
        <div class="popup-message-container" v-if="isShowing" :class="{ 'danger': danger }">
            <div class="popup-message-text" v-html="message"></div>
        </div>
    </transition>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'

	@Component({})
	export default class PopupMessage extends Vue {
		private showTime: number = 5000
		private message: string = ''
		private isShowing: boolean = false
		private danger: boolean = false

		shopPopupMessage(message, danger = false, showTime = 5000) {
			if (!message) {
				return
			}

			this.showTime = showTime
			this.danger = danger
			this.message = message
			this.isShowing = true

			setTimeout(() => {
				this.isShowing = false
			}, this.showTime)
		}
	}
</script>

<style scoped>
    .popup-message-container {
		z-index: var(--z-index-tooltip);
		position: fixed;
		top: 24px;
		right: 24px;
		width: 280px;
		min-height: 56px;
		max-height: 200px;
		background-color: var(--ds-60);
		border-radius: 16px;
		transition: all 0.2s;
		display: flex;
		overflow: hidden;
    }

    .popup-message-text {
        flex-grow: 1;
        padding: 16px 20px;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 24px;
        color: var(--white);
    }

    .danger {
        background-color: var(--red);
    }

    .message-enter, .message-leave-to {
        opacity: 0;
        transform: translateY(8px);
    }

	@media only screen and (max-width: 480px) {
		.popup-message-container {
			width: auto;
			left: 24px;
		}
	}
</style>
