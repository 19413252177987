<template>
	<div class="sidebar-item" :id="topic.id" :data-expanded="expanded">
		<div
				class="sidebar-item-title-container"
				:class="{'active': dashboardFilter === topic.id, 'item-hovered': menuOpened}">

			<div
					class="indent-line"
					:class="{'indent-line-first': index === 0 && level - 1 === i}"
					:key="lvl"
					v-for="(lvl, i) in level">
			</div>

			<div
					class="sidebar-item-title"
					@mousedown="onMouseDown"
					@click="topicClicked(topic.id)"
					v-tooltip="{content: name, placement: 'top', delay: 2000}">
				{{ name }}
			</div>

			<v-popover
					:container="'.popover-backdrop'"
					@show="onMenuShow(true)"
					@hide="onMenuShow(false)">
				<div class="tooltip-target small-button item-button">
					<Icon3Dots/>
				</div>

				<TopicMenu slot="popover" :topic="topic"/>
			</v-popover>

			<div
					class="item-button small-button"
					@click="openCreateSubtopic"
					v-tooltip="{content: 'Add subtopic', placement: 'bottom'}">
				<IconPlus/>
			</div>

			<IconChevron
					class="expand-icon"
					:class="{'expanded': expanded}"
					v-if="expandIconVisibility"
					@click.native="toggleExpandChildren"/>
		</div>

		<div class="children-container" v-if="showChildren" v-show="expanded">
			<TopicItem
					v-for="(topic, i) in children"
					:index="i"
					:key="topic.id"
					:topic="topic"
					:level="level + 1"/>
			<TopicItem
					v-if="!children.length"
					class="no-pages"
					:topic="{}"
					:level="level + 1"
					:showChildren="false"
					:customName="'No pages inside'"/>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import IconChevron from 'components/icons/icons/IconChevron.vue'
	import Icon3Dots from 'components/icons/icons/Icon3Dots.vue'
	import IconPlus from 'components/icons/icons/IconPlus.vue'
	import Utils from 'utils/Utils'
	import DNDService, {DraggingItemType} from 'modules/space/DNDService'
	import IconExternalLink from 'components/icons/icons/IconExternalLink.vue'
	import {ITopic} from 'components/topics/TopicsService'
	import TopicMenu from 'components/common/menus/TopicMenu.vue'
	import TopicsUtils from 'components/topics/TopicsUtils'

	@Component({
		components: {
			TopicMenu,
			IconChevron,
			Icon3Dots,
			IconPlus,
			IconExternalLink
		},
		name: 'TopicItem'
	})

	export default class TopicItem extends Vue {
		@Prop()
		topic: ITopic

		@Prop({default: 0})
		index: number

		@Prop({default: 0})
		level: number

		@Prop({default: true})
		showChildren: boolean

		@State(state => state.dashboardFilter)
		dashboardFilter: string

		expanded: boolean = false
		menuOpened: boolean = false

		private dndService: DNDService = DNDService.getInstance()

		@Watch('dashboardFilter')
		onCurrentPageChanged(newVal) {
			if (newVal === this.topic.id) {
				this.expandParents()
			}
		}

		mounted() {
			if (this.dashboardFilter === this.topic.id) {
				this.expandParents()
			}

			EventBus.$on(CustomEventNames.DRAG_STARTED, this.onDragStart)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.DRAG_STARTED, this.onDragStart)
		}

		get name(): string {
			return this.topic.name || ''
		}

		get expandIconVisibility(): boolean {
			return !!this.children.length
		}

		get children(): gapi.client.drive.File[] {
			return TopicsUtils.getTopicChildren(this.topic.id)
		}

		private onDragStart(topicId: string) {
			if (topicId === this.topic.id) {
				this.expanded = false
			}
		}

		private expandParents() {
			let parent = this.$parent as any
			while (parent.expandChildren) {
				parent.expandChildren()
				parent = parent.$parent
			}
			setTimeout(() => {
				this.scrollToItem()
			})
		}

		private scrollToItem() {
			Utils.scrollIntoView(this.$el, document.getElementById('dashboardSidebarItemsScrollContainer'), 200)
		}

		private topicClicked(topicId) {
			if (topicId) {
				this.expandChildren()
				EventBus.$emit(CustomEventNames.DASHBOARD_FILTER_SELECTED, topicId)
			}
		}

		toggleExpandChildren(e: Event) {
			e.stopPropagation()
			this.expanded = !this.expanded
		}

		expandChildren() {
			this.expanded = true
		}

		private onMenuShow(state: boolean) {
			this.menuOpened = state
		}

		private onMouseDown(e: MouseEvent) {
			this.dndService.onMouseDown(e, this.$el, DraggingItemType.TOPIC)
		}

		private openCreateSubtopic() {
			EventBus.$emit(CustomEventNames.OPEN_CREATE_TOPIC, this.topic.id)
		}
	}
</script>

<style scoped>
	.sidebar-item {
		color: var(--primary-color);
		cursor: pointer;
		flex: none;
		-webkit-tap-highlight-color: transparent;
	}

	.sidebar-item-title-container {
		padding: 0 8px 0 16px;
		line-height: 32px;
		font-size: 16px;
		color: var(--g-60);
		display: flex;
		position: relative;
		border-radius: 6px;
	}

	.sidebar-item-title-container:not(.active):hover,
	.item-hovered {
		background-color: var(--ds-60);
		z-index: 1;
	}

	.sidebar-item-title-container:hover .small-button,
	.item-hovered .small-button {
		display: block;
	}

	.sidebar-item-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex-grow: 1;
		box-sizing: border-box;
		position: relative;
		padding-right: 8px;
		-webkit-tap-highlight-color: transparent;
	}

	.small-button {
		width: 20px;
		height: 20px;
		margin: 4px 4px 4px 0;
		border-radius: 4px;
		color: var(--g-60);
		cursor: pointer;
		display: none;
	}

	.small-button svg,
	.expand-icon svg {
		width: 20px;
		height: 20px;
	}

	.expand-icon {
		width: 20px;
		height: 20px;
		margin: 6px 0;
		flex: none;
		color: var(--lb-50);
		border-radius: 4px;
		transform: rotate(-90deg);
		transition: transform 0.2s;
	}

	.external-link-icon {
		width: 20px;
		height: 20px;
		color: var(--g-60);
		margin: 4px 0;
    flex: none;
	}

	.plus-button {
		transform: rotate(0);
		height: 28px;
		margin: 0;
		padding: 4px 0;
		box-sizing: border-box;
	}

	.expanded {
		transform: rotate(0);
	}

	.expand-icon:hover,
	.small-button:hover {
		color: var(--lb-50);
	}

	.sidebar-item-title-container.active .expand-icon,
	.sidebar-item-title-container.active .small-button {
		color: var(--g-60);
	}

	.sidebar-item-title-container.active .expand-icon:hover,
	.sidebar-item-title-container.active .small-button:hover {
		color: var(--lb-50);
	}

	.sidebar-item-title-container:not(.active):hover .expand-icon:not(:hover),
	.item-hovered .expand-icon:not(:hover) {
		color: var(--g-60);
	}

	.children-container {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.sidebar-item-title-container:not(.active):hover .indent-line,
	.item-hovered .indent-line {
		border-left: 2px solid transparent;
	}

	.active {
		background: var(--ds-60);
		color: var(--lb-50);
		z-index: 1;
	}

	.active .indent-line {
		border-left: 2px solid transparent;
	}

	.no-pages {
		color: var(--g-60);
		display: none;
	}

	.indent-line {
		margin: -8px 16px 4px 1px;
		border-left: 2px solid var(--ds-35);
	}

	.transparent-line {
		border-left: 2px solid transparent;
	}

	.indent-line-first {
		margin-top: 4px;
	}

	.bold-title {
		font-weight: 600;
	}

	body.disable-hovers .sidebar-item-title-container:not(.active):hover {
		background-color: transparent;
		z-index: 0;
	}

	body.disable-hovers .sidebar-item-title-container:hover .small-button {
		display: none;
	}

	body.disable-hovers .sidebar-item-title-container:not(.active):hover .indent-line {
		border-left: 2px solid var(--ds-35);
	}

	body.disable-hovers .sidebar-item-title-container:not(.active):hover .expand-icon:not(:hover) {
		color: var(--lb-50);
	}

	body.disable-hovers .active {
		background: transparent;
		color: var(--lb-50);
	}

	body.disable-hovers .active .indent-line {
		border-left: 2px solid var(--ds-35);
	}

	@media only screen and (max-width: 480px) {
		.item-button {
			display: none;
		}
	}
</style>
