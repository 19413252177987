import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import GoogleAuth = gapi.auth2.GoogleAuth
import AppService from 'modules/app/AppService'
import RoutingUtils from 'utils/RoutingUtils'
import GoogleUser = gapi.auth2.GoogleUser
import {store} from 'store/Store'
import NotificationService from 'modules/notifications/NotificationService'
import {SOMETHING_WENT_WRONG} from 'modules/notifications/NotificationMessages'
import {EventBus} from 'EventBus'
import {CustomEventNames} from 'enums/CustomEventNames'
import Stat from 'modules/stat/Stat'
import {GOOGLE_CONFIG} from "../GoogleConfig";
import {ErrorHandlingUtils} from "../../utils/ErrorHandlingUtils";

export default class AuthService {
	private static _instance: AuthService
	private authInstance: GoogleAuth
	private appProcessor: AppService

	googleUser: GoogleUser
	firebaseUser: firebase.User

	authStateChanged = new signals.Signal<(isSignedIn: boolean, isFirstCheck: boolean) => void>()

	static getInstance():AuthService {
		if (!AuthService._instance) {
			AuthService._instance = new AuthService()
		}
		return AuthService._instance
	}

	constructor() {
		this.appProcessor = AppService.getInstance()
		if (window.gapi) {
			this.onGapiInit()
		} else {
			this.appProcessor.gapiInitialized.add(this.onGapiInit, this)
		}
	}

	private onGapiInit() {
		this.authInstance = gapi.auth2.getAuthInstance()
		this.authInstance.isSignedIn.listen(this.handleIsSignedIn.bind(this))
		this.handleIsSignedIn(this.authInstance.isSignedIn.get(), true)
	}

	signIn() {
		if (this.authInstance.isSignedIn.get()) {
			return
		}
		this.authInstance.signIn().then(user => {
			if (store.state.remainedRoute) {
				this.appProcessor.appRouter.push({path: store.state.remainedRoute})
			}
		}).catch(error => {
			Stat.authError(error.error)
			const message = ErrorHandlingUtils.getCorrectMessageForSignInError(error.error)
			NotificationService.getInstance().showNotification(message, true)
		})
	}

	signOut() {
		this.authInstance.signOut().then(() => {
			firebase.auth().signOut().then(() => {
				RoutingUtils.goToLogin()
			})
		})
	}

	private setUsers(googleUser, firebaseUser: firebase.User) {
		this.googleUser = googleUser
		this.firebaseUser = firebaseUser
		store.commit('setGoogleUser', googleUser)
		store.commit('setFirebaseUser', firebaseUser)
	}

	private handleIsSignedIn(isSignedIn, isFirstCheck = false) {
		if (isSignedIn) {
			const currentUser = this.authInstance.currentUser.get()
			const authResponse = currentUser.getAuthResponse(true)
			const credential = firebase.auth.GoogleAuthProvider.credential(authResponse.id_token, authResponse.access_token)

			firebase.auth().signInWithCredential(credential).then(userCredential => {
				const hasUser = userCredential !== null

				if (hasUser) {
					Stat.identifyUser(userCredential, gapi.auth2.getAuthInstance().currentUser.get().getHostedDomain())
					this.setUsers(gapi.auth2.getAuthInstance().currentUser.get(), userCredential.user)
					this.authStateChanged.dispatch(isSignedIn, isFirstCheck)
				} else {
					this.setUsers(undefined, undefined)
					this.authStateChanged.dispatch(false, isFirstCheck)
				}
			})
		} else {
			this.setUsers(undefined, undefined)
			setTimeout(() => {
				this.authStateChanged.dispatch(isSignedIn, isFirstCheck)
			})
		}
	}

	requestBasicScopes() {
		const scopesString = GOOGLE_CONFIG.scopes.join(' ')
		const option = new gapi.auth2.SigninOptionsBuilder()
		option.setScope(scopesString)
		this.googleUser.grant(option).then(() => {
			setTimeout(() => {
				Stat.basicScopesGranted()
				EventBus.$emit(CustomEventNames.BASIC_SCOPES_GRANTED, true)
			})
		}, () => {
			Stat.basicScopesRejected()
			EventBus.$emit(CustomEventNames.BASIC_SCOPES_GRANTED, false)
		})
	}

	requestScopes(scopes: string[]) {
		const scopesString = scopes.join(' ')
		const option = new gapi.auth2.SigninOptionsBuilder()
		option.setScope(scopesString)
		this.googleUser.grant(option).then(() => {
			setTimeout(() => {
				EventBus.$emit(CustomEventNames.SCOPES_GRANTED, scopes)
			})
		}).catch(error => {
			console.log('requestScopesError', error)
			NotificationService.getInstance().showNotification(SOMETHING_WENT_WRONG)
		})
	}
}
