import {GoogleMimeTypes} from 'enums/GoogleMimeTypes'

export interface IFileDef {
	title: string
	desc: string
	icon: string
	smallIcon: string
	buttonText: string
	mimeType: string
	codeField?: boolean
	tip?: string
	tipLink?: string
	type?: string
	codeFieldPlaceholder?: string
	codeFieldTitle?: string
}

export enum FileTypes {
	FOLDER = 'folder',
	DOCS = 'docs',
	SHEETS = 'sheets',
	SLIDES = 'slides',
	FORMS = 'forms',
	LINK = 'link'
}

export const FileDefs: IFileDef[] = [
	{
		title: 'Folder',
		desc: 'Add a new section',
		icon: require('filedefs/icons/folder.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.FOLDER),
		buttonText: 'Create Folder',
		mimeType: GoogleMimeTypes.FOLDER,
		type: FileTypes.FOLDER,
	},
	{
		title: 'Google Docs',
		desc: 'Add a new document',
		icon: require('filedefs/icons/docs.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.DOCS),
		buttonText: 'Create Google Docs',
		mimeType: GoogleMimeTypes.DOCS,
		type: FileTypes.DOCS,
	},
	{
		title: 'Google Sheets',
		desc: 'Add a new spreadsheet',
		icon: require('filedefs/icons/sheets.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.SHEETS),
		buttonText: 'Create Google Sheets',
		mimeType: GoogleMimeTypes.SHEETS,
		type: FileTypes.SHEETS,
	},
	{
		title: 'Google Slides',
		desc: 'Add a new presentation',
		icon: require('filedefs/icons/slides.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.SLIDES),
		buttonText: 'Create Google Slides',
		mimeType: GoogleMimeTypes.SLIDES,
		type: FileTypes.SLIDES,
	},
	{
		title: 'Google Forms',
		desc: 'Add a new form',
		icon: require('filedefs/icons/forms.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.FORMS),
		buttonText: 'Create Google Forms',
		mimeType: GoogleMimeTypes.FORMS,
		type: FileTypes.FORMS,
	},
	{
		title: 'Figma',
		desc: 'Embed a Figma file',
		icon: require('filedefs/icons/figma.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'figma'),
		codeField: true,
		buttonText: 'Embed Figma',
		tip: 'How to get an iframe code for Figma file?',
		tipLink: 'https://help.figma.com/hc/en-us/articles/360039827134-Embed-files-and-prototypes',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'figma',
	},
	{
		title: 'Invision',
		desc: 'Embed an Invision project',
		icon: require('filedefs/icons/invision.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'invision'),
		codeField: true,
		buttonText: 'Embed Invision',
		tip: 'How to get an iframe code for Invision project?',
		tipLink: 'https://support.invisionapp.com/hc/en-us/articles/360027579812-Embedding-Screens-Prototypes',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'invision',
	},
	{
		title: 'Marvel',
		desc: 'Embed a Marvel prototype',
		icon: require('filedefs/icons/marvel.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'marvel'),
		codeField: true,
		buttonText: 'Embed Marvel',
		tip: 'How to get an iframe code for Marvel prototype?',
		tipLink: 'https://help.marvelapp.com/hc/en-us/articles/360002747857-How-to-embed-your-prototype-on-Wordpress-or-any-web-page-blog-or-portfolio-',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'marvel',
	},
	// {
	// 	title: 'UXPin',
	// 	desc: 'Embed an UXPin prototype',
	// 	icon: require('./icons/uxpin.svg'),
	// 	codeField: true,
	// 	buttonText: 'Embed UXPin'
	// },



	// {
	// 	title: 'GitHub Gist',
	// 	desc: 'Embed a Gist from GitHub',
	// 	icon: require('./icons/github.svg'),
	// 	codeField: true,
	// 	buttonText: 'Embed GitHub Gist'
	// },
	{
		title: 'CodePen',
		desc: 'Embed a CodePen',
		icon: require('filedefs/icons/codepen.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'codepen'),
		codeField: true,
		buttonText: 'Embed CodePen',
		tip: 'How to get an iframe code for CodePen?',
		tipLink: 'https://blog.codepen.io/documentation/embedded-pens/',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'codepen',
	},


	{
		title: 'Loom',
		desc: 'Embed a Loom recording',
		icon: require('filedefs/icons/loom.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'loom'),
		codeField: true,
		buttonText: 'Embed Loom',
		tipLink: 'https://support.loom.com/hc/en-us/articles/360002208317-How-to-embed-your-video-into-a-webpage-',
		tip: 'How to get an iframe code for Loom recording?',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'loom',
	},
	{
		title: 'YouTube',
		desc: 'Embed YouTube videos',
		icon: require('filedefs/icons/youtube.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'youtube'),
		codeField: true,
		buttonText: 'Embed YouTube',
		tipLink: 'https://support.google.com/youtube/answer/171780?hl=en',
		tip: 'How to get an iframe code for YouTube video?',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'youtube',
	},
	{
		title: 'Vimeo',
		desc: 'Embed Vimeo videos',
		icon: require('filedefs/icons/vimeo.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'vimeo'),
		codeField: true,
		buttonText: 'Embed Vimeo',
		tipLink: 'https://vimeo.zendesk.com/hc/en-us/articles/224969968-Embedding-videos-overview',
		tip: 'How to get an iframe code for Vimeo video?',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'vimeo',
	},

	{
		title: 'Miro',
		desc: 'Embed a Miro board',
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'miro'),
		icon: require('filedefs/icons/miro.svg'),
		codeField: true,
		buttonText: 'Embed Miro',
		tip: 'How to get an iframe code for Miro board?',
		tipLink: 'https://help.miro.com/hc/en-us/articles/360016335640-Embedding-Editable-Boards-into-Websites',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'miro',
	},
	{
		title: 'Whimsical',
		desc: 'Embed a Whimsical board',
		icon: require('filedefs/icons/whimsical.png'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'whimsical'),
		codeField: true,
		buttonText: 'Embed Whimsical',
		tip: 'How to get an iframe code for Whimsical board?',
		tipLink: 'https://help.whimsical.com/en/articles/3722574-how-to-embed-whimsical-boards',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'whimsical',
	},
	// {
	// 	title: 'Prezi',
	// 	desc: 'Embed Prezi slides',
	// 	icon: require('./icons/prezi.svg'),
	// 	codeField: true,
	// 	buttonText: 'Embed Prezi'
	// },
	{
		title: 'Airtable',
		desc: 'Embed Airtable base',
		icon: require('filedefs/icons/airtable.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'airtable'),
		codeField: true,
		buttonText: 'Embed Airtable',
		tipLink: 'https://support.airtable.com/hc/en-us/articles/217846478-Embedding-a-view-or-base',
		tip: 'How to get an iframe code for Airtable base?',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'airtable',
	},
	{
		title: 'Google Maps',
		desc: 'Embed Google Maps',
		icon: require('filedefs/icons/googlemaps.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'gmaps'),
		codeField: true,
		buttonText: 'Embed Google Maps',
		tip: 'How to get an iframe code for Google Map?',
		tipLink: 'https://support.google.com/maps/answer/144361?co=GENIE.Platform%3DDesktop&hl=en',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'gmaps'
	},
	{
		title: 'Embed',
		desc: 'For any app or service',
		icon: require('filedefs/icons/embed.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'embed'),
		codeField: true,
		buttonText: 'Embed',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: 'embed'
	},
	{
		title: 'Link',
		desc: 'Link to an external resource',
		icon: require('filedefs/icons/link.svg'),
		smallIcon: getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, 'link'),
		codeField: true,
		codeFieldPlaceholder: 'Paste url...',
		codeFieldTitle: 'Url',
		buttonText: 'Create link',
		mimeType: GoogleMimeTypes.EXTERNAL_SHORTCUT,
		type: FileTypes.LINK,
	},
]

export const GoogleFileDefs: IFileDef[] = FileDefs.filter(file => file.mimeType !== GoogleMimeTypes.EXTERNAL_SHORTCUT)
export const ExternalFileDefs: IFileDef[] = FileDefs.filter(file => file.mimeType === GoogleMimeTypes.EXTERNAL_SHORTCUT)

export function getSmallIconByFile(file: gapi.client.drive.File): string {
	if (file.mimeType === GoogleMimeTypes.GOOGLE_SHORTCUT) {
		return file.shortcutDetails ? getFileIcon(file.shortcutDetails.targetMimeType) : getFileIcon()
	} else if (file.mimeType === GoogleMimeTypes.EXTERNAL_SHORTCUT || file.mimeType === GoogleMimeTypes.SPACELI_SHORTCUT) {
		return file.properties.type ? getFileIcon(GoogleMimeTypes.EXTERNAL_SHORTCUT, file.properties.type) : getFileIcon()
	} else {
		return getFileIcon(file.mimeType)
	}
}

export function getShortcutIcon() {
	return require('filedefs/icons/shortcut.svg')
}

function getFileIcon(mimeType: string = '', type: string = ''): string {
	if (mimeType === GoogleMimeTypes.FOLDER) {
		return require('filedefs/icons/folder.svg')
	} else if (mimeType === GoogleMimeTypes.DOCS) {
		return require('filedefs/icons/docs-24.svg')
	} else if (mimeType === GoogleMimeTypes.SHEETS) {
		return require('filedefs/icons/sheets-24.svg')
	} else if (mimeType === GoogleMimeTypes.SLIDES) {
		return require('filedefs/icons/slides-24.svg')
	} else if (mimeType === GoogleMimeTypes.FORMS) {
		return require('filedefs/icons/forms-24.svg')
	} else if (mimeType === GoogleMimeTypes.PDF) {
		return require('filedefs/icons/pdf-24.svg')
	} else if (mimeType === GoogleMimeTypes.PNG || mimeType === GoogleMimeTypes.SVG || mimeType === GoogleMimeTypes.JPG) {
		return require('filedefs/icons/img-24.svg')
	} else if (mimeType === GoogleMimeTypes.WORD_DOC || mimeType === GoogleMimeTypes.WORD_DOCX) {
		return require('filedefs/icons/word-24.svg')
	} else if (mimeType === GoogleMimeTypes.EXCEL_XLS || mimeType === GoogleMimeTypes.EXCEL_XLSX) {
		return require('filedefs/icons/excel-24.svg')
	} else if (mimeType === GoogleMimeTypes.POWERPOINT || mimeType === GoogleMimeTypes.POWERPOINT_1) {
		return require('filedefs/icons/powerpoint-24.svg')
	} else if (mimeType === GoogleMimeTypes.EXTERNAL_SHORTCUT || mimeType === GoogleMimeTypes.SPACELI_SHORTCUT) {
		if (type === 'figma') {
			return require('filedefs/icons/figma.svg')
		} else if (type === 'invision') {
			return require('filedefs/icons/invision.svg')
		} else if (type === 'marvel') {
			return require('filedefs/icons/marvel.svg')
		} else if (type === 'codepen') {
			return require('filedefs/icons/codepen.svg')
		} else if (type === 'loom') {
			return require('filedefs/icons/loom.svg')
		} else if (type === 'youtube') {
			return require('filedefs/icons/youtube.svg')
		} else if (type === 'vimeo') {
			return require('filedefs/icons/vimeo.svg')
		} else if (type === 'miro') {
			return require('filedefs/icons/miro-32.png')
		} else if (type === 'whimsical') {
			return require('filedefs/icons/whimsical.png')
		} else if (type === 'airtable') {
			return require('filedefs/icons/airtable.svg')
		} else if (type === 'gmaps') {
			return require('filedefs/icons/googlemaps.svg')
		} else if (type === 'embed') {
			return require('filedefs/icons/embed.svg')
		} else if (type === 'link') {
			return require('filedefs/icons/link.svg')
		} else {
			return require('filedefs/icons/default.svg')
		}
	} else {
		return require('filedefs/icons/default.svg')
	}
}
