<template>
	<a class="circle-button-32" :href="page.webViewLink" v-tooltip="{content: 'Edit in new tab', placement: 'bottom'}" target="_blank">
		<IconEdit/>
	</a>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import GoogleUtils from 'utils/GoogleUtils'
	import IconEdit from 'components/icons/icons/IconEdit.vue'

	@Component({
		components: {
			IconEdit,
		}
	})

	export default class EditByLink extends Vue {
		@Prop()
		page: gapi.client.drive.File

		get getViewInDriveUrl(): string {
			return GoogleUtils.getViewInDriveUrl(this.page)
		}
	}
</script>

<style scoped>

</style>
