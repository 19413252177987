<template>
	<div class="access-error-fs" v-if="opened">
		<a href="https://spaceli.io" class="logo"><LogoTextGradient/></a>
		<div class="access-error-content">
			<h1 class="modal-header">Oops!</h1>
			<div class="modal-paragraph">{{ message }}</div>
			<div class="modal-buttons-container">
				<router-link to="/dashboard" class="rounded-button-medium db-50-button" v-if="!buttonReload && !grandScopesButton && googleUser">Open dashboard</router-link>
				<div
						class="rounded-button-medium db-50-button"
						@click="reloadPage"
						v-if="buttonReload">Reload</div>
        <div
            class="rounded-button-medium db-50-button"
            @click="grandScopes"
            v-if="grandScopesButton">Grand permissions</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import {Component, Vue} from 'vue-property-decorator'
	import {EventBus} from 'EventBus'
	import {CustomEventNames} from 'enums/CustomEventNames'
	import {State} from 'vuex-class'
	import LogoTextGradient from 'components/icons/logo/LogoTextGradient.vue'
  import AuthService from "../../modules/app/AuthService";

	@Component({
		components: {
			LogoTextGradient
		}
	})

	export default class AccessErrorPage extends Vue {
		@State
		googleUser: gapi.auth2.GoogleUser

		opened: boolean = false
		message: string = ''
		buttonReload: boolean = false
    grandScopesButton: boolean = false

		open(message: string, buttonReload: boolean = false, grandScopesButton: boolean = false) {
			this.message = message
			this.buttonReload = buttonReload
      this.grandScopesButton = grandScopesButton
			this.opened = true
		}

    onBasicScopesGranted(result: boolean): void {
      if (result) {
        this.reloadPage()
      }
    }

		mounted() {
			EventBus.$on(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, this.open)
      EventBus.$on(CustomEventNames.BASIC_SCOPES_GRANTED, this.onBasicScopesGranted)
		}

		beforeDestroy() {
			EventBus.$off(CustomEventNames.OPEN_SPACE_OR_PAGE_ERROR, this.open)
      EventBus.$off(CustomEventNames.BASIC_SCOPES_GRANTED, this.onBasicScopesGranted)
		}

		reloadPage() {
			location.reload()
		}

    grandScopes() {
      AuthService.getInstance().requestBasicScopes()
    }
	}
</script>

<style scoped>
	.access-error-fs {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		z-index: var(--modal-z-index);
		align-items: center;
		justify-content: center;
		background-color: var(--white);
    color: var(--primary-color);
	}

	.access-error-content {
		width: 450px;
		max-width: 100%;
		padding: 32px;
		box-sizing: border-box;
    user-select: text;
	}

	.logo {
		position: absolute;
		top: 32px;
		left: 56px;
	}

	@media only screen and (max-width: 480px) {
		.logo {
			left: 32px;
		}
	}
</style>
