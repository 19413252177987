<template>
	<div
			class="user-avatar"
			v-tooltip="{content: name, placement: 'bottom'}"
			:style="{width: size + 'px', height: size + 'px', 'borderColor': borderColor, 'backgroundColor': backgroundColor}">
		<img class="user-avatar-img" @load="imgLoaded = true" :src="photoUrl" v-show="imgLoaded" referrerPolicy="no-referrer">
		<div class="initials" v-show="!imgLoaded">{{ initials }}</div>
	</div>
</template>

<script lang="ts">
	import {Component, Prop, Vue} from 'vue-property-decorator'
	import Utils from 'utils/Utils'

	@Component({
		components: {
			//
		}
	})

	export default class UserAvatar extends Vue {
		@Prop()
		user: gapi.client.drive.User

		@Prop({default: 'var(--white)'})
		borderColor: string

		@Prop({default: ' var(--g-20)'})
		backgroundColor: string

		@Prop({default: 28})
		size: number

		imgLoaded: boolean = false

		get photoUrl() {
			return this.user ? this.processPhotoLink(this.user.photoLink) : ''
		}

		private processPhotoLink(link): string {
			if (!link) {
				return ''
			}
			return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`
		}

		get initials() {
			return Utils.getUserInitials(this.user)
		}

		get name() {
			if (this.user) {
				if (this.user.displayName) {
					return this.user.displayName
				} else if (this.user.emailAddress) {
					return this.user.emailAddress
				} else {
					return ''
				}
			}
			return ''
		}
	}
</script>

<style scoped>
	.user-avatar {
		box-sizing: border-box;
		background-color: var(--g-20);
		border: 2px solid;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		user-select: none;
    flex-shrink: 0;
	}

	.user-avatar-img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.initials {
		font-weight: 600;
		font-size: 12px;
	}
</style>
